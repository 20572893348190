import React from 'react';
import { useTranslation } from 'react-i18next';

type ParagraphTextComponentProps = {
    headingKey: string;
    paragraphKey: string;
    contractTypeContent: string;
    carInformation: string;
};
const ParagraphTextComponent: React.FC<ParagraphTextComponentProps> = ({
    headingKey,
    paragraphKey,
    contractTypeContent,
    carInformation,
}) => {
    const { t } = useTranslation('digital-renewal');
    const heading = t(`${contractTypeContent}.return_of_the_car.${headingKey}`, { brand: carInformation });
    const paragraph = t(`${contractTypeContent}.return_of_the_car.${paragraphKey}`, { brand: carInformation });
    return (
        <>
            <strong dangerouslySetInnerHTML={{ __html: heading }} />
            <br />
            <br />
            {paragraphKey !== '' && <span dangerouslySetInnerHTML={{ __html: paragraph }} />}
            <br />
            <br />
        </>
    );
};

export default ParagraphTextComponent;
