import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import React from 'react';
import { useContract } from 'components/contracts/useContract';
import { NoConnectionNotification } from 'components/notifications/no-connection/NoConnectionNotification';
import { Contract, FinancialDetails } from '@cp-es/common';
import { ContractRecalculationForm } from './contract-recalculation-form/ContractRecalculationForm';
import { ContractRecalculationHeader } from './header/ContractRecalculationHeader';
import { useFinancialDetails } from 'components/contracts/financial-details/useFinancialDetails';
import { Heading } from '@vwfs-bronson/bronson-react';
import { useTranslation } from 'react-i18next';

type ContractRecalculationUiProps = {
    contract?: Contract;
    financialDetails?: FinancialDetails;
    vinCode: string;
};
type ContractRecalculationProps = {
    encryptedContractId: string;
    vinCode: string;
};
const ContractRecalculationUi: React.FC<ContractRecalculationUiProps> = ({ contract, financialDetails, vinCode }) => {
    const { t } = useTranslation('contract-recalculation');
    if (!contract || !financialDetails) {
        return <NoConnectionNotification />;
    }

    return (
        <>
            <Heading level={'1'}>{t('heading')}</Heading>
            <ContractRecalculationHeader contract={contract} financialDetails={financialDetails} />
            <ContractRecalculationForm contract={contract} financialDetails={financialDetails} vinCode={vinCode} />
        </>
    );
};

const ContractRecalculationUiWithHandler = withLoadingAndNoConnectionHandler(ContractRecalculationUi);

export const ContractRecalculation: React.FC<ContractRecalculationProps> = ({ encryptedContractId, vinCode }) => {
    const {
        data: contract,
        isLoading: isLoadingContracts,
        loadingError: loadingErrorContract,
    } = useContract(encryptedContractId, { encryptedContractId: true });

    const {
        data: financialDetails,
        isLoading: isLoadingFinancialDetails,
        loadingError: loadingErrorFinancialDetails,
    } = useFinancialDetails(contract?.contractNumber || '');

    return (
        <ContractRecalculationUiWithHandler
            isLoading={isLoadingContracts || isLoadingFinancialDetails}
            hasError={!!loadingErrorFinancialDetails && !!loadingErrorContract && !contract?.isActiveContract}
            contract={contract}
            financialDetails={financialDetails}
            vinCode={vinCode}
        />
    );
};
