import * as React from 'react';
import { Card } from '@vwfs-bronson/bronson-react';
import { LicensePlate } from '../../../../../license-plate';
import { useTranslation } from 'react-i18next';

export type SmeContractHeaderProps = {
    /**
     * The complete car information (e.g. "VW CALIFORNIA 2.0BITDI SE 180 4DR 4MTN DSG")
     */
    carInformation: string;
    /**
     * The contract number
     */
    contractNumber: string;
    /**
     * A license plate number
     */
    licensePlateNumber?: string;
};

/**
 * A contract header applicable to OperatingLeasing SME Contracts
 */
export const SmeContractHeader: React.FC<SmeContractHeaderProps> = ({
    licensePlateNumber,
    carInformation,
    contractNumber,
}) => {
    const { t } = useTranslation('financial-details');

    return (
        <Card element="article">
            <div className="u-text-left@m-up  u-mr-none cp-contract-identifier-slot">
                <LicensePlate size={'small'} licensePlateNumber={licensePlateNumber} className="u-mb" />

                <p className="u-zeta">{carInformation}</p>

                <p>{`${t('ibanSection.editView.smeContractsView.contract')} ${contractNumber}`}</p>
            </div>
        </Card>
    );
};
