import React, { useEffect, useState } from 'react';
import { CpDataApi } from 'cp-xhr';
import { TimeoutModal } from './timeout-modal';
import { CecaPaymentInformation, CecaPaymentInformationRequest } from '@cp-es/common';
import { OnlinePaymentView } from './online-payment-view';
import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import { useAnalyticsActionTracker, useAnalyticsPageViewTracker } from '@cp-shared-6/frontend-ui';

export type UnpaidDetailsOnlinePaymentProps = {
    toPreviousView: () => void;
    onlinePaymentEndpoint?: string;
    lastIndexToBePayed: number;
};

type OnlinePaymentProps = {
    toPreviousView: () => void;
    paymentInformation?: CecaPaymentInformation;
};

type PaymentInformationRequestProps = {
    paymentInformation?: CecaPaymentInformation;
    isLoadingPaymentInformation: boolean;
    hasErrorPaymentInformation: boolean;
};

const OnlinePayment: React.FC<OnlinePaymentProps> = ({ toPreviousView, paymentInformation }) => {
    const [showTimeoutModal, setShowTimeoutModal] = useState(false);

    useAnalyticsPageViewTracker('onPayment');

    const { onAction: onPay } = useAnalyticsActionTracker('onPay');

    if (!paymentInformation) {
        return null;
    }

    const { paymentPayload, paymentDetails } = paymentInformation;

    const handlePayNowButtonClick = (flag: boolean): void => setShowTimeoutModal(flag);
    const handlePay = (): void => {
        onPay();
        handlePayNowButtonClick(true);
    };
    return (
        <>
            <OnlinePaymentView paymentDetails={paymentDetails} back={toPreviousView} next={handlePay} />
            {showTimeoutModal && (
                <TimeoutModal payload={paymentPayload} handlePayNowButtonClick={handlePayNowButtonClick} />
            )}
        </>
    );
};

const OnlinePaymentWithHandlers = withLoadingAndNoConnectionHandler(OnlinePayment);

export const UnpaidDetailsOnlinePayment: React.FC<UnpaidDetailsOnlinePaymentProps> = ({
    toPreviousView,
    onlinePaymentEndpoint,
    lastIndexToBePayed,
}) => {
    const [paymentInformationRequest, setPaymentInformationRequest] = useState<PaymentInformationRequestProps>({
        paymentInformation: undefined,
        isLoadingPaymentInformation: false,
        hasErrorPaymentInformation: false,
    });

    useEffect(() => {
        if (onlinePaymentEndpoint) {
            const requestBody: CecaPaymentInformationRequest = {
                lastIndexToBePayed,
            };
            setPaymentInformationRequest({
                paymentInformation: undefined,
                isLoadingPaymentInformation: true,
                hasErrorPaymentInformation: false,
            });
            CpDataApi.post(onlinePaymentEndpoint, requestBody)
                .then(response => {
                    const paymentInformation = response.data;
                    setPaymentInformationRequest({
                        paymentInformation: paymentInformation,
                        isLoadingPaymentInformation: false,
                        hasErrorPaymentInformation: false,
                    });
                })
                .catch(() =>
                    setPaymentInformationRequest({
                        paymentInformation: undefined,
                        isLoadingPaymentInformation: false,
                        hasErrorPaymentInformation: true,
                    }),
                );
        }
    }, [onlinePaymentEndpoint, lastIndexToBePayed]);
    const { paymentInformation, isLoadingPaymentInformation, hasErrorPaymentInformation } = paymentInformationRequest;

    return (
        <OnlinePaymentWithHandlers
            toPreviousView={toPreviousView}
            hasError={hasErrorPaymentInformation}
            isLoading={isLoadingPaymentInformation}
            paymentInformation={paymentInformation}
        />
    );
};
