import React from 'react';
import { InstructionStepper } from '@vwfs-bronson/bronson-react';
import { StepperType } from './StepperType';
import { PartialEarlySettlementViewType } from '../PartialEarlySettlementViewType';

export type StepperUiProps = {
    stepper: StepperType[];
    currentViewType: PartialEarlySettlementViewType;
};

export const StepperUi: React.FC<StepperUiProps> = ({ stepper, currentViewType }) => {
    return (
        <InstructionStepper numbered>
            {stepper.map((item: StepperType, index) => {
                return (
                    <InstructionStepper.Step
                        key={index}
                        title={item.title}
                        state={item.viewType === currentViewType ? 'is-active' : ''}
                    />
                );
            })}
        </InstructionStepper>
    );
};
