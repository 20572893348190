import { IconListItem } from '@cp-shared-6/frontend-ui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { SubrogationInfo } from '../subrogation-info/SubrogationInfo';

export const LegalPersonInfo: React.FC = () => {
    const { t } = useTranslation('request-subrogation');

    const documentsList: IconListItem[] = [
        {
            label: t('documents.legal-person.doc1'),
            icon: 'checkmark',
        },
        {
            label: t('documents.legal-person.doc2'),
            icon: 'checkmark',
        },
        {
            label: t('documents.legal-person.doc3'),
            icon: 'checkmark',
        },
        {
            label: t('documents.legal-person.doc4'),
            icon: 'checkmark',
        },
        {
            label: t('documents.legal-person.doc5'),
            icon: 'checkmark',
        },
        {
            label: t('documents.legal-person.doc6'),
            icon: 'checkmark',
        },
        {
            label: t('documents.legal-person.doc7'),
            icon: 'checkmark',
        },
        {
            label: t('documents.legal-person.doc8'),
            icon: 'checkmark',
        },
        {
            label: t('documents.legal-person.doc9'),
            icon: 'checkmark',
        },
        {
            label: t('documents.legal-person.doc10'),
            icon: 'checkmark',
        },
        {
            label: t('documents.legal-person.doc11'),
            icon: 'checkmark',
        },
        {
            label: t('documents.legal-person.doc12'),
            icon: 'checkmark',
        },
    ];

    return (
        <SubrogationInfo
            documentsList={documentsList}
            type={t('subrogation-info.type.value.legal-person')}
            isLegalPerson={true}
        />
    );
};
