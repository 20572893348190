import React, { useState } from 'react';

import { Contract, PostboxDocument } from '@cp-es/common';
import { Layout, Link } from '@vwfs-bronson/bronson-react';
import { useTranslation } from 'react-i18next';

import { PostboxDocumentType } from '../helper/DocumentType';
import { AllTimeframes, Timeframe } from '../helper/Timeframe';
import { ContractFilter } from './filters/ContractFilter';
import { DocumentTypeFilter } from './filters/DocumentTypeFilter';
import { TimeframeFilter } from './filters/TimeFrameFilter';
import { PostboxTableUi } from './table/PostboxTableUi';
import { useAnalyticsPageViewTracker } from '@cp-shared-6/frontend-ui';

export const PostboxUi: React.FC<{
    postboxDocuments?: PostboxDocument[];
    contracts?: Contract[];
    hasInvoiceConsent: boolean;
    hasSignUpCheckError: boolean;
}> = ({ postboxDocuments, contracts, hasInvoiceConsent, hasSignUpCheckError }) => {
    const { t } = useTranslation('postbox');
    useAnalyticsPageViewTracker('postbox', !!(contracts && postboxDocuments));

    const all = 'ALL';

    const [currentDocumentTypeFilter, setCurrentDocumentTypeFilter] = useState<PostboxDocumentType>(all);
    const [currentTimeFrameFilter, setCurrentTimeFrameFilter] = useState<Timeframe | AllTimeframes>(all);
    const [currentContractFilter, setCurrentContractFilter] = useState<string>(all);

    const [resetKey, setResetKey] = useState<number>(0);

    if (!contracts || !postboxDocuments) {
        return null;
    }

    // Set session storage consent on failing sign-up check
    let sessionConfirmedInvoice = false;
    if (hasSignUpCheckError) {
        sessionStorage.setItem('sessionConfirmedInvoices', 'Y');
    }

    // if consent is not given by check-up check if session consent is given
    if (!hasInvoiceConsent) {
        sessionConfirmedInvoice = sessionStorage.getItem('sessionConfirmedInvoices')
            ? sessionStorage.getItem('sessionConfirmedInvoices') === 'Y'
            : false;
        hasInvoiceConsent = sessionConfirmedInvoice;
    }

    const hasOperativeLeaseContracts = contracts
        ? contracts.some(contract => contract.isOperatingLeaseContract)
        : false;

    const postboxDocumentsListIsEmpty = postboxDocuments.length === 0;

    const documentTypeFilterChange = (value: PostboxDocumentType) => {
        setCurrentDocumentTypeFilter(value);
    };

    const timeframeFilterChange = (value: Timeframe | AllTimeframes) => {
        setCurrentTimeFrameFilter(value);
    };

    const contractFilterChange = (selectedContract: string) => {
        setCurrentContractFilter(selectedContract);
    };

    const resetAllFilters = (): void => {
        /**
         *  Pattern taken from
         *  https://reactjs.org/blog/2018/06/07/you-probably-dont-need-derived-state.html#recommendation-fully-uncontrolled-component-with-a-key
         */
        setResetKey(resetKey + 1);

        setCurrentDocumentTypeFilter(all);
        setCurrentTimeFrameFilter(all);
        setCurrentContractFilter(all);
    };

    return (
        <>
            {postboxDocumentsListIsEmpty ? null : (
                <>
                    <Layout>
                        <Layout.Item default={'1/3'} xs={'1/1'} m={'1/1'}>
                            <DocumentTypeFilter
                                key={resetKey}
                                postboxDocuments={postboxDocuments}
                                onChange={documentTypeFilterChange}
                            />
                        </Layout.Item>
                        <Layout.Item default={'1/3'} xs={'1/1'} m={'1/2'}>
                            <ContractFilter key={resetKey} contracts={contracts} onChange={contractFilterChange} />
                        </Layout.Item>
                        <Layout.Item default={'1/3'} xs={'1/1'} m={'1/2'}>
                            <TimeframeFilter
                                key={resetKey}
                                postboxDocuments={postboxDocuments}
                                onChange={timeframeFilterChange}
                            />
                        </Layout.Item>
                    </Layout>
                    <Layout className={'u-text-right u-mt-small'}>
                        <Layout.Item className={'u-mt-none'}>
                            <Link
                                onClick={resetAllFilters}
                                testId={'ResetButtonLink'}
                                icon={'semantic-reload'}
                                normalLink
                                reversed
                            >
                                {t('reset-button')}
                            </Link>
                        </Layout.Item>
                    </Layout>
                </>
            )}

            <Layout>
                <Layout.Item default={'1/1'}>
                    <PostboxTableUi
                        postboxDocuments={postboxDocuments}
                        activeDocumentTypeFilter={currentDocumentTypeFilter}
                        activeTimeframeFilter={currentTimeFrameFilter}
                        activeContractFilter={currentContractFilter}
                        hasInvoiceConsent={hasInvoiceConsent}
                        hasOperativeLeaseContracts={hasOperativeLeaseContracts}
                    />
                </Layout.Item>
            </Layout>
        </>
    );
};
