import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Button, ButtonContainer, Card, Heading, Layout, Paragraph } from '@vwfs-bronson/bronson-react';
import { ContractHeader } from '../../contracts/ContractHeader';
import { dashboardPagePath, digitalRenewalPagePath } from 'components/navigation/paths';
import {
    FinanceContract,
    OperatingLeaseContract,
    ContractType,
    EncryptedContractNumber,
    DigitalRenewalOptions,
} from '@cp-es/common';
import { makeClientDecision } from '../makeClientDecision';
import { SuccessModal } from '../success-modal/SuccessModal';
import ParagraphTextComponent from './ParagraphTextComponent';
import DigitalRenewalContentTransformComponent from '../DigitalRenewalContentTransformComponent';
import { useDispatch } from 'react-redux';
import { setSuccessNotification } from 'components/notifications/digital-renewal/DigitalRenewalNotificationSlice';
import { Spinner } from '@cp-shared-6/frontend-ui';

export type DigitalRenewalReturnOfTheCarProps = {
    contract: (FinanceContract | OperatingLeaseContract) & EncryptedContractNumber;
};

export const DigitalRenewalReturnOfTheCar: React.FC<DigitalRenewalReturnOfTheCarProps> = ({ contract }) => {
    const { t } = useTranslation('digital-renewal');
    const history = useHistory();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isError, setIsError] = useState(true);
    const [isSuccess, setIsSuccess] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const dispatch = useDispatch();

    if (!contract) {
        return null;
    }

    const { carInformation, contractType } = contract;
    const contractTypeContent = contractType === ContractType.FINANCING ? 'finance' : 'leasing';
    const description = t('finance.return_of_the_car.modal-success.description');

    const backToDashboard = (): void => history.push(dashboardPagePath());
    const onModalClose = (): void => {
        setIsModalOpen(false);
        backToDashboard();
    };

    const handleSetSuccessMessage = (
        contract: (FinanceContract | OperatingLeaseContract) & EncryptedContractNumber,
        option: DigitalRenewalOptions,
    ): void => {
        dispatch(setSuccessNotification({ contract, selectedOption: option }));
    };

    const handleMakeClientDecision = (
        contract: (FinanceContract | OperatingLeaseContract) & EncryptedContractNumber,
        option: DigitalRenewalOptions,
    ): void => {
        setIsLoading(true);
        const contractNumber = contract._encryptedContractNumber;
        makeClientDecision(contractNumber, option)
            .then(() => {
                setIsSuccess(true);
                handleSetSuccessMessage(contract, option);
            })
            .catch(() => {
                setIsError(true);
            })
            .finally(() => {
                setIsLoading(false);
                setIsModalOpen(true);
            });
    };

    const successModalContent = isSuccess
        ? {
              heading: t('finance.return_of_the_car.modal-success.heading'),
              description: <DigitalRenewalContentTransformComponent content={description} />,
              buttonContent: t('finance.return_of_the_car.modal-success.buttonContent'),
          }
        : isError
        ? {
              heading: t('finance.modal-error.heading'),
              description: t('finance.modal-error.description'),
              buttonContent: t('finance.modal-error.buttonContent'),
          }
        : null;

    return (
        <>
            <Heading level={1}>{t(`${contractTypeContent}.return_of_the_car.firstHeading`)}</Heading>
            <Layout>
                <Layout.Item default="1/1" s="1/1">
                    <Card element="article">
                        <ContractHeader contract={contract} />
                    </Card>
                </Layout.Item>
                {contractType === ContractType.OPERATING_LEASE && (
                    <Layout.Item default="1/1" s="1/1">
                        <Heading level={4}>{t(`${contractTypeContent}.return_of_the_car.paragraph`)}</Heading>
                    </Layout.Item>
                )}
                <Layout.Item default="1/1" s="1/1">
                    <Heading level={3}>{t(`${contractTypeContent}.return_of_the_car.secondHeading`)}</Heading>
                </Layout.Item>
                {contractType === ContractType.FINANCING && (
                    <Layout.Item default="1/1" s="1/1">
                        <Heading level={4}>{t(`${contractTypeContent}.return_of_the_car.thirdHeading`)}</Heading>
                    </Layout.Item>
                )}
                <Layout.Item default="1/1" s="1/1">
                    <Paragraph>
                        <ParagraphTextComponent
                            headingKey="firstParagraphHeading"
                            paragraphKey="firstParagraph"
                            carInformation={carInformation}
                            contractTypeContent={contractTypeContent}
                        />

                        <ParagraphTextComponent
                            headingKey="secondParagraphHeading"
                            paragraphKey="secondParagraph"
                            carInformation={carInformation}
                            contractTypeContent={contractTypeContent}
                        />

                        <ParagraphTextComponent
                            headingKey="thirdParagraphHeading"
                            paragraphKey="thirdParagraph"
                            carInformation={carInformation}
                            contractTypeContent={contractTypeContent}
                        />

                        {contractType === ContractType.OPERATING_LEASE && (
                            <>
                                <ParagraphTextComponent
                                    headingKey="fourthParagraphHeading"
                                    paragraphKey="fourthParagraph"
                                    carInformation={carInformation}
                                    contractTypeContent={contractTypeContent}
                                />
                                <ParagraphTextComponent
                                    headingKey="fifthParagraphHeading"
                                    paragraphKey="fifthParagraph"
                                    carInformation={carInformation}
                                    contractTypeContent={contractTypeContent}
                                />
                                <ParagraphTextComponent
                                    headingKey="sixthHeadline"
                                    paragraphKey=""
                                    carInformation={carInformation}
                                    contractTypeContent={contractTypeContent}
                                />
                            </>
                        )}
                    </Paragraph>
                </Layout.Item>
                {isLoading && (
                    <Layout.Item>
                        <Spinner center />
                    </Layout.Item>
                )}

                <Layout.Item className="u-mt" default="1/1" s="1/1">
                    <ButtonContainer center>
                        <Button
                            secondary
                            onClick={(): void =>
                                history.push(digitalRenewalPagePath(contract._encryptedContractNumber))
                            }
                            testId="backButton"
                        >
                            {t('finance.button.back')}
                        </Button>
                        <Button
                            onClick={() => handleMakeClientDecision(contract, DigitalRenewalOptions.RETURN_OF_THE_CAR)}
                            testId="submitButton"
                        >
                            {t('finance.button.submit')}
                        </Button>
                    </ButtonContainer>
                </Layout.Item>
            </Layout>
            <SuccessModal
                isOpen={isModalOpen}
                onClose={onModalClose}
                onSubmit={onModalClose}
                content={successModalContent}
            />
        </>
    );
};
