import React, { Suspense } from 'react';
import { history } from './store/rootReducer';
import { ConnectedRouter } from 'connected-react-router';
import { LastLocationProvider } from 'react-router-last-location';
import { AuthenticationProvider, AutoLogout, Spinner } from '@cp-shared-6/frontend-ui';
import { AxiosConfiguration } from 'auth/AxiosConfiguration';
import { logoutPath } from './components/navigation/paths';

export const Providers: React.FC = ({ children }) => {
    return (
        <Suspense fallback={<Spinner center />}>
            <AuthenticationProvider loadingComponent={<Spinner center />}>
                <AutoLogout
                    idleTimeInMinutes={10}
                    redirectUri={`${window.location.origin}${logoutPath()}?logoutType=auto`}
                >
                    <AxiosConfiguration>
                        <ConnectedRouter history={history}>
                            <LastLocationProvider>{children}</LastLocationProvider>
                        </ConnectedRouter>
                    </AxiosConfiguration>
                </AutoLogout>
            </AuthenticationProvider>
        </Suspense>
    );
};
