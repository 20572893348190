import { CPDate } from '@cp-shared-6/common-utilities';

export type PostboxDocumentType =
    | 'INVOICE'
    | 'FINANCING_CONTRACT'
    | 'LONG_DRIVE_SERVICE_CONTRACT'
    | 'CARDIF_INSURANCE_POLICY'
    | 'SEPA_MANDATE'
    | 'CONSUMER_CREDIT_INFORMATION'
    | 'WELCOME_LETTER'
    | 'PARTICULAR_CONDITIONS';

export type PostboxDocument = {
    _links: DocumentDownloadLink;
    type: PostboxDocumentType;
    date: CPDate;
    contractNumber?: string;
    operationCode?: string;
    invoiceNumber?: string;
    invoiceAmount?: number;
};

export type DocumentDownloadLink = {
    downloadEndpoint: string;
};

export type PostboxDocumentFile = {
    name: string;
    mime: string;
    content: string;
};

export enum PostboxDocumentCategoryCode {
    INVOICE = 146,
    FINANCING_CONTRACT = 25,
    LONG_DRIVE_SERVICE_CONTRACT = 26,
    CARDIF_INSURANCE_POLICY = 28,
    SEPA_MANDATE = 32,
    CONSUMER_CREDIT_INFORMATION = 35,
    WELCOME_LETTER = 45,
    PARTICULAR_CONDITIONS = 27,
}

export type DocumentDownload = {
    filename: string;
    file: string;
};

export type DocumentError = 'FILE_NOT_FOUND';
