import { onSanitize } from './sanitize';
import { DNI_LETTERS, isValidDni } from './dni';

const NIE_FIRST_LETTERS = 'XYZ';
const NIE_DIGITS = 7;

const NIE_REGEX = new RegExp(`^([${NIE_FIRST_LETTERS}])(\\d{${NIE_DIGITS}})([${DNI_LETTERS}])$`);

const nie = (str: string): boolean => {
    if (!NIE_REGEX.test(str)) return false;
    const firstLetter = str.charAt(0);
    const prefix = NIE_FIRST_LETTERS.indexOf(firstLetter).toString();
    return isValidDni(str.replace(firstLetter, prefix));
};

export const isValidNie = onSanitize(nie);
