import {
    ChangeInvoiceConsentRequest,
    DocumentDownload,
    getInvoiceConsentEndpoint,
    PostboxDocument,
} from '@cp-es/common';
import {
    DefaultBusinessMarketApiErrorCode,
    formatCpDate,
    WithDefaultCpIntegrationErrors,
} from '@cp-shared-6/common-utilities';
import { parseErrorResponse } from '@cp-shared-6/frontend-integration';
import {
    IconList,
    Notification,
    NotificationStatus,
    Spinner,
    useAnalyticsActionTracker,
    useAnalyticsPageViewTracker,
} from '@cp-shared-6/frontend-ui';
import base64ToBlob from 'b64-to-blob';
import { Inbox, Modal } from '@vwfs-bronson/bronson-react';
import { documentTypeTranslationMapping, PostboxDocumentType } from 'components/postbox/helper/DocumentType';
import {
    getGroupedDocuments,
    getSortedGroupKeys,
    GroupedDocuments,
} from 'components/postbox/helper/SortPostboxDocuments';
import { getFilteredDocuments, getTableMonthLocalizations } from 'components/postbox/helper/Table';
import { AllTimeframes, Timeframe } from 'components/postbox/helper/Timeframe';
import { CpDataApi } from 'cp-xhr';
import { saveAs as downloadFileAs } from 'file-saver';
import { TranslationFormat, useTranslationWithFormatting } from 'localization/useTranslationWithFormatting';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { myProfilePagePath } from '../../../navigation/paths';

const InboxTableSectionsWithMessagesUi: React.FC<{
    groupedDocuments: GroupedDocuments;
    displayFilterToStrongWarning: boolean;
    hasInvoiceConsent: boolean;
}> = ({ groupedDocuments, displayFilterToStrongWarning, hasInvoiceConsent }) => {
    const { t: tPostbox, f } = useTranslationWithFormatting('postbox');
    const { t: tInvoiceConsent } = useTranslation('my-profile-invoice-consent');
    const [downloadingFromEndpoint, setDownloadingFromEndpoint] = useState<string | null>(null);
    const [finishDownloadingFromEndpoint, setFinishDownlaodingFromEndpoint] = useState<string | null>(null);
    const [downloadingFromEndpointList, setDownloadingFromEndpointList] = useState<string[]>([]);
    const [downloadError, setDownloadError] = useState<WithDefaultCpIntegrationErrors<
        DefaultBusinessMarketApiErrorCode
    > | null>(null);
    const [showInvoiceSignUpModal, setShowInvoiceSignUpModal] = useState<string | null>(null);
    const { onAction: onDownloadSuccess } = useAnalyticsActionTracker('onPostboxDownload');
    const { onAction: onDownloadError } = useAnalyticsActionTracker('onPostboxDownloadError');
    useAnalyticsPageViewTracker('postboxInvoiceSignUp', !!showInvoiceSignUpModal);
    const { onAction: confirmInvoiceSignUp } = useAnalyticsActionTracker('onPostboxInvoiceSignUpConfirm');
    const { onAction: cancelInvoiceSignUp } = useAnalyticsActionTracker('onPostboxInvoiceSignUpCancel');

    // Use effect hook is necessary due to asnychronious state update
    useEffect(() => {
        if (downloadingFromEndpoint !== null) {
            setDownloadingFromEndpointList([...downloadingFromEndpointList, downloadingFromEndpoint]);
            setDownloadingFromEndpoint(null);
        }
        if (finishDownloadingFromEndpoint !== null) {
            setDownloadingFromEndpointList(
                downloadingFromEndpointList.filter(o => o !== finishDownloadingFromEndpoint),
            );
            setFinishDownlaodingFromEndpoint(null);
        }
    }, [downloadingFromEndpointList, downloadingFromEndpoint, finishDownloadingFromEndpoint]);

    type IconListProps = {
        label: string;
        icon: string;
    };

    const singUpConfirmItems: IconListProps[] = [
        {
            label: tInvoiceConsent('confirm-view.sign-up.content.line1'),
            icon: 'checkmark',
        },
        {
            label: tInvoiceConsent('confirm-view.sign-up.content.line2'),
            icon: 'checkmark',
        },
        {
            label: tInvoiceConsent('confirm-view.sign-up.content.line3'),
            icon: 'checkmark',
        },
    ];

    const sortedGroupKeysDescending = getSortedGroupKeys(groupedDocuments);

    if (displayFilterToStrongWarning) {
        return (
            <Inbox.Section key={'none'} showTableHeader>
                <tr>
                    <td colSpan={3}>
                        <Notification
                            status={NotificationStatus.warning}
                            text={tPostbox('table.errors.no-filtered-documents')}
                        />
                    </td>
                </tr>
            </Inbox.Section>
        );
    }

    /*
     * Downloads a postbox document as pdf.
     * @param endpoint
     */
    const downloadPostboxDocument = (
        endpoint: string | null,
        isInvoice: boolean,
        forceDownload?: boolean,
        documentType?: string,
    ): void => {
        if (endpoint === null) return;

        const sessionConfirmedInvoice = sessionStorage.getItem('sessionConfirmedInvoices')
            ? sessionStorage.getItem('sessionConfirmedInvoices') === 'Y'
            : false;
        const hasConsent = sessionConfirmedInvoice || hasInvoiceConsent;
        if (isInvoice && !hasConsent && !forceDownload) {
            setShowInvoiceSignUpModal(endpoint);
            return;
        }

        setDownloadingFromEndpoint(endpoint);

        CpDataApi.get(endpoint)
            .then(response => {
                const { filename, file: content }: DocumentDownload = response.data;
                const pdfContentType = 'application/pdf';
                const pdfBlob = base64ToBlob(content, pdfContentType);
                downloadFileAs(pdfBlob, filename);
                onDownloadSuccess(documentType);
                setFinishDownlaodingFromEndpoint(endpoint);
                setDownloadError(null);
            })
            .catch(error => {
                const { code, message } = parseErrorResponse<DefaultBusinessMarketApiErrorCode>(error);
                setFinishDownlaodingFromEndpoint(endpoint);
                onDownloadError(documentType, { errorCode: code, errorMessage: message, errorCausingUrl: endpoint });
                setDownloadError(code);
            });
    };

    /*
     * Signs-up for digital invoices.
     */
    const signUpForInvoices = () => {
        const invoiceChangeBody: ChangeInvoiceConsentRequest = {
            signUp: false,
        };
        CpDataApi.put(getInvoiceConsentEndpoint(), invoiceChangeBody)
            .then(() => {
                sessionStorage.setItem('sessionConfirmedInvoices', 'Y');

                downloadPostboxDocument(showInvoiceSignUpModal, true, true);
                setShowInvoiceSignUpModal(null);
            })
            .catch(() => {
                sessionStorage.setItem('sessionConfirmedInvoices', 'Y');
                downloadPostboxDocument(downloadingFromEndpoint, true);
                setShowInvoiceSignUpModal(null);
            });
    };

    return (
        <>
            {sortedGroupKeysDescending.map(groupYearKey => {
                const group: PostboxDocument[] = groupedDocuments[groupYearKey];
                const year = groupYearKey;

                const messages: React.ReactNode[] = group.map((document, index) => {
                    const downloadLink = document._links.downloadEndpoint;
                    const isInvoice = document.type === 'INVOICE';
                    const documentTypeLable = isInvoice
                        ? ` ${document.invoiceNumber} (${f(document.invoiceAmount, TranslationFormat.CURRENCY)})`
                        : ` (${document.operationCode})`;
                    const mandatoryFieldIsMissingInvoice =
                        !document.invoiceNumber || !document.invoiceAmount || !downloadLink;
                    const mandatoryFieldIsMissingOthers =
                        !document.date || !document.type || !document.operationCode || !downloadLink;
                    const mandatoryFieldIsMissing = isInvoice
                        ? mandatoryFieldIsMissingInvoice
                        : mandatoryFieldIsMissingOthers;
                    if (mandatoryFieldIsMissing) {
                        return null;
                    }
                    const month = formatCpDate(document.date)
                        .toMoment()
                        .month()
                        .toString();
                    const day = formatCpDate(document.date)
                        .toMoment()
                        .date()
                        .toString();
                    const documentType: React.ReactNode = (
                        <React.Fragment key={`${groupYearKey}-${index}-document-type`}>
                            <strong>
                                {tPostbox(
                                    `filters.document-type.select-items.${
                                        documentTypeTranslationMapping[document.type]
                                    }`,
                                )}
                            </strong>
                            {documentTypeLable}
                        </React.Fragment>
                    );

                    const isDownloadingCurrentDocument = downloadingFromEndpointList.find(o => o === downloadLink);

                    return (
                        <React.Fragment key={`${groupYearKey}-${index}-fragment`}>
                            {isDownloadingCurrentDocument ? (
                                <tr key={`${groupYearKey}-${index}-message`}>
                                    <td colSpan={3}>
                                        <Spinner small />
                                    </td>
                                </tr>
                            ) : (
                                <Inbox.Message
                                    key={`${groupYearKey}-${index}-message`}
                                    testId={`inboxMessage-${groupYearKey}-${index}`}
                                    year={year}
                                    month={month}
                                    day={day}
                                    read={true}
                                    download={true}
                                    onDownloadClick={() =>
                                        downloadPostboxDocument(downloadLink, isInvoice, false, document.type)
                                    }
                                    onItemClick={() =>
                                        downloadPostboxDocument(downloadLink, isInvoice, false, document.type)
                                    }
                                >
                                    {documentType}
                                </Inbox.Message>
                            )}
                        </React.Fragment>
                    );
                });

                return (
                    <Inbox.Section key={groupYearKey} year={groupYearKey} showYear showTableHeader>
                        {messages.map(message => message)}
                    </Inbox.Section>
                );
            })}
            {/* Error Modal */}
            <Modal
                shown={!!downloadError}
                buttonConfirmText={tPostbox('table.download-failure-modal.accept-button')}
                onConfirm={(): void => setDownloadError(null)}
                onClose={(): void => setDownloadError(null)}
                title={tPostbox('table.download-failure-modal.title')}
                status="error"
                testId="downloadFailureModal"
            >
                {tPostbox('table.download-failure-modal.text')}
            </Modal>
            {/* Sign-up Modal */}
            <Modal
                shown={!!showInvoiceSignUpModal}
                buttonConfirmText={tPostbox('table.consent-signup-modal.signup-button')}
                buttonCancelText={tPostbox('translation:editableSectionNav.cancel')}
                onConfirm={(): void => {
                    confirmInvoiceSignUp();
                    signUpForInvoices();
                }}
                onCancel={(): void => {
                    cancelInvoiceSignUp();
                    setShowInvoiceSignUpModal(null);
                }}
                onClose={(): void => {
                    cancelInvoiceSignUp();
                    setShowInvoiceSignUpModal(null);
                }}
                title={tInvoiceConsent('confirm-view.sign-up.headline')}
                status="info"
                testId="invoiceSignUpModal"
            >
                <p>{tInvoiceConsent('confirm-view.sign-up.content.heading')}</p>
                <IconList items={singUpConfirmItems} />
            </Modal>
        </>
    );
};

export type PostboxTableUiProps = {
    postboxDocuments?: PostboxDocument[];
    activeDocumentTypeFilter: PostboxDocumentType;
    activeTimeframeFilter: Timeframe | AllTimeframes;
    activeContractFilter: string;
    hasInvoiceConsent: boolean;
    hasOperativeLeaseContracts: boolean;
};

export const PostboxTableUi: React.FC<PostboxTableUiProps> = ({
    postboxDocuments,
    activeDocumentTypeFilter,
    activeTimeframeFilter,
    activeContractFilter,
    hasInvoiceConsent,
    hasOperativeLeaseContracts,
}) => {
    const { t } = useTranslation('postbox');

    if (!postboxDocuments) {
        return null;
    }

    if (postboxDocuments.length === 0) {
        return <Notification status={NotificationStatus.warning} text={t('table.errors.no-documents-found')} />;
    }

    const filteredDocuments = getFilteredDocuments(
        postboxDocuments,
        activeDocumentTypeFilter,
        activeContractFilter,
        activeTimeframeFilter,
    );

    const displayFilterToStrongWarning = filteredDocuments.length === 0;

    const groupedDocuments = getGroupedDocuments(filteredDocuments);

    return (
        <>
            <Inbox
                tableHeader={[
                    t('table.column-headers.date'),
                    t('table.column-headers.download'),
                    t('table.column-headers.document-type'),
                ]}
                localization={getTableMonthLocalizations(t)}
                testId={'postbox-inbox'}
            >
                <InboxTableSectionsWithMessagesUi
                    groupedDocuments={groupedDocuments}
                    displayFilterToStrongWarning={displayFilterToStrongWarning}
                    hasInvoiceConsent={hasInvoiceConsent}
                />
            </Inbox>
            {hasOperativeLeaseContracts && (
                <>
                    <p>{t('table.invoice-disclaimer')}</p>
                    {hasInvoiceConsent && (
                        <div
                            dangerouslySetInnerHTML={{
                                __html: t('table.sign-off-entrypoint', { myProfilePath: myProfilePagePath() }),
                            }}
                        />
                    )}
                </>
            )}
        </>
    );
};
