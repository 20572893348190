import React from 'react';
import { DATE_FORMAT, DueDateEditStatus, FinanceFinancialDetails } from '@cp-es/common';
import { ConsultView } from './consult-view/ConsultView';
import { EditView } from './edit-view/EditView';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import { changeMonthlyDueDatePath } from 'components/navigation/paths';
import { CPDate, formatCpDate, WithDefaultMarketApiErrors } from '@cp-shared-6/common-utilities';
import { useUpdateFinancialDetailsPerContract as useUpdateFinancialDetails } from '../../useUpdateFinancialDetails';
import { useAnalyticsActionTracker } from '@cp-shared-6/frontend-ui';

export type FinanceDueDateSectionProps = {
    encryptedContractId: string;
    contractId: string;
    details: FinanceFinancialDetails;
    hasUnpaidBalance?: boolean;
};

export const DueDateSection: React.FC<FinanceDueDateSectionProps> = ({
    encryptedContractId,
    contractId,
    details,
    hasUnpaidBalance,
}) => {
    const history = useHistory();
    const { path } = useRouteMatch();
    const { onAction: onEditStart } = useAnalyticsActionTracker('financialDetailsEditDueDateSection');
    const { onAction: onEditSuccess } = useAnalyticsActionTracker('financialDetailsEditDueDateSectionSuccess');
    const { onAction: onEditFail } = useAnalyticsActionTracker('financialDetailsEditDueDateSectionSubmitFailed');
    const { updateFinancialDetails, financialDetailsData } = useUpdateFinancialDetails(contractId);

    const setLastEditStatus = (
        newEditStatus: WithDefaultMarketApiErrors<DueDateEditStatus>,
        selectedDueDate?: number,
        upcomingDueDate?: CPDate,
    ): void => {
        const serializedData: FinanceFinancialDetails = {
            ...financialDetailsData,
            upcomingDueDate: formatCpDate(upcomingDueDate).toCpDate(),
            dueDate: selectedDueDate ?? (financialDetailsData as FinanceFinancialDetails).dueDate,
            lastEditStatus: { ...financialDetailsData?.lastEditStatus, dueDate: newEditStatus },
        };
        updateFinancialDetails(serializedData);
    };

    const startEditing = (): void => {
        onEditStart();
        setLastEditStatus('NOT_PERFORMED');
        history.push(changeMonthlyDueDatePath(encryptedContractId));
    };
    const cancelEditing = (): void => {
        setLastEditStatus('NOT_PERFORMED');
        history.push(path);
    };
    const finishEditing = (
        newEditStatus: WithDefaultMarketApiErrors<DueDateEditStatus>,
        selectedDueDate?: number,
        upcomingDueDate?: CPDate,
    ): void => {
        if (newEditStatus === 'SUCCESS' || newEditStatus === 'SAME_DATE_SELECTED') onEditSuccess();
        setLastEditStatus(newEditStatus, selectedDueDate, upcomingDueDate);
        history.push(path);
    };

    return (
        <Switch>
            {details.dueDate && details._links?.changeMonthlyDueDate && (
                <Route exact path={changeMonthlyDueDatePath(encryptedContractId)}>
                    <EditView
                        dueDate={String(details.dueDate)}
                        changeMonthlyDueDateEndpoint={details._links.changeMonthlyDueDate}
                        cancelEditing={cancelEditing}
                        finishEditing={finishEditing}
                        onEditFail={onEditFail}
                    />
                </Route>
            )}
            <Route path={path}>
                <ConsultView
                    details={details}
                    startEditing={startEditing}
                    lastEditStatus={financialDetailsData?.lastEditStatus?.dueDate ?? 'NOT_PERFORMED'}
                    upcomingDueDate={formatCpDate(financialDetailsData?.upcomingDueDate).format(DATE_FORMAT)}
                    hasUnpaidBalance={hasUnpaidBalance}
                />
            </Route>
        </Switch>
    );
};
