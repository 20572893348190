import { Button, ButtonContainer, DataOverview, Fieldset, Form, Layout, Modal } from '@vwfs-bronson/bronson-react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Contact, ContactChange, PersonalDetailsEditStatus, PersonalDetailsUpdateError } from '@cp-es/common';
import {
    preventSubmit,
    UiBlockingSpinner,
    useAnalyticsActionTracker,
    useAnalyticsFormTracker,
    useAnalyticsPageViewTracker,
    ValidatedInput,
    withAutoScroll,
} from '@cp-shared-6/frontend-ui';
import { Formik } from 'formik';
import { CpDataApi } from 'cp-xhr';
import { trimAllValues } from '@cp-shared-6/common-utilities';
import { parseErrorResponse } from '@cp-shared-6/frontend-integration';
import { useContactValidation } from './useValidation';
import { isEmpty } from 'lodash';

export type EditViewProps = {
    cancelEditing: () => void;
    finishEditing: (newEditStatus: PersonalDetailsEditStatus, updatedContact?: Contact) => void;
    contact: Contact;
    hasOperativeLeaseContracts: boolean;
    lastEditStatus: PersonalDetailsEditStatus;
};

export const EditView: React.FC<EditViewProps> = withAutoScroll(
    ({ cancelEditing, finishEditing, contact, hasOperativeLeaseContracts, lastEditStatus }) => {
        const [showConfirmationModal, setShowConfirmationModal] = useState(false);
        const [isSubmitting, setIsSubmitting] = useState(false);
        const [lastEnteredValues, setLastEnteredValues] = useState<ContactChange>();
        const validationSchema = useContactValidation();

        const trackingSection = 'Contact';
        useAnalyticsPageViewTracker('editProfileSectionDisplayed', lastEditStatus === 'NOT_PERFORMED', trackingSection);
        const { onAction: onValidationError } = useAnalyticsActionTracker('onEditProfileContactValidationError');
        const { onTyping, onError, onSuccess } = useAnalyticsFormTracker({
            startTyping: 'onEditProfileContactTypedIn',
            confirmError: 'onEditProfileContactSubmitFailed',
            confirmSuccess: 'onEditProfileContactSuccess',
        });
        const { onAction: onShowConfirmationView } = useAnalyticsActionTracker('onEditProfileConfirmation');
        const { onAction: onCancelConfirmation } = useAnalyticsActionTracker('onEditProfileConfirmationCancel');

        const { t } = useTranslation('my-profile-contact-details');

        const getInitialErrors = (values: { [k: string]: string | undefined }) =>
            ['email', 'primaryPhoneNumber'].filter(element => !values[element] || values[element] === '').join(', ');
        const getErrors = (errors: { [k: string]: string | undefined }) => Object.keys(errors).join(`, `);

        const openConfirmationModal = (): void => setShowConfirmationModal(true);
        const closeConfirmationModal = (cancel = true): void => {
            if (cancel) {
                onCancelConfirmation(trackingSection);
            }
            setShowConfirmationModal(false);
        };

        // The fallback to empty string is crucial as otherwise the symmetry with validation schema breaks leading to warnings
        const initialValues: ContactChange = {
            email: lastEnteredValues?.email ?? contact.email ?? '',
            primaryPhoneNumber: lastEnteredValues?.primaryPhoneNumber ?? contact.primaryPhoneNumber ?? '',
            secondaryPhoneNumber: lastEnteredValues?.secondaryPhoneNumber ?? contact.secondaryPhoneNumber ?? '',
            invoiceEmail: hasOperativeLeaseContracts
                ? lastEnteredValues?.invoiceEmail ?? contact.invoiceEmail ?? ''
                : undefined,
        };

        const onSubmitForm = () => {
            openConfirmationModal();
            onShowConfirmationView(trackingSection);
        };

        const onSubmit = (values: ContactChange): void => {
            if (!contact._links?.changeContactDetails) {
                return;
            }

            const contactChangeValues = trimAllValues<ContactChange>(values);
            setIsSubmitting(true);
            setLastEnteredValues(contactChangeValues);
            CpDataApi.put(contact._links?.changeContactDetails, contactChangeValues)
                .then(response => {
                    setIsSubmitting(false);
                    const editStatus = response.status === 202 ? 'SUCCESS_PENDING' : 'SUCCESS';
                    const updatedContact = { ...contact, ...contactChangeValues };
                    finishEditing(editStatus, updatedContact);
                    onSuccess();
                })
                .catch(error => {
                    onError();
                    setIsSubmitting(false);
                    const errorCode = parseErrorResponse<PersonalDetailsUpdateError>(error).code;
                    switch (errorCode) {
                        case 'CHANGE_ERROR':
                        case 'PARTIAL_CHANGE_ERROR':
                            finishEditing(errorCode);
                            break;
                        default:
                            finishEditing('UNEXPECTED_ERROR');
                            break;
                    }
                });
        };

        return (
            <DataOverview title={t('editView.title')}>
                <UiBlockingSpinner isBlocking={isSubmitting}>
                    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmitForm}>
                        {({ errors, touched, values, submitForm }): JSX.Element => (
                            <>
                                <Form
                                    onSubmit={preventSubmit()}
                                    data-testid="editForm"
                                    onChange={(): void => onTyping(errors, touched)}
                                >
                                    <Fieldset>
                                        <Fieldset.Row>
                                            <Layout>
                                                <Layout.Item default="1/2" s="1/1">
                                                    <ValidatedInput
                                                        label={t('editView.email.label')}
                                                        tooltip={t('editView.email.tooltip')}
                                                        name="email"
                                                        testId="email"
                                                        addonText={t('editView.emailPrefix')}
                                                        reversed
                                                        type="email"
                                                    />
                                                </Layout.Item>
                                                {hasOperativeLeaseContracts ? (
                                                    <Layout.Item default="1/2" s="1/1">
                                                        <ValidatedInput
                                                            label={t('editView.invoiceEmail.label')}
                                                            tooltip={t('editView.invoiceEmail.tooltip')}
                                                            name="invoiceEmail"
                                                            testId="invoiceEmail"
                                                            addonText={t('editView.emailPrefix')}
                                                            reversed
                                                            type="email"
                                                        />
                                                    </Layout.Item>
                                                ) : (
                                                    <Layout.Item default="1/2" className="u-hide@s" />
                                                )}
                                                <Layout.Item default="1/2" s="1/1">
                                                    <ValidatedInput
                                                        label={t('editView.primaryPhoneNumber.label')}
                                                        name="primaryPhoneNumber"
                                                        testId="primaryPhoneNumber"
                                                        addonText={t('editView.phonePrefix')}
                                                        reversed
                                                        type="tel"
                                                    />
                                                </Layout.Item>
                                                <Layout.Item default="1/2" s="1/1">
                                                    <ValidatedInput
                                                        label={t('editView.secondaryPhoneNumber.label')}
                                                        name="secondaryPhoneNumber"
                                                        testId="secondaryPhoneNumber"
                                                        addonText={t('editView.phonePrefix')}
                                                        reversed
                                                        type="tel"
                                                    />
                                                </Layout.Item>
                                            </Layout>
                                        </Fieldset.Row>
                                    </Fieldset>
                                    <Fieldset>
                                        <Fieldset.Row>
                                            <ButtonContainer nav>
                                                <Button
                                                    secondary
                                                    onClick={cancelEditing}
                                                    testId="cancelButton"
                                                    type="btn"
                                                >
                                                    {t('translation:editableSectionNav.cancel')}
                                                </Button>
                                                <Button
                                                    onClick={() => {
                                                        submitForm();
                                                        const initialErrors = getInitialErrors({
                                                            email: values.email,
                                                            primaryPhoneNumber: values.primaryPhoneNumber,
                                                        });
                                                        if (!isEmpty(errors)) {
                                                            const errorsList = getErrors(errors);
                                                            onValidationError(errorsList);
                                                        } else if (!!initialErrors) {
                                                            onValidationError(initialErrors);
                                                        }
                                                    }}
                                                    testId="submitButton"
                                                    type="btn"
                                                >
                                                    {t('translation:editableSectionNav.submit')}
                                                </Button>
                                            </ButtonContainer>
                                        </Fieldset.Row>
                                    </Fieldset>
                                </Form>
                                <Modal
                                    shown={showConfirmationModal}
                                    buttonConfirmText={t('translation:editableSectionNav.confirm')}
                                    buttonCancelText={t('translation:editableSectionNav.cancel')}
                                    onConfirm={(): void => {
                                        onSubmit(values);
                                        closeConfirmationModal(false);
                                    }}
                                    onCancel={closeConfirmationModal}
                                    onClickOutside={closeConfirmationModal}
                                    onClose={closeConfirmationModal}
                                    testId="confirmModal"
                                >
                                    <div dangerouslySetInnerHTML={{ __html: t('editView.confirmationModal.text') }} />
                                </Modal>
                            </>
                        )}
                    </Formik>
                </UiBlockingSpinner>
            </DataOverview>
        );
    },
    'ContactSectionEditView',
);
