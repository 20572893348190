import { LegalFooterDto, getLegalFooterEndpoint } from '@cp-es/common';
import { createCmsContentSlice } from 'cms-integration';

const { reducer, fetchContent } = createCmsContentSlice<LegalFooterDto>({
    contentName: 'legalFooter',
    contentEndpoint: getLegalFooterEndpoint,
});

export default reducer;
export const fetchLegalFooter = fetchContent;
