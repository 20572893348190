import { useGetSimpleApiData } from '@cp-shared-6/frontend-integration';

import { MyProfileSelector } from './MyProfileSelector';
import { fetchMyProfile } from './MyProfileSlice';

/**
 * Fetches all personal details
 */
export function usePersonalDetails() {
    return useGetSimpleApiData(fetchMyProfile, MyProfileSelector);
}
