import { DataOverview, Form } from '@vwfs-bronson/bronson-react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    preventSubmit,
    UiBlockingSpinner,
    useAnalyticsActionTracker,
    useAnalyticsFormTracker,
    useAnalyticsPageViewTracker,
    withAutoScroll,
} from '@cp-shared-6/frontend-ui';
import { Formik } from 'formik';
import {
    ChangeInvoiceConsentResponse,
    getInvoiceConsentEndpoint,
    InvoiceConsent,
    InvoiceConsentError,
    PersonalDetailsEditStatus,
} from '@cp-es/common';
import { WithDefaultCpIntegrationErrors } from '@cp-shared-6/common-utilities';
import { CpDataApi } from '../../../../cp-xhr';
import { ConfirmationView } from './confirmation-view/ConfirmationView';
import { FormView } from './form-view/FormView';
import { parseErrorResponse } from '@cp-shared-6/frontend-integration';

export type EditViewProps = {
    cancelEditing: () => void;
    finishEditing: (
        newEditStatus: WithDefaultCpIntegrationErrors<PersonalDetailsEditStatus>,
        newIsSignUp: boolean,
    ) => void;
    invoiceConsent: InvoiceConsent;
    lastEditStatus: WithDefaultCpIntegrationErrors<PersonalDetailsEditStatus>;
};

export const EditView: React.FC<EditViewProps> = withAutoScroll(
    ({ cancelEditing, invoiceConsent, finishEditing, lastEditStatus }) => {
        const { t } = useTranslation('my-profile-invoice-consent');

        const [isSubmitting, setIsSubmitting] = useState(false);
        const [showConfirmation, setShowConfirmation] = useState(false);
        const [dataOverviewTitle, setDataOverviewTitle] = useState(t('edit-view.title'));

        const trackingSection = 'Consent settings';
        useAnalyticsPageViewTracker('editProfileSectionDisplayed', lastEditStatus === 'NOT_PERFORMED', trackingSection);
        const { onTyping, onError } = useAnalyticsFormTracker({
            startTyping: 'onEditProfileConsentSettingsTypedIn',
            confirmError: 'onEditProfileConsentSettingsSubmitFailed',
        });
        const { onAction: onSuccess } = useAnalyticsActionTracker('onEditProfileConsentSettingsSuccess');
        const { onAction: onShowConfirmationView } = useAnalyticsActionTracker('onEditProfileConfirmation');
        const { onAction: onCancelConfirmation } = useAnalyticsActionTracker('onEditProfileConfirmationCancel');

        type FormType = {
            signUp: boolean;
        };

        const initialConsentValue = !!invoiceConsent.signUp;

        const initialValues: FormType = {
            signUp: initialConsentValue,
        };

        const onSubmit = (values: FormType): void => {
            setIsSubmitting(true);
            const requestBody: ChangeInvoiceConsentResponse = {
                signUp: values.signUp,
            };
            CpDataApi.put(getInvoiceConsentEndpoint(), requestBody)
                .then(() => {
                    setIsSubmitting(false);
                    finishEditing('SUCCESS', values.signUp);
                    onSuccess(undefined, values.signUp.toString());
                })
                .catch(error => {
                    setIsSubmitting(false);
                    const errorCode = parseErrorResponse<WithDefaultCpIntegrationErrors<InvoiceConsentError>>(error)
                        .code;
                    finishEditing(errorCode, values.signUp);
                    onError();
                });
        };

        const toNextView = (hasSignedUp: boolean): void => {
            setShowConfirmation(true);
            onShowConfirmationView(trackingSection);
            if (hasSignedUp) {
                setDataOverviewTitle(t('confirm-view.sign-up.headline'));
            } else {
                setDataOverviewTitle(t('confirm-view.sign-off.headline'));
            }
        };

        const toPreviousView = (): void => {
            onCancelConfirmation(trackingSection);
            setShowConfirmation(false);
            setDataOverviewTitle(t('edit-view.title'));
        };

        return (
            <DataOverview title={dataOverviewTitle}>
                <UiBlockingSpinner isBlocking={isSubmitting}>
                    <Formik initialValues={initialValues} onSubmit={onSubmit}>
                        {formik => (
                            <Form
                                onSubmit={preventSubmit()}
                                data-testid="editForm"
                                onChange={(): void => onTyping(formik.errors, formik.touched)}
                            >
                                {!showConfirmation && (
                                    <FormView name={'signUp'} toNextView={toNextView} cancelEditing={cancelEditing} />
                                )}
                                {showConfirmation && (
                                    <ConfirmationView
                                        invoiceConsentChecked={formik.values.signUp}
                                        toPreviousView={toPreviousView}
                                        onSubmit={formik.handleSubmit}
                                    />
                                )}
                            </Form>
                        )}
                    </Formik>
                </UiBlockingSpinner>
            </DataOverview>
        );
    },
    'ConsentSectionEditView',
);
