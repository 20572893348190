import { onSanitize } from './sanitize';

const ASSOCIATION_PROVINCE_CODE = '00';
const NUMBER_ASSOCIATIONS = 'ABEH';
const LETTER_ASSOCIATIONS = 'PQSW';
const BOTH_ASSOCIATIONS = 'CDFGJNRUV';
type Association = 'letter' | 'number' | 'both';

const CIF_FIRST_LETTERS = `${NUMBER_ASSOCIATIONS}${LETTER_ASSOCIATIONS}${BOTH_ASSOCIATIONS}`;
const CIF_DIGITS = 7;
const CIF_LETTERS = 'JABCDEFGHI';
const CIF_CONTROL_SIGN = `0-9${CIF_LETTERS}`;

const CIF_REGEX = new RegExp(`^([${CIF_FIRST_LETTERS}])(\\d{${CIF_DIGITS}})([${CIF_CONTROL_SIGN}])$`);

const typeOfControl = (str: string): Association => {
    const firstLetter = str.charAt(0);
    const provinceCode = str.substr(1, 2);
    if (provinceCode === ASSOCIATION_PROVINCE_CODE || LETTER_ASSOCIATIONS.includes(firstLetter)) return 'letter';
    if (NUMBER_ASSOCIATIONS.includes(firstLetter)) return 'number';
    return 'both';
};
const sumDigits = (number: number): number =>
    (number * 2)
        .toString()
        .split('')
        .map(Number)
        .reduce((acc, curr) => acc + curr, 0);
const getControlNumber = (str: string): number =>
    Number(
        str
            .substr(1, CIF_DIGITS)
            .split('')
            .map(Number)
            .map((value, index) => (index % 2 ? value : sumDigits(value)))
            .reduce((acc, curr) => acc + curr, 0)
            .toString()
            .substr(-1),
    );
const getControl = (str: string): [number, string] => {
    const controlNumbers = (10 - getControlNumber(str)) % 10;
    return [controlNumbers, CIF_LETTERS[controlNumbers]];
};

const cif = (str: string): boolean => {
    if (!CIF_REGEX.test(str)) return false;
    const [controlDigit, controlLetter] = getControl(str);
    const type = typeOfControl(str);
    if (type === 'letter') return str.endsWith(controlLetter);
    if (type === 'number') return str.endsWith(controlDigit.toString());
    if (type === 'both') return str.endsWith(controlLetter) || str.endsWith(controlDigit.toString());
    return false;
};

export const isValidCif = onSanitize(cif);
