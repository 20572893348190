export function getPersonalDetailsEndpoint(): string {
    return '/personal-details';
}

export function getContactDetailsEndpoint(contractId: string): string {
    return `/personal-details/${contractId}/contact-details`;
}

export function getAddressEndpoint(contractId: string): string {
    return `/personal-details/${contractId}/address`;
}

export function getInvoiceConsentEndpoint(): string {
    return '/personal-details/invoice-consent';
}
