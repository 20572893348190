import React from 'react';
import {
    HeroTeaserLoadingPlaceholder,
    HeroImage as HeroImageShared,
    useAnalyticsPageViewTracker,
    useAuthentication,
} from '@cp-shared-6/frontend-ui';
import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import { useLogin } from 'auth/useLogin';
import { useLandingPageTeaser } from './useLandingPageTeaser';

const HeroImageWithWrappers = withLoadingAndNoConnectionHandler(HeroImageShared);

export const LandingPageHeroTeaser: React.FC = () => {
    const { cmsContent: landingPageHeroTeaser, isLoading, loadingError } = useLandingPageTeaser();
    const { isAuthenticated } = useAuthentication();
    const onLoginCallback = useLogin();

    useAnalyticsPageViewTracker('landingPage', !!landingPageHeroTeaser, isAuthenticated);

    return (
        <HeroImageWithWrappers
            isLoading={isLoading}
            hasError={!!loadingError}
            title={landingPageHeroTeaser?.title || ''}
            imageUrl={landingPageHeroTeaser?.imageUrl || ''}
            subTitle={landingPageHeroTeaser?.subTitle}
            text={landingPageHeroTeaser?.text}
            buttonText={landingPageHeroTeaser?.buttonText}
            clickHandler={onLoginCallback}
            inverted={!!landingPageHeroTeaser?.isInverted}
            loadingPlaceholder={<HeroTeaserLoadingPlaceholder withTitle withSubTitle withButton />}
        />
    );
};
