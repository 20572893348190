import React from 'react';
import { useContracts } from '../contracts/useContracts';
import { withLoadingAndNoConnectionHandler } from '../integration-wrapper';
import { MyRequestUi } from './ui';
import { usePersonalDetails } from '../my-profile/usePersonalDetails';
import { RequestLoadingPlaceholder, useAnalyticsPageViewTracker } from '@cp-shared-6/frontend-ui';

const MyRequestWithHandlers = withLoadingAndNoConnectionHandler(MyRequestUi);

export const MyRequest: React.FC = () => {
    const { data: contracts, isLoading: isLoadingContracts, loadingError: loadingErrorContracts } = useContracts();
    const { data: myDetailsList, isLoading: isLoadingProfile } = usePersonalDetails();

    const isLoading = isLoadingContracts || isLoadingProfile;

    useAnalyticsPageViewTracker('requests');

    return (
        <div>
            <MyRequestWithHandlers
                isLoading={isLoading}
                hasError={!!loadingErrorContracts}
                contracts={contracts}
                email={myDetailsList?.contact?.email}
                loadingPlaceholder={<RequestLoadingPlaceholder withTextArea numberOfButtons={1} />}
            />
        </div>
    );
};
