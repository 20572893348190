import * as Yup from 'yup';
import { MyRequest } from '../../apis';
import { defaultMyRequestValidatorError } from '../messages';
import { MyRequestValidatorError } from '../types';

export const MAX_SHORT_DESCRIPTION = 40;
export const MAX_REQUEST = 1000;
export const myRequestValidator = (
    errors: MyRequestValidatorError = defaultMyRequestValidatorError,
): Yup.SchemaOf<MyRequest> =>
    Yup.object()
        .shape({
            email: Yup.string()
                .trim()
                .email(errors.email.email)
                .required(errors.email.required),
            category: Yup.string().required(errors.category.required),
            contractInfo: Yup.object()
                .default(undefined)
                .nullable()
                .shape({
                    licensePlate: Yup.string(),
                    contractId: Yup.string(),
                    contractType: Yup.string(),
                }),
            shortDescription: Yup.string()
                .trim()
                .required(errors.shortDescription.required)
                .max(MAX_SHORT_DESCRIPTION, errors.shortDescription.max),
            request: Yup.string()
                .trim()
                .required(errors.request.required)
                .max(MAX_REQUEST, errors.request.max),
        })
        .defined();
