import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Modal } from '@vwfs-bronson/bronson-react';
import { useTranslationWithFormatting } from 'localization/useTranslationWithFormatting';
import { cecaUrl } from 'config';
import { PaymentPayload } from '@cp-es/common';

export type TimeoutModalProps = {
    handlePayNowButtonClick(flag: boolean): void;
    payload?: PaymentPayload;
};

export const SECONDS_BEFORE_AUTO_REDIRECT = 3;

export const TimeoutModal: React.FC<TimeoutModalProps> = ({ handlePayNowButtonClick, payload = {} }) => {
    const { t } = useTranslationWithFormatting('unpaid-details');
    const [counter, setCounter] = useState(SECONDS_BEFORE_AUTO_REDIRECT);
    const [showModal, setShowModal] = useState(true);
    const formRef = useRef<HTMLFormElement>(null);
    // UseRef (instead of useState) is used to store the current setTimeout function because it`s change will
    // not lead to a unnecessary re-render of the component
    const timer = useRef<number | null>(null);

    useEffect(() => {
        // Kill timeout function on unmount
        return (): void => {
            if (timer.current) {
                clearTimeout(timer.current);
            }
        };
    }, []);

    const handleModalClose = useCallback(() => {
        handlePayNowButtonClick(false);
        setShowModal(false);
    }, [handlePayNowButtonClick]);

    const handleRedirect = useCallback(() => {
        if (formRef.current && payload) {
            formRef.current.submit();
        }
        handlePayNowButtonClick(false);
    }, [handlePayNowButtonClick, payload]);

    const handleTimeout = useCallback(() => {
        timer.current = window.setTimeout(() => {
            setCounter(counter - 1);
            if (counter === 1) handleRedirect();
        }, 1000);
        return (): void => {
            if (timer.current) {
                clearTimeout(timer.current);
            }
        };
    }, [counter, handleRedirect]);

    useEffect(() => {
        if (counter > 0) handleTimeout();
    }, [counter, handleTimeout]);

    const payloadData = payload as { [key: string]: string };
    return (
        <>
            <Modal
                shown={showModal}
                title={t('modal.title')}
                buttonCancelText={t('translation:editableSectionNav.cancel')}
                onCancel={handleModalClose}
                onClickOutside={handleModalClose}
                onClose={handleModalClose}
                testId="timeoutModal"
            >
                <div>
                    {`${t('modal.content', { counter })} `}
                    <a
                        href="#"
                        data-testid="redirectButton"
                        onClick={(e: React.MouseEvent): void => {
                            e.preventDefault();
                            handleRedirect();
                        }}
                    >
                        {t('modal.label-redirect-button')}
                    </a>
                    .
                </div>
                <form data-testid="cecaForm" ref={formRef} action={cecaUrl} method="post">
                    {Object.keys(payload).map((key: string) => (
                        <input data-testid={key} key={key} type="hidden" name={key} value={payloadData[key]} />
                    ))}
                </form>
            </Modal>
        </>
    );
};
