import React from 'react';
import { Layout, DefinitionTable, Card } from '@vwfs-bronson/bronson-react';
import { LicensePlate } from '../../license-plate';
import { TranslationFormat, useTranslationWithFormatting } from '../../../localization/useTranslationWithFormatting';
import { ContractType } from '@cp-es/common';
import { getProductCategoryTranslationLabel } from 'components/contracts/ProductCategoryTranslationLabel';

export type UnpaidDetailsHeaderProps = {
    carInformation: string;
    contractNumber: string;
    licensePlateNumber?: string;
    contractType: ContractType;
    unpaidInstallments: number;
    unpaidAmount: number;
};
export const UnpaidDetailsContractHeader: React.FC<UnpaidDetailsHeaderProps> = ({
    carInformation,
    contractNumber,
    licensePlateNumber,
    contractType,
    unpaidInstallments,
    unpaidAmount,
}) => {
    const { t, f } = useTranslationWithFormatting('unpaid-details');
    if (unpaidAmount <= 0) return null;
    return (
        <Card element="article">
            <Layout equalHeight divider>
                <Layout.Item default="1/2" s="1/1">
                    <div className="u-text-left@m-up  u-mr-none cp-contract-identifier-slot">
                        <LicensePlate size={'small'} licensePlateNumber={licensePlateNumber} className="u-mb" />
                        <p className="u-zeta">{carInformation}</p>
                        <p>{`${t(getProductCategoryTranslationLabel(contractType))} ${contractNumber}`}</p>
                    </div>
                </Layout.Item>
                <Layout.Item default="1/2" s="1/1">
                    <DefinitionTable>
                        <DefinitionTable.Section
                            summary
                            summaryTitle={t('unpaid-amount')}
                            summaryData={f(unpaidAmount, TranslationFormat.CURRENCY)}
                        >
                            <DefinitionTable.Item title={t('unpaid-installments')}>
                                {unpaidInstallments}
                            </DefinitionTable.Item>
                        </DefinitionTable.Section>
                    </DefinitionTable>
                </Layout.Item>
            </Layout>
        </Card>
    );
};
