import { ContentSection } from '@vwfs-bronson/bronson-react';
import React from 'react';

import { CookiePolicyDto as CookiePolicyType } from '@cp-es/common';
import { useCmsContent } from '@cp-shared-6/frontend-integration';
import {
    StaticPageLoadingPlaceholder,
    CookieTable,
    CookiePolicy as CookiePolicyShared,
    HeroImage,
} from '@cp-shared-6/frontend-ui';
import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import { RootState } from 'store/rootReducer';

import { fetchCookiePolicy } from './CookiePolicySlice';

const CookiePolicyView: React.FC<{ cookiePolicy?: CookiePolicyType }> = ({ cookiePolicy }) => {
    const { teaser, content } = cookiePolicy || { content: {} };

    const teaserComponent = teaser ? (
        <HeroImage title={teaser.title} imageUrl={teaser.imageUrl} shallow={true} inverted={!!teaser.isInverted} />
    ) : null;

    const { cookies, cookieTableTitles } = content;
    const cookieTable =
        cookies && cookies.length > 0 && cookieTableTitles ? (
            <CookieTable columnTitles={cookieTableTitles} cookies={cookies} />
        ) : null;

    const contentComponent = (
        <CookiePolicyShared
            title={content.title}
            cookieTable={cookieTable}
            textAboveTable={content.textAboveTable}
            textBelowTable={content.textBelowTable}
        />
    );

    return (
        <>
            {teaserComponent}
            <ContentSection pageWrapSize="medium">{contentComponent}</ContentSection>
        </>
    );
};

const CookiePolicyWithHandlers = withLoadingAndNoConnectionHandler(CookiePolicyView);

export const CookiePolicy: React.FC = () => {
    const { cmsContent: cookiePolicy, isLoading, loadingError } = useCmsContent(
        fetchCookiePolicy,
        (state: RootState) => {
            return state.content.cookiePolicy;
        },
    );

    return (
        <CookiePolicyWithHandlers
            hasError={!!loadingError}
            isLoading={isLoading}
            cookiePolicy={cookiePolicy}
            loadingPlaceholder={<StaticPageLoadingPlaceholder />}
        />
    );
};
