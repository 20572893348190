import {
    Contract,
    DashboardMarketingCard,
    DashboardMarketingCards,
    PersonalDetails,
    TotalEarlySettlement,
    TotalEarlySettlementOfflinePayment,
} from '@cp-es/common'; //ContactDetails,CustomerAddress,
import { Carousel } from '@cp-shared-6/frontend-ui';
import React, { useEffect, useState } from 'react';
import { FlickityOptions } from 'react-flickity-component';
import { ExtendedWarrantyMarketingCard } from '../extended-warranty-marketing-card/ExtendedWarrantyMarketingCard';
import { MarketingCardComponent } from '../marketing-card-component';

type DashboardMarketingCardUiProps = {
    mostRecentSeatContract?: Contract;
    personalDetails?: PersonalDetails;
    dashboardMarketingCard?: DashboardMarketingCards;
    totalEarlySettelment?: TotalEarlySettlement;
    offlinePayment?: TotalEarlySettlementOfflinePayment;
    showLongDriveMarketingCard?: boolean;
    setHasMarketingCard: (hasCard: boolean) => void;
};

export const DashboardMarketingCardUi: React.FC<DashboardMarketingCardUiProps> = React.memo(
    ({
        mostRecentSeatContract,
        personalDetails,
        dashboardMarketingCard,
        totalEarlySettelment,
        offlinePayment,
        showLongDriveMarketingCard,
        setHasMarketingCard,
    }) => {
        const [productRoutingLoaded, setProductRoutingLoaded] = useState<boolean>();
        const setProductRoutingOnLoad = (loadingSuccessful: boolean): void => {
            setProductRoutingLoaded(loadingSuccessful);
        };

        const getCarouselKey = (): string => (productRoutingLoaded ? mostRecentSeatContract?.contractNumber || '' : '');

        if (!dashboardMarketingCard) {
            return null;
        }

        const options: FlickityOptions = {
            autoPlay: 10000,
        };

        const cardsToRender = dashboardMarketingCard.filter((card: DashboardMarketingCard) => {
            if (card.flag === 'extendedWarranty') {
                return productRoutingLoaded || productRoutingLoaded === undefined;
            } else if (card.flag === 'longDrive' && !showLongDriveMarketingCard) {
                return false;
            } else {
                return true;
            }
        });

        useEffect(() => {
            setHasMarketingCard(cardsToRender.length > 0);
        }, [cardsToRender]);

        return (
            <>
                <Carousel options={options} key={getCarouselKey()}>
                    {cardsToRender.map((card: DashboardMarketingCard) => {
                        if (card.flag === 'extendedWarranty') {
                            return (
                                <ExtendedWarrantyMarketingCard
                                    personalDetails={personalDetails}
                                    dashboardMarketingCard={card}
                                    testId="extended-warranty"
                                    mostRecentSeatContract={mostRecentSeatContract}
                                    setProductRoutingLoaded={setProductRoutingOnLoad}
                                    totalEarlySettelment={totalEarlySettelment}
                                    offlinePayment={offlinePayment}
                                />
                            );
                        } else {
                            return (
                                <MarketingCardComponent
                                    testId="google-rating-card"
                                    content={card}
                                    buttonUrl={card.buttonUrl}
                                />
                            );
                        }
                    })}
                </Carousel>
            </>
        );
    },
);

DashboardMarketingCardUi.displayName = 'DashboardMarketingCardUi';
