import React from 'react';
import { useTranslation } from 'react-i18next';

import { changeLocale } from '@cp-es/common';
import { LanguageDescriptionList, LanguageSwitcher as LanguageSwitcherShared } from '@cp-shared-6/frontend-ui';

export const LanguageSwitcher: React.FC = () => {
    const { t, i18n } = useTranslation('navigation');

    const languageSwitchHandler = async (newLocale: string, currentLocale: string): Promise<void> => {
        if (newLocale === currentLocale) {
            return;
        }
        await i18n.changeLanguage(newLocale);
        if (newLocale === 'en' || newLocale === 'es') {
            changeLocale(newLocale);
        }
    };

    const supportedLanguages: LanguageDescriptionList = [
        {
            locale: 'es',
            dropdownLabel: t('languageSwitcher.spanish.longLabel'),
            activeLabel: t('languageSwitcher.spanish.shortLabel'),
        },
        {
            locale: 'en',
            dropdownLabel: t('languageSwitcher.english.longLabel'),
            activeLabel: t('languageSwitcher.english.shortLabel'),
        },
    ];

    const currentLanguage =
        supportedLanguages.find(language => language.locale === i18n.languages[0]) || supportedLanguages[0];

    return (
        <LanguageSwitcherShared
            currentLanguage={currentLanguage}
            selectableLanguages={supportedLanguages}
            onClick={languageSwitchHandler}
        />
    );
};
