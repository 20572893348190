import React from 'react';
import { useTranslation } from 'react-i18next';
import { Notification, NotificationStatus } from '@cp-shared-6/frontend-ui';
import { IbanValidationError } from './IbanValidationError';
import { WithDefaultBusinessMarketApiError } from '@cp-shared-6/common-utilities';

export const NotificationForLastValidation: React.FC<{
    lastValidationError: WithDefaultBusinessMarketApiError<IbanValidationError>;
    forOperatingLease?: boolean;
}> = ({ lastValidationError, forOperatingLease }) => {
    const { t } = useTranslation('financial-details');

    switch (lastValidationError) {
        case 'SAME_BANK_ACCOUNT': {
            return (
                <Notification
                    status={NotificationStatus.warning}
                    text={t('ibanSection.editView.ibanValidationView.backendValidation.sameBankAccount.text')}
                    testId={'lastValidationStatus'}
                />
            );
        }
        case 'NO_LOAN_PAYMENT_HOLDER': {
            return (
                <Notification
                    status={NotificationStatus.warning}
                    headline={t('ibanSection.editView.ibanValidationView.backendValidation.noLoanPaymentHolder.title')}
                    text={t('ibanSection.editView.ibanValidationView.backendValidation.noLoanPaymentHolder.text')}
                    testId={'lastValidationStatus'}
                />
            );
        }
        case 'WRONG_BANK_CODE': {
            return (
                <Notification
                    status={NotificationStatus.warning}
                    text={t('ibanSection.editView.ibanValidationView.backendValidation.wrongBankCode.text')}
                    testId={'lastValidationStatus'}
                />
            );
        }
        case 'MARKET_API_DEFAULT_BUSINESS_ERROR': {
            return (
                <Notification
                    status={NotificationStatus.warning}
                    headline={t('ibanSection.editView.ibanValidationView.backendValidation.businessError.title')}
                    testId={'lastValidationStatus'}
                >
                    <span
                        dangerouslySetInnerHTML={{
                            __html: forOperatingLease
                                ? t(
                                      'ibanSection.editView.ibanValidationView.backendValidation.businessError.operative-leasing.text',
                                  )
                                : t(
                                      'ibanSection.editView.ibanValidationView.backendValidation.businessError.finance.text',
                                  ),
                        }}
                    />
                </Notification>
            );
        }
        default: {
            return null;
        }
    }
};
