import { TFunction } from 'i18next';
import { Namespace, useTranslation, UseTranslationOptions, UseTranslationResponse } from 'react-i18next';
import {
    formatAsCurrency,
    formatAsDate,
    formatAsNumber,
    formatAsNumeral,
    formatAsEmission,
    numberFormat,
    numberFormatRoundedTwo,
    formatAsPercent,
    formatAsPercentWithoutRounding,
} from '@cp-es/common';

export enum TranslationFormat {
    DISTANCE,
    DAYS,
    NUMERAL,
    NUMBER,
    NUMBER_ROUNDED_TWO,
    CURRENCY,
    DATE,
    EMISSION,
    PERCENT,
    PERCENT_NOT_ROUNDED,
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type FormatterFunction = (value: any, format: TranslationFormat) => string;

type UseFormattingResponse = {
    f: FormatterFunction;
};
type UseTranslationWithFormattingResponse = UseTranslationResponse & UseFormattingResponse;

function createFormatter(t: TFunction, currentLanguage?: string): FormatterFunction {
    return (value, format) => {
        switch (format) {
            case TranslationFormat.DISTANCE:
                return typeof value === 'number' ? t('translation:metrics.distance', { value }) : '';
            case TranslationFormat.CURRENCY:
                return formatAsCurrency(value);
            case TranslationFormat.DATE:
                return formatAsDate(value);
            case TranslationFormat.DAYS:
                return typeof value === 'number' ? t('translation:metrics.day', { value }) : '';
            case TranslationFormat.EMISSION:
                return formatAsEmission(value);
            case TranslationFormat.NUMBER:
                return formatAsNumber(value, numberFormat);
            case TranslationFormat.NUMBER_ROUNDED_TWO:
                return formatAsNumber(value, numberFormatRoundedTwo);
            case TranslationFormat.NUMERAL:
                return formatAsNumeral(value);
            case TranslationFormat.PERCENT:
                return formatAsPercent(value);
            case TranslationFormat.PERCENT_NOT_ROUNDED:
                const deliminiter = currentLanguage === 'es' ? ',' : '.';
                return formatAsPercentWithoutRounding(value, deliminiter);
        }
    };
}

export function useTranslationWithFormatting(
    ns?: Namespace,
    options?: UseTranslationOptions,
): UseTranslationWithFormattingResponse {
    const useTranslationResponse = useTranslation(ns, options);
    const currentLanguage = useTranslationResponse.i18n.languages[0];
    const f = createFormatter(useTranslationResponse.t, currentLanguage);
    return Object.assign({ f }, useTranslationResponse);
}
