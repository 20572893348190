import React from 'react';
import { Layout, Card } from '@vwfs-bronson/bronson-react';
import { LicensePlate } from '../../license-plate';
import { TranslationFormat, useTranslationWithFormatting } from '../../../localization/useTranslationWithFormatting';
import { EarlySettlementHeader, Contract } from '@cp-es/common';
import { getProductCategoryTranslationLabel } from 'components/contracts/ProductCategoryTranslationLabel';
import { DefinitionListHorizontal, DefinitionListItem } from '@cp-shared-6/frontend-ui';

export type ContractTermsHeaderProps = {
    contractDetails: Contract;
    earlySettlementHeader: EarlySettlementHeader;
};
export const ContractTermsHeader: React.FC<ContractTermsHeaderProps> = ({ contractDetails, earlySettlementHeader }) => {
    const { t, f } = useTranslationWithFormatting('total-early-settlement');

    const contractInformationList: DefinitionListItem[] = [
        {
            label: t('contract-information.next-due-amount'),
            value: f(contractDetails.nextInstalmentAmount, TranslationFormat.CURRENCY),
        },
        {
            label: t('contract-information.accumulated-points'),
            value: earlySettlementHeader.totalPoints,
        },
        {
            label: t('contract-information.engaged-months'),
            value: f(earlySettlementHeader.monthsOfEngagement, TranslationFormat.NUMBER),
        },
        {
            label: t('contract-information.remaining-installments'),
            value: f(earlySettlementHeader.pendingInstallments, TranslationFormat.NUMBER),
        },
        {
            label: t('contract-information.pending-capital'),
            value: f(contractDetails.totalAmount, TranslationFormat.CURRENCY),
        },
        {
            label: t('contract-information.end-date'),
            value: f(contractDetails.contractEndDate, TranslationFormat.DATE),
        },
    ].filter(item => !!item.value);

    return (
        <Card element="article">
            <Layout equalHeight divider>
                <Layout.Item default="1/2" s="1/1">
                    <div className="u-text-left@m-up  u-mr-none cp-contract-identifier-slot">
                        <LicensePlate
                            size={'small'}
                            licensePlateNumber={contractDetails.licensePlateNumber}
                            className="u-mb"
                        />
                        <p className="u-zeta">
                            <strong>{contractDetails.carInformation}</strong>
                        </p>
                        <p>{`${t(getProductCategoryTranslationLabel(contractDetails.contractType))} ${
                            contractDetails.contractNumber
                        }`}</p>
                    </div>
                </Layout.Item>
                <Layout.Item default="1/2" s="1/1">
                    <div>
                        <h6>{t('contract-information.headline')}</h6>
                        <DefinitionListHorizontal list={contractInformationList} />
                    </div>
                </Layout.Item>
            </Layout>
        </Card>
    );
};
