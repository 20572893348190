import { useContract } from 'components/contracts/useContract';
import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import React from 'react';
import { RequestSubrogationUi } from './ui/RequestSubrogationUi';

const RequestSubrogationWithWrappers = withLoadingAndNoConnectionHandler(RequestSubrogationUi);

export const RequestSubrogation: React.FC<{ encryptedContractId: string }> = ({ encryptedContractId }) => {
    const {
        data: contract,
        isLoading: isLoadingContract,
        loadingError: loadingErrorContract,
    } = useContract(encryptedContractId, { encryptedContractId: true });

    return (
        <RequestSubrogationWithWrappers
            isLoading={isLoadingContract}
            hasError={!!loadingErrorContract}
            contract={contract}
        />
    );
};
