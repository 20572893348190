import { Contract } from '@cp-es/common';

export type AllContractType = 'ALL';

export type AvailableContract = {
    key: string | AllContractType;
    contract?: Contract;
};

export const getListOfAllAvailableContracts = (contracts: Contract[]): AvailableContract[] => {
    const enrichedContract: AvailableContract[] = [{ key: 'ALL' }];
    contracts.forEach(contract => {
        enrichedContract.push({ key: contract.contractNumber, contract: contract });
    });
    return enrichedContract;
};
