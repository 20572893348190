/*
 * The idea is to have later on endpoint functions that can be used by backend and frontend.
 * In the frontend, one could use the real values for the path/query params and in the backend placeholders that NextJS
 * would understand.
 */

export function getContractsEndpoint(): string {
    return '/contracts';
}

export function getContractPartiesEndpoint(contractId: string): string {
    return `/contracts/${contractId}/contract-parties`;
}

export function getVehicleDetailsEndpoint(contractId: string): string {
    return `/contracts/${contractId}/vehicle-details`;
}

export function getAdditionalProductsEndpoint(contractId: string): string {
    return `/contracts/${contractId}/additional-products`;
}

export function getAmortizationDetailsEndpoint(contractId: string): string {
    return `/contracts/${contractId}/amortization-details`;
}

export function getAmortizationDetailsPdfDownloadEndpoint(contractId: string): string {
    return `/contracts/${contractId}/amortization-details/download`;
}
