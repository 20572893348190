import { ContentEndpoint } from '@cp-es/common';
import {
    createCmsContentSlice as createCmsContentSliceShared,
    AbstractContentState,
    CmsContentSlice,
} from '@cp-shared-6/frontend-integration';
import { CpContentApi } from 'cp-xhr';
import { currentBrand } from 'config';

export function createCmsContentSlice<TDto>({
    contentName,
    contentEndpoint,
    initialContent,
}: {
    contentName: string;
    contentEndpoint: ContentEndpoint;
    initialContent?: TDto;
}): CmsContentSlice<AbstractContentState<TDto>> {
    return createCmsContentSliceShared<TDto>({
        contentCallback(locale: string) {
            return CpContentApi.get<TDto>(contentEndpoint(locale, currentBrand)).then(response => response.data);
        },
        contentName,
        initialContent,
    });
}
