import React from 'react';
import { useTranslation } from 'react-i18next';

import { Contract } from '@cp-es/common';
import { Combobox, Layout } from '@vwfs-bronson/bronson-react';
import { useTranslationWithFormatting } from '../../../../localization/useTranslationWithFormatting';
import { getProductCategoryTranslationLabel } from '../../../contracts/ProductCategoryTranslationLabel';
import { LicensePlate } from '../../../license-plate';
import { getListOfAllAvailableContracts } from '../../helper/AvailableContract';
import { ComboboxOptions, ComboboxState } from '../../../../types/bronson-react';

export type ContractFilterProps = {
    onChange: (selectedContract: string) => void;
    contracts: Contract[];
};

export const ContractFilter: React.FC<ContractFilterProps> = ({ onChange, contracts }) => {
    const { t: postboxTranslation } = useTranslation('postbox');
    const { t: contractsTranslation } = useTranslationWithFormatting('contracts');

    if (!contracts) {
        return null;
    }

    const selectValueChange = (selected: ComboboxOptions): void => {
        onChange(selected[0].key);
    };

    const availableOptions = getListOfAllAvailableContracts(contracts);
    const allTranslation = postboxTranslation('filters.contract.all');

    const availableOptionElements = availableOptions.map(availableContract => {
        const key = availableContract.key;
        const contract = availableContract.contract;

        if (contract) {
            return (
                <Combobox.Item
                    testId={`ContractFilterItem-${contract.contractNumber}`}
                    optionValue={contract.contractNumber}
                    optionKey={contract.contractNumber}
                    key={contract.contractNumber}
                >
                    <Layout flush>
                        <Layout.Item default="1/1">
                            <LicensePlate
                                licensePlateNumber={contract.licensePlateNumber}
                                size={'xsmall'}
                                numberOfCars={contract.numberOfCars}
                                className={'u-text-base'}
                            />
                        </Layout.Item>
                        <Layout.Item default="1/1">
                            <strong>{contract.carInformation}</strong>
                        </Layout.Item>
                        <Layout.Item default="1/1">
                            {contractsTranslation(getProductCategoryTranslationLabel(contract.contractType))}{' '}
                            {contract.contractNumber}
                        </Layout.Item>
                    </Layout>
                </Combobox.Item>
            );
        } else if (key === 'ALL') {
            return (
                <Combobox.Item
                    testId={`ContractFilterItem-ALL`}
                    key="ALL"
                    optionValue={allTranslation}
                    optionKey={'ALL'}
                >
                    {allTranslation}
                </Combobox.Item>
            );
        } else {
            return null;
        }
    });

    const defaultItem: ComboboxState = {
        value: [
            {
                key: availableOptions[0].key,
                value: allTranslation,
            },
        ],
    };

    return (
        <>
            <label>{postboxTranslation('filters.contract.label')}</label>
            <Combobox
                testId={'ContractFilter'}
                onChange={selectValueChange}
                icon="semantic-expand"
                defaultState={defaultItem}
            >
                {availableOptionElements}
            </Combobox>
        </>
    );
};
