import { IconListItem } from '@cp-shared-6/frontend-ui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { SubrogationInfo } from '../subrogation-info/SubrogationInfo';

export const CorporateNameChangingInfo: React.FC = () => {
    const { t } = useTranslation('request-subrogation');

    const documentsList: IconListItem[] = [
        {
            label: t('documents.corporate.doc1'),
            icon: 'checkmark',
        },
        {
            label: t('documents.corporate.doc2'),
            icon: 'checkmark',
        },
        {
            label: t('documents.corporate.doc3'),
            icon: 'checkmark',
        },
        {
            label: t('documents.corporate.doc4'),
            icon: 'checkmark',
        },
    ];

    return <SubrogationInfo documentsList={documentsList} type={t('subrogation-info.type.value.corporate')} />;
};
