import * as Yup from 'yup';
import { defaultContactValidatorErrors } from '../messages';
import { ContactChange } from '../../apis';
import { ContactValidatorErrors } from '../types';

export const contactValidator = (
    error: ContactValidatorErrors = defaultContactValidatorErrors,
): Yup.SchemaOf<ContactChange> =>
    Yup.object()
        .shape({
            email: Yup.string()
                .trim()
                .required(error.email.required)
                .email(error.email.email),
            primaryPhoneNumber: Yup.string()
                .trim()
                .required(error.primaryPhoneNumber.required)
                .min(7, error.primaryPhoneNumber.min)
                .max(9, error.primaryPhoneNumber.max)
                .matches(/^[6789][0-9]*$/, error.primaryPhoneNumber.matches.pattern),
            secondaryPhoneNumber: Yup.string()
                .trim()
                .transform(value => (value === '' ? undefined : value))
                .notRequired()
                .min(7, error.secondaryPhoneNumber.min)
                .max(9, error.secondaryPhoneNumber.max)
                .matches(/^[6789][0-9]*$/, error.secondaryPhoneNumber.matches.pattern),
            invoiceEmail: Yup.string().email(error.invoiceEmail.email),
        })
        .defined();
