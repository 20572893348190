import * as Yup from 'yup';
import { AddressValidationErrors, addressValidator, ChangeAddressData } from '@cp-es/common';
import { useTranslation } from 'react-i18next';

export const useAddressValidationSchema = (): Yup.SchemaOf<ChangeAddressData> => {
    const { t } = useTranslation('my-profile-address-details');
    const required = t('edit-view.validations.required');
    const defaultMaxMessage = (count: number): string => t('edit-view.validations.invalid-count', { count });
    const matches = t('edit-view.validations.invalid-pattern');
    const messages: AddressValidationErrors = {
        street: {
            name: {
                required,
                max: defaultMaxMessage(30),
                matches,
            },
            number: {
                required: t('edit-view.street.number.validations.required'),
                max: defaultMaxMessage(4),
                matches,
            },
        },
        complementaryInformation: {
            block: {
                max: defaultMaxMessage(4),
                matches,
            },
            stairs: {
                max: defaultMaxMessage(4),
                matches,
            },
            floor: {
                max: defaultMaxMessage(4),
                matches,
            },
            door: {
                max: defaultMaxMessage(4),
                matches,
            },
            details: {
                max: defaultMaxMessage(40),
                matches,
            },
        },
        city: {
            locality: {
                required,
                max: defaultMaxMessage(26),
                matches,
            },
            zip: {
                required,
                max: defaultMaxMessage(5),
                matches: t('edit-view.city.zip.validations.invalid-pattern'),
            },
        },
    };
    return addressValidator(messages);
};
