import { Button, ButtonContainer, Fieldset, Layout } from '@vwfs-bronson/bronson-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ValidatedCheckbox, withAutoScroll } from '@cp-shared-6/frontend-ui';
import { useField } from 'formik';

export type FormViewProps = {
    name: string;
    toNextView: (value: boolean) => void;
    cancelEditing: () => void;
};

export const FormView: React.FC<FormViewProps> = withAutoScroll(({ name, toNextView, cancelEditing }) => {
    const { t } = useTranslation('my-profile-invoice-consent');

    const [field] = useField({ name, type: 'checkbox' });

    return (
        <>
            <p>{t('edit-view.infobox')}</p>
            <Fieldset>
                <Fieldset.Row>
                    <Layout>
                        <Layout.Item default="1/2" s="1/1">
                            <ValidatedCheckbox
                                label={t('consult-view.invoice-consent-setting')}
                                name={name}
                                testId={name}
                            />
                        </Layout.Item>
                    </Layout>
                </Fieldset.Row>
            </Fieldset>
            <Fieldset>
                <Fieldset.Row>
                    <ButtonContainer nav>
                        <Button secondary onClick={cancelEditing} testId="cancelButton" type="btn">
                            {t('translation:editableSectionNav.cancel')}
                        </Button>
                        <Button onClick={() => toNextView(field.value)} testId="toNextButton" type="btn">
                            {t('translation:editableSectionNav.continue')}
                        </Button>
                    </ButtonContainer>
                </Fieldset.Row>
            </Fieldset>
        </>
    );
}, 'ConsentSectionFormView');
