import { ContentSection } from '@vwfs-bronson/bronson-react';
import { RequestVehiclePurchase } from 'components/request-vehicle-purchase/RequestVehiclePurchase';
import React from 'react';

export const RequestVehiclePurchasePage: React.FC = () => {
    return (
        <ContentSection pageWrapSize="medium">
            <RequestVehiclePurchase />
        </ContentSection>
    );
};
