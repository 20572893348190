import {
    Contract,
    ContractInfo,
    ContractType,
    getMyRequestEndpoint,
    MAX_REQUEST,
    MAX_SHORT_DESCRIPTION,
    MyRequest,
    OpenRequestGeneralCategoriesSalesforce,
} from '@cp-es/common';
import { trimAllValues } from '@cp-shared-6/common-utilities';
import {
    Notification,
    NotificationStatus,
    preventSubmit,
    Spinner,
    useAnalyticsActionTracker,
    ValidatedInput,
    ValidatedTextarea,
} from '@cp-shared-6/frontend-ui';
import { Button, ButtonContainer, Fieldset, Form, Modal } from '@vwfs-bronson/bronson-react';
import { Formik } from 'formik';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { CpDataApi } from '../../../cp-xhr';
import { dashboardPagePath } from '../../navigation/paths';
import { ContractDropdown } from './form-input/contract-dropdown';
import { FinancingDropdown } from './form-input/financing-dropdown/FinancingDropdown';
import { GeneralRequestDropdown } from './form-input/general-request-dropdown/GeneralRequestDropdown';
import { OperativeLeasingDropdown } from './form-input/operative-leasing-dropdown/OperativeLeasingDropdown';
import { MyRequestFormFields } from './MyRequestFormFields';
import { getMyRequestValidationSchema } from './Validations';

type MyRequestUiProps = { contracts?: Contract[]; email?: string };

export const MyRequestUi: React.FC<MyRequestUiProps> = ({ contracts, email }) => {
    const { t } = useTranslation('my-request');
    const history = useHistory();

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showSuccessDialogue, setShowSuccessDialogue] = useState(false);
    const [showErrorDialogue, setShowErrorDialogue] = useState(false);
    const [currentSelectedContract, setCurrentlySelectedContract] = useState<Contract | undefined>(undefined);

    const { onAction: onError } = useAnalyticsActionTracker('onRequestsError');
    const { onAction: onSuccess } = useAnalyticsActionTracker('onRequestsSuccess');

    if (!contracts || contracts.length === 0) {
        return (
            <Notification
                status={NotificationStatus.error}
                headline={t('translation:noConnection.title')}
                text={t('translation:noConnection.text')}
                testId={'NoContractsErrorNotification'}
            />
        );
    }

    const initialValues: MyRequestFormFields = {
        email: email || '',
        category: '',
        subject: '',
        request: '',
    };

    const getContract = (contracts: Contract[], contractId: string): Contract | undefined =>
        contracts.find(contract => contract.contractNumber === contractId);

    const getContractTypeFromGeneralCategory = (category: string): ContractType =>
        category === OpenRequestGeneralCategoriesSalesforce.OTHER_ISSUES_OPERATIVE_LEASING
            ? ContractType.OPERATING_LEASE
            : ContractType.FINANCING;

    const getContractInfo = (category: string, contract?: Contract): ContractInfo | undefined => {
        if (contract) {
            const { contractNumber, licensePlateNumber, contractType } = contract;
            return { licensePlate: licensePlateNumber, contractId: contractNumber, contractType };
        } else {
            return { contractType: getContractTypeFromGeneralCategory(category) };
        }
    };

    const handleSubmit = (input: MyRequestFormFields): void => {
        setIsSubmitting(true);

        const requestBody: MyRequest = {
            email: input.email,
            category: input.category,
            shortDescription: input.subject,
            request: input.request,
            contractInfo: getContractInfo(input.category, currentSelectedContract),
        };

        const trackingCategory = `Category: ${input.category.toLowerCase()}`;

        CpDataApi.post(getMyRequestEndpoint(), trimAllValues(requestBody))
            .then(() => {
                setShowSuccessDialogue(true);
                onSuccess(trackingCategory);
            })
            .catch(() => {
                setShowErrorDialogue(true);
                onError(trackingCategory);
            })
            .finally(() => setIsSubmitting(false));
    };

    const onSelectedContractChange = (contractId: string): void => {
        const currentlySelectedContract = getContract(contracts, contractId);
        setCurrentlySelectedContract(currentlySelectedContract);
    };

    const redirectToDashboard = (): void => {
        history.push(dashboardPagePath());
    };

    const closeModal = (): void => {
        setShowSuccessDialogue(false);
        setShowErrorDialogue(false);
    };

    return (
        <>
            <Notification status={NotificationStatus.info} text={t('info-text')} />
            <hr />
            {isSubmitting && <Spinner fullPage={true} />}
            <Formik
                initialValues={initialValues}
                validationSchema={getMyRequestValidationSchema(t)}
                onSubmit={handleSubmit}
            >
                {(formik): JSX.Element => {
                    const displayFinancingCategoryDropdown = currentSelectedContract?.isFinancingContract;
                    const displayOperativeLeasingCategoryDropdown = currentSelectedContract?.isOperatingLeaseContract;
                    const displayGeneralRequestCategoryDropdown =
                        !displayFinancingCategoryDropdown && !displayOperativeLeasingCategoryDropdown;

                    return (
                        <>
                            <Form onSubmit={preventSubmit()}>
                                <Fieldset>
                                    <Fieldset.Row>
                                        <ValidatedInput
                                            name={'email'}
                                            label={t('form.email.label')}
                                            testId={'email'}
                                            inputMode={'email'}
                                            isMandatory
                                        />
                                    </Fieldset.Row>
                                    <Fieldset.Row>
                                        <ContractDropdown
                                            label={t('form.contract-selector-label')}
                                            contracts={contracts}
                                            name={'contractId'}
                                            onChange={onSelectedContractChange}
                                        />
                                    </Fieldset.Row>
                                    <Fieldset.Row>
                                        {displayGeneralRequestCategoryDropdown && (
                                            <GeneralRequestDropdown name="category" />
                                        )}
                                        {displayFinancingCategoryDropdown && <FinancingDropdown name="category" />}
                                        {displayOperativeLeasingCategoryDropdown && (
                                            <OperativeLeasingDropdown name="category" />
                                        )}
                                    </Fieldset.Row>
                                    <Fieldset.Row>
                                        <ValidatedInput
                                            label={t('form.subject.label')}
                                            name="subject"
                                            testId="subject"
                                            type="text"
                                            inputMode="text"
                                            maxLength={MAX_SHORT_DESCRIPTION}
                                            isMandatory
                                        />
                                    </Fieldset.Row>
                                    <Fieldset.Row>
                                        <ValidatedTextarea
                                            label={t('form.request.label')}
                                            name="request"
                                            id="request"
                                            testId="request"
                                            inputMode="text"
                                            maxLength={MAX_REQUEST}
                                            isMandatory
                                        />
                                    </Fieldset.Row>
                                    <Fieldset.Row>
                                        <ButtonContainer center>
                                            <Button
                                                secondary
                                                onClick={redirectToDashboard}
                                                testId="dashboardButton"
                                                type="btn"
                                            >
                                                {t('translation:editableSectionNav.back')}
                                            </Button>
                                            <Button
                                                onClick={async (): Promise<void> => {
                                                    await formik.validateForm();
                                                    await formik.handleSubmit();
                                                }}
                                                testId="submitButton"
                                                type="btn"
                                            >
                                                {t('translation:editableSectionNav.confirm')}
                                            </Button>
                                        </ButtonContainer>
                                    </Fieldset.Row>
                                </Fieldset>
                            </Form>
                            <Modal
                                title={t('form.modal.success.title')}
                                shown={showSuccessDialogue}
                                buttonConfirmText={t('form.dashboard-button')}
                                onConfirm={redirectToDashboard}
                                onClickOutside={closeModal}
                                onClose={closeModal}
                                testId="successModal"
                                status="success"
                            >
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: t('form.modal.success.text'),
                                    }}
                                />
                            </Modal>
                            <Modal
                                title={t('form.modal.error.title')}
                                shown={showErrorDialogue}
                                buttonCancelText={t('translation:editableSectionNav.back')}
                                onCancel={closeModal}
                                onClickOutside={closeModal}
                                onClose={closeModal}
                                testId="errorModal"
                                status="error"
                            >
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: t('form.modal.error.text'),
                                    }}
                                />
                            </Modal>
                        </>
                    );
                }}
            </Formik>
        </>
    );
};
