import { TFunction } from 'i18next';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import {
    cookiePolicyPath,
    dashboardPagePath,
    faqPagePath,
    featureAppLicensesPagePath,
    identityBrokerErrorPagePath,
    landingPagePath,
    legalNoticePath,
    privacyPolicyPath,
    myProfilePagePath,
    myRequestPagePath,
    notAuthorizedPagePath,
    notFoundPagePath,
    postboxPagePath,
    registrationPagePath,
    logoutPath,
} from 'components/navigation/paths';

import {
    NavigationBar as NavigationBarShared,
    SiteNavItemPropsList,
    SiteNavProps,
    useAnalyticsActionTracker,
    useAuthentication,
} from '@cp-shared-6/frontend-ui';

import { LanguageSwitcher } from './language-switcher/LanguageSwitcher';

import { useLogin } from 'auth/useLogin';

const pagesWithoutNavbar = [registrationPagePath(), featureAppLicensesPagePath()];

const pagesWithoutStaticHeader = [
    landingPagePath(),
    faqPagePath(),
    cookiePolicyPath(),
    legalNoticePath(),
    notAuthorizedPagePath(),
    notFoundPagePath(),
    privacyPolicyPath(),
    registrationPagePath(),
    logoutPath(),
];

function publicNavigationLinks(t: TFunction, login: () => Promise<void>): SiteNavItemPropsList {
    return [
        {
            url: landingPagePath(),
            label: t('navigation.home'),
        },
        {
            url: faqPagePath(),
            label: t('navigation.faq'),
        },
        {
            label: t('navigation.login'),
            onClick: login,
        },
    ];
}

function privateNavigationLinks(t: TFunction, logout: () => Promise<void>): SiteNavItemPropsList {
    return [
        {
            url: dashboardPagePath(),
            label: t('navigation.dashboard'),
        },
        {
            url: myProfilePagePath(),
            label: t('navigation.profile'),
        },
        {
            url: postboxPagePath(),
            label: t('navigation.postbox'),
        },
        {
            url: myRequestPagePath(),
            label: t('navigation.request'),
        },
        {
            url: faqPagePath(),
            label: t('navigation.faq'),
        },
        {
            label: t('navigation.logout'),
            onClick: logout,
        },
    ];
}

export const NavigationBar: React.FC = () => {
    const { t } = useTranslation('navigation');
    const history = useHistory();
    const location = useLocation();
    const { isAuthenticated, logout } = useAuthentication();
    const loginFn = useLogin();
    const { onAction: onLogoutAction } = useAnalyticsActionTracker('logout');
    const currentPageName = location.pathname.split('/')[1];

    const logoutFn = useCallback(() => {
        onLogoutAction(currentPageName);
        return logout({ redirectUri: window.location.origin + logoutPath() + '?logout-type=manual' });
    }, [logout, onLogoutAction, currentPageName]);
    const currentPathName = location.pathname;

    const navigationItems = useMemo(() => {
        if (currentPathName === identityBrokerErrorPagePath()) {
            return [];
        }
        return (isAuthenticated ? privateNavigationLinks(t, logoutFn) : publicNavigationLinks(t, loginFn)).map(
            navItem => ({
                ...{ isActive: currentPathName === navItem.url },
                ...navItem,
            }),
        );
    }, [isAuthenticated, logoutFn, loginFn, t, currentPathName]);

    const hidden = pagesWithoutNavbar.includes(currentPathName);
    const withStaticHeader = !pagesWithoutStaticHeader.includes(currentPathName);

    const navigation: SiteNavProps = {
        navigationItems,
    };

    const onLogoClickCallback = useCallback(() => {
        history.push(isAuthenticated ? dashboardPagePath() : landingPagePath());
    }, [isAuthenticated, history]);

    return (
        <NavigationBarShared
            languageSwitcher={<LanguageSwitcher />}
            logoAltText={t(`navigation.${isAuthenticated ? 'dashboard' : 'home'}`)}
            onLogoClick={onLogoClickCallback}
            navigation={navigation}
            withStaticHeader={withStaticHeader}
            hidden={hidden}
        />
    );
};
