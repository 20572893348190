import { ProductStatus as ProductStatusType } from '@cp-es/common';
import React from 'react';
import { useTranslation } from 'react-i18next';

export function isActiveProduct(status: ProductStatusType): boolean {
    return status === 'VALID' || status === 'SEGURO_EXFINANCIADO' || status === 'PENDING_ON_ACTIVATION';
}

export const ProductStatus: React.FC<{ status: ProductStatusType }> = ({ status }) => {
    const { t } = useTranslation('contracts');
    return (
        <p className={isActiveProduct(status) ? 'u-text-success' : 'u-text-alert'}>
            {t(`additional-products.product-status.${status}`)}
        </p>
    );
};
