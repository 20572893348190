import { IdentityBrokerErrorType } from '../types';

export const errorPrefixes: { [key in IdentityBrokerErrorType]: string } = {
    CONSENT_DENIED_ERR: 'consent-denied-err',
    GENERAL_ERR: 'general-err',
    DIFFERENT_USER_ERR: 'different-user-err',
    INVALID_SESSION_ERR: 'invalid-session-err',
    UNEXPECTED_ERR: 'unexpected-err',
    ALREADY_LOGIN_ERR: 'general-err',
};

export const getGenericErrorTranslationPrefix = (errorType: IdentityBrokerErrorType): string => {
    return errorPrefixes[errorType] || errorPrefixes['GENERAL_ERR'];
};
