// Do not import with ".." only, as it leads to cyclic dependencies!
import React from 'react';

import { getDisplayName } from '@cp-shared-6/frontend-ui';
import { DifferentBrandNotification } from '../notifications/different-brand/DifferentBrandNotification';
import { FetchContractError } from '@cp-es/common';

export type withDifferentBrandHandlerProps = {
    errorCode?: FetchContractError;
};

export const withDifferentBrandHandler = <TProps extends object>(
    WrappedComponent: React.ComponentType<TProps>,
): React.FC<TProps & withDifferentBrandHandlerProps> => {
    const Wrapper: React.FC<TProps & withDifferentBrandHandlerProps> = ({
        errorCode,
        ...props
    }: withDifferentBrandHandlerProps) => {
        const isDifferentBrand = errorCode === 'NO_CONTRACT_FOUND_FOR_GIVEN_BRAND';
        return isDifferentBrand ? <DifferentBrandNotification /> : <WrappedComponent {...(props as TProps)} />;
    };

    Wrapper.displayName = `withDifferentBrandHandler(${getDisplayName(WrappedComponent)})`;

    return Wrapper;
};
