import { Contract } from '@cp-es/common';
import { Card, Heading } from '@vwfs-bronson/bronson-react';
import { ContractHeader } from 'components/contracts/ContractHeader';
import {
    corporateNameChangingSubragationPagePath,
    legalPersonSubragationPagePath,
    privatePersonSubragationPagePath,
} from 'components/navigation/paths';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { ChooseSubrogationType } from '../choose-subrogation-type/ChooseSubrogationType';
import { CorporateNameChangingInfo } from '../corporate-name-changing/CorporateNameChangingInfo';
import { LegalPersonInfo } from '../legal-person/LegalPersonInfo';
import { PrivetePersonInfo } from '../private-person-info-page/PrivetePersonInfo';

type RequestSubrogationUiProps = {
    contract?: Contract;
};

export const RequestSubrogationUi: React.FC<RequestSubrogationUiProps> = ({ contract }) => {
    const { t } = useTranslation('request-subrogation');
    const { path } = useRouteMatch();

    if (!contract) return null;

    return (
        <>
            <Heading level={'1'}>{t('heading')}</Heading>
            <Card className={'u-mb-large'} element="article">
                <ContractHeader contract={contract} />
            </Card>
            <Switch>
                <Route exact path={privatePersonSubragationPagePath()}>
                    <PrivetePersonInfo />
                </Route>
                <Route exact path={legalPersonSubragationPagePath()}>
                    <LegalPersonInfo />
                </Route>
                <Route exact path={corporateNameChangingSubragationPagePath()}>
                    <CorporateNameChangingInfo />
                </Route>
                <Route path={path}>
                    <ChooseSubrogationType contract={contract} />
                </Route>
            </Switch>
        </>
    );
};
