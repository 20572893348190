import { ContractType } from '@cp-es/common';

export const getProductCategoryTranslationLabel = (contractType: ContractType): string => {
    switch (contractType) {
        case ContractType.OPERATING_LEASE:
            return 'translation:product-category.operating-lease';
        case ContractType.FINANCING:
            return 'translation:product-category.financing';
        case ContractType.FINANCE_LEASE:
            return 'translation:product-category.finance-leasing';
    }
};
