import { useTranslation } from 'react-i18next';
import React from 'react';
import { Button, Card, Layout } from '@vwfs-bronson/bronson-react';

export type TotalEarlySettlementSimulationEntryPointProps = { setCurrentView: Function };

export const TotalEarlySettlementSimulationEntryPoint: React.FC<TotalEarlySettlementSimulationEntryPointProps> = ({
    setCurrentView,
}) => {
    const { t } = useTranslation('total-early-settlement');

    const proceedToSimulation = (): void => setCurrentView('TES_FUTURE_QUOTE_SIMULATION');
    return (
        <Card className="u-mt" element="article">
            <Layout>
                <Layout.Item className={'u-text-center'} default="1/1">
                    <h3>{t('future-quote-simulation.entry-point.headline')}</h3>
                    <p>{t('future-quote-simulation.entry-point.description')}</p>
                </Layout.Item>
                <Layout.Item className={'u-text-center'} default="1/1">
                    <Button secondary onClick={proceedToSimulation} testId="gotoSimulationButton">
                        {t('future-quote-simulation.entry-point.button')}
                    </Button>
                </Layout.Item>
            </Layout>
        </Card>
    );
};
