import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { ContractRecalculationFormValues, recalculationType } from './ContractRecalculationFormValues';

export const getContractRecalculationValidationSchema = (t: TFunction): Yup.SchemaOf<ContractRecalculationFormValues> =>
    Yup.object().shape(
        {
            recalculationType: Yup.string()
                .oneOf([recalculationType.extension, recalculationType.reduction])
                .required(t('form.validation')),
            newPeriod: Yup.boolean().when('newMileage', {
                is: false,
                then: Yup.boolean().oneOf([true], t('form.validation')),
            }),
            newMileage: Yup.boolean().when('newPeriod', {
                is: false,
                then: Yup.boolean().oneOf([true], t('form.validation')),
            }),
            durationValue: Yup.number(),
            distanceValue: Yup.number(),
            comment: Yup.string().trim(),
        },
        [['newPeriod', 'newMileage']],
    );
