import React from 'react';
import { Layout, ButtonContainer, Button, Link } from '@vwfs-bronson/bronson-react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { dashboardPagePath } from 'components/navigation/paths';

type OfflinePaymentButtonBarProps = {
    back: () => void;
    success?: boolean;
    sendEmail?: () => void;
};

export const OfflinePaymentButtonBar: React.FC<OfflinePaymentButtonBarProps> = ({ back, sendEmail, success }) => {
    const { t } = useTranslation('total-early-settlement');
    const history = useHistory();
    const backToDashboard = (): void => {
        history.push(dashboardPagePath());
    };
    return (
        <Layout className="u-mt">
            <Layout.Item className={'u-text-center'} default="1/1" s="1/1">
                <ButtonContainer center>
                    <Button secondary onClick={back} testId="offlinePaymentBackButton">
                        {t('offline-payment.button.back')}
                    </Button>
                    <Button onClick={backToDashboard} testId="dashboardButton">
                        {t('offline-payment.button.dashboard')}
                    </Button>
                </ButtonContainer>
            </Layout.Item>
            <Layout.Item className={'u-text-center'} default="1/1" s="1/1">
                {!success && (
                    <Link onClick={sendEmail} testId="offlinePaymentSendEmailButton" normalLink icon="semantic-mail">
                        {t('offline-payment.link')}
                    </Link>
                )}
            </Layout.Item>
        </Layout>
    );
};
