import { ContractType, MyRequest } from '@cp-es/common';
import { ContractsWithoutLinks } from '../contracts/examples/ExampleData';

export const exampleOperativeLeasingCategory = 'DOCUMENTATION TO CIRCULATE IN A FOREIGN COUNTRY';
export const requestBody: MyRequest = {
    email: 'example@email.com',
    category: exampleOperativeLeasingCategory,
    shortDescription: 'This is a subject',
    request: 'This is a message',
    contractInfo: {
        licensePlate: ContractsWithoutLinks[1].licensePlateNumber,
        contractId: ContractsWithoutLinks[1].contractNumber,
        contractType: ContractType.OPERATING_LEASE,
    },
};
