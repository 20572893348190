import React from 'react';

type DigitalRenewalContentTransformComponentProps = {
    content: string;
};

const DigitalRenewalContentTransformComponent: React.FC<DigitalRenewalContentTransformComponentProps> = ({
    content,
}) => {
    return <span dangerouslySetInnerHTML={{ __html: content }} />;
};

export default DigitalRenewalContentTransformComponent;
