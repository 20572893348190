import React from 'react';
import { useTranslation } from 'react-i18next';
import { Combobox, FormField, Layout } from '@vwfs-bronson/bronson-react';
import { Contract } from '@cp-es/common';
import { LicensePlate } from '../../../../license-plate';
import { getProductCategoryTranslationLabel } from '../../../../contracts/ProductCategoryTranslationLabel';
import { ComboboxOptions, ComboboxState } from '../../../../../types/bronson-react';

export type ContractDropdownProps = {
    label: string;
    contracts: Contract[];
    name: string;
    onChange: (optionKey: string) => void;
};

type OptionItem = {
    content: JSX.Element;
    optionKey: string;
    optionValue: string;
    testId: string;
};

export const ContractDropdown: React.FC<ContractDropdownProps> = ({ label, contracts, name, onChange }) => {
    const { t } = useTranslation('my-request');

    const dropdownOptions: OptionItem[] = contracts.map(contract => ({
        content: (
            <Layout flush>
                {contract.licensePlateNumber && (
                    <Layout.Item default="1/1">
                        <LicensePlate size={'xsmall'} licensePlateNumber={contract.licensePlateNumber} />
                    </Layout.Item>
                )}
                <Layout.Item default="1/1">
                    <strong>{contract.contractNumber}</strong>
                </Layout.Item>
                <Layout.Item default="1/1">
                    <strong>{t(getProductCategoryTranslationLabel(contract.contractType))}</strong>
                </Layout.Item>
                <Layout.Item default="1/1">{contract.carInformation}</Layout.Item>
            </Layout>
        ),
        optionKey: contract.contractNumber,
        optionValue: contract.contractNumber,
        testId: contract.contractNumber,
    }));

    const generalRequest = t('form.general-request');
    const preselectedOptionKey = '0';
    const generalRequestItem: OptionItem = {
        content: <strong>{generalRequest}</strong>,
        optionKey: preselectedOptionKey,
        optionValue: generalRequest,
        testId: 'generalRequestTestId',
    };

    dropdownOptions.splice(0, 0, generalRequestItem);

    const onChangeInternal = (selectedOption: ComboboxOptions): void => {
        const key = selectedOption[0].key;
        const value = key === preselectedOptionKey ? '' : key;
        onChange(value);
    };

    const defaultOptionKey: ComboboxState = { value: [{ key: preselectedOptionKey, value: generalRequest }] };

    return (
        <FormField labelText={label} type="select">
            <Combobox onChange={onChangeInternal} name={name} defaultState={defaultOptionKey} testId={name}>
                {dropdownOptions.map(option => (
                    <Combobox.Item
                        key={option.optionKey}
                        optionKey={option.optionKey}
                        optionValue={option.optionValue}
                        testId={option.optionKey}
                    >
                        {option.content}
                    </Combobox.Item>
                ))}
            </Combobox>
        </FormField>
    );
};
