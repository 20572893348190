import { CPDate, WithDefaultMarketApiErrors } from '@cp-shared-6/common-utilities';

export interface BaseFinancialDetails {
    /*
     * Contract start date
     * */
    startDate: CPDate;
    /*
     * Contract end date
     * */
    endDate: CPDate;
    /*
     * IBAN
     * */
    iban: string;
    /*
     * NEXT product engagement.
     * Included if contract.modality_product == "C5"
     * */
    nextEngagement?: NextEngagement;
    /**
     * Is the change of due date allowed?
     */
    isDueDateChangeAllowed: boolean;
    /**
     * Is the contract active?
     */
    isActiveContract?: boolean;
    /*
     * Status of edition
     * */
    lastEditStatus?: {
        dueDate?: WithDefaultMarketApiErrors<DueDateEditStatus>;
        iban?: FinancialDetailsEditStatus;
    };
    /*
     * Upcoming due date
     * */
    upcomingDueDate?: CPDate;
}

export type BaseFinancialDetailsLinks = {
    validateIban: string;
    changeIban: string;
    sepaMandate: string;
};

/*
 * Financial details for FI or LE contracts
 * */
export type FinanceFinancialDetailsLinks = {
    changeMonthlyDueDate?: string;
};

export type FinanceFinancialDetailsResourceLinks = {
    _links?: FinanceFinancialDetailsLinks & BaseFinancialDetailsLinks;
};
export interface FinanceFinancialDetails extends BaseFinancialDetails, FinanceFinancialDetailsResourceLinks {
    /*
     * Pending capital
     * */
    pendingCapital?: number;
    /*
     * Amortized capital
     * */
    amortizedCapital?: number;
    /*
     * Nominal amount
     * */
    nominalAmount?: number;
    /*
     * Current payment date
     * */
    dueDate?: number;
}

export type OperatingLeaseFinancialDetailsResourceLinks = {
    _links?: BaseFinancialDetailsLinks;
};

/*
 * Financial details for AL or SV contracts
 * */
export interface OperatingLeaseFinancialDetails
    extends BaseFinancialDetails,
        OperatingLeaseFinancialDetailsResourceLinks {
    /*
     * Mileage last recalculation
     * */
    mileageLastRecalculation?: number;
    /*
     * Down payment
     * */
    downPayment?: number;
    /*
     * Bail amount
     * */
    bailAmount?: number;
    /*
     * Payment method description
     * */
    paymentMethod?: string;
    /*
     *  Cost center and its description
     * */
    costCenter?: string;
    /*
     * Deferment payment days
     * */
    defermentPaymentDays?: string;
    /*
     *  Cost center
     * */
    rawCostCenter?: string;
    /*
     *  Estimated early settlement cost for leasing contract type
     * */
    earlyCanAmount?: string;
}

export type NextEngagement = {
    /*
     * The total points added from the beginning of the contract
     * */
    totalPoints?: number;
    /*
     * Points awarded during this month
     * */
    thisMonthPoints?: number;
    /*
     * The time from which the customer can make the decision about a vehicle: renew it, keep it or return it
     * */
    engagedMonths?: number;
};

export type FinancialDetails = FinanceFinancialDetails | OperatingLeaseFinancialDetails;

export type PaymentData = {
    isSepaNeeded?: boolean;
    bankName: string;
};

export interface DueDateChange {
    dueDate: string;
}

export const VALID_DUE_DATES = ['4', '9', '14', '21', '28'];

export interface IbanChange {
    iban: string;
}

export type FetchMonthlyDueDateError = 'CANT_CHANGE_MORE_THAN_TWO_TIMES' | 'NO_INSTALMENTS_REMAINING';

export type IbanError = 'SAME_BANK_ACCOUNT' | 'NO_LOAN_PAYMENT_HOLDER' | 'WRONG_BANK_CODE';

export type FinancialDetailsEditStatus = 'SUCCESS' | 'SUCCESS_PENDING' | 'NOT_PERFORMED';

export type DueDateEditStatus = 'SAME_DATE_SELECTED' | FinancialDetailsEditStatus | FetchMonthlyDueDateError;
