import React from 'react';
import { useTranslation } from 'react-i18next';

import { Notification, NotificationStatus } from '@cp-shared-6/frontend-ui';
import { currentBrand } from '../../../config';

export type DifferentBrandNotificationProps = {
    testId?: string;
};

export const DifferentBrandNotification: React.FC<DifferentBrandNotificationProps> = ({ testId }) => {
    const { t } = useTranslation();

    let text;
    switch (currentBrand) {
        case 'audi':
        case 'seat':
        case 'skoda':
            text = t(`differentBrand.text.${currentBrand}`);
            break;

        default:
            text = t('differentBrand.text.vw');
            break;
    }

    return (
        <Notification
            status={NotificationStatus.error}
            headline={t('differentBrand.title')}
            text={text}
            testId={testId}
        />
    );
};
