export function getEarlySettlementHeaderEndpoint(contractId: string): string {
    return `/contracts/${contractId}/early-settlement-header`;
}

export function getTotalEarlySettlementEndpoint(contractId: string): string {
    return `/contracts/${contractId}/total-early-settlement`;
}

export function getOfflinePaymentEndpoint(contractId: string): string {
    return `/contracts/${contractId}/total-early-settlement/offline-payment`;
}

export function getOfflinePaymentEmailSendingEndpoint(contractId: string): string {
    return `/contracts/${contractId}/total-early-settlement/offline-payment/send-email`;
}

export function getPartialEarlySettlementEndpoint(contractId: string): string {
    return `/contracts/${contractId}/partial-early-settlement`;
}
