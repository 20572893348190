import React from 'react';
import { useTranslation } from 'react-i18next';
import { ContractParty, ContractPartyRole } from '@cp-es/common';
import { getContractBasedDataSelector, useGetContractBasedApiData } from '@cp-shared-6/frontend-integration';
import { AccordionItem, DataOverview, DefinitionListHorizontal } from '@cp-shared-6/frontend-ui';
import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import { ValueOrDefaultError } from 'components/value-or-default-error';
import { RootState } from 'store/rootReducer';
import { fetchContractParties } from './ContractPartiesSlice';

type ContractPartiesProps = {
    contractId: string;
    link?: string;
};

function compareContractParties(party1: ContractParty, party2: ContractParty): number {
    if (party1.userRole === party2.userRole) {
        return 0;
    }
    if (party1.userRole === ContractPartyRole.HOLDER) {
        return -1;
    }
    if (party2.userRole === ContractPartyRole.HOLDER) {
        return 1;
    }
    return 0;
}

const ContractPartiesComponent: React.FC<{ parties?: ContractParty[] }> = ({ parties }) => {
    const { t } = useTranslation('contracts');

    if (!parties) {
        return null;
    }

    const roleTranslation = (value: ContractPartyRole | undefined): string => {
        if (value === ContractPartyRole.HOLDER) {
            return t('contractParties.holder');
        } else if (value === ContractPartyRole.GUARANTOR) {
            return t('contractParties.guarantor');
        } else {
            return t('contractParties.error.valueMissing');
        }
    };

    const sortedParties = [...parties].sort(compareContractParties);

    return (
        <>
            {sortedParties.map(party => (
                <DataOverview key={party.name} title={party.name} withoutCardEffect={true}>
                    <DefinitionListHorizontal
                        list={[
                            {
                                label: t('contractParties.identification'),
                                value: <ValueOrDefaultError value={party.userId} />,
                            },
                            {
                                label: t('contractParties.role'),
                                value: roleTranslation(party.userRole),
                            },
                        ]}
                    />
                </DataOverview>
            ))}
        </>
    );
};
const ContractPartiesWithWrappers = withLoadingAndNoConnectionHandler(ContractPartiesComponent);

export const ContractPartiesDataSelector = getContractBasedDataSelector((state: RootState) => state.contractParties);

export const ContractParties: React.FC<ContractPartiesProps> = ({ contractId, link }) => {
    const { t } = useTranslation('contracts');

    const { data: contractParties, isLoading, loadingError } = useGetContractBasedApiData(
        contractId,
        fetchContractParties,
        ContractPartiesDataSelector,
        link,
    );

    return (
        <AccordionItem title={t('contractParties.headline')}>
            <div>
                <ContractPartiesWithWrappers
                    isLoading={isLoading}
                    hasError={!!loadingError}
                    parties={contractParties}
                />
            </div>
        </AccordionItem>
    );
};
