export interface VehicleDetails {
    /*
     * Description of vehicle which is concatenation of typecar (Brand) + model-code + version
     **/
    vehicleDescription?: string;
    /*
     * License number of a vehicle
     **/
    licensePlate?: string;
    /*
     * The unique Vehicle Identification Number of a vehicle
     **/
    vinCode?: string;
    /*
     * CO2 emissions from a fuel combustion inside a vehicle
     **/
    emissionCO2?: number;
    /*
     * Additional equipments could include special paint such as white metallic color, parking assist, rear view camera..etc
     **/
    additionalEquipments?: string[];
    /*
     * All the official accessories included with the vehicle
     **/
    accessories?: string[];

    vehicleModel?: VehicleModelDetails;

    brandHeader?: BrandHeader;
}

type VehicleModelDetails = {
    name?: string;
};

export enum BrandHeader {
    AUDI = 'audi',
    SEAT = 'seat',
    SKODA = 'skoda',
    VW = 'vw',
    VWN = 'vwn',
    VWFS = 'vwfs',
    CUPRA = 'cupra',
}
