import { FinanceContract as FinanceContractType } from '@cp-es/common';
import { Card } from '@vwfs-bronson/bronson-react';
import { ContractHeader } from 'components/contracts/ContractHeader';
import React from 'react';

export type RequestCertificateHeaderProps = {
    contract: FinanceContractType;
};

export const RequestCertificateHeader: React.FC<RequestCertificateHeaderProps> = ({ contract }) => {
    return (
        <Card element="article">
            <ContractHeader contract={contract} />
        </Card>
    );
};
