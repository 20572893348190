import { getNotFoundErrorEndpoint, HeroTeaserDto } from '@cp-es/common';
import { createCmsContentSlice } from 'cms-integration';

const { reducer, fetchContent } = createCmsContentSlice<HeroTeaserDto>({
    contentName: 'notFoundErrorContent',
    contentEndpoint: getNotFoundErrorEndpoint,
});

export default reducer;
export const fetchNotFoundError = fetchContent;
