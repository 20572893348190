import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ContractTermsHeader } from '../contract-terms-header';
import {
    Contract,
    EarlySettlementHeader,
    TotalEarlySettlement as TotalEarlySettlementType,
    EarlySettlementError,
} from '@cp-es/common';
import { withLoadingHandler } from 'components/integration-wrapper';

import { dashboardPagePath, earlySettlementPagePath } from 'components/navigation/paths';

import { useContract } from 'components/contracts/useContract';
import { NoConnectionNotification } from 'components/notifications/no-connection/NoConnectionNotification';
import { Button, Layout, Card } from '@vwfs-bronson/bronson-react';
import { useGetContractBasedApiData } from '@cp-shared-6/frontend-integration';
import { fetchTotalEarlySettlement } from './TotalEarlySettlementSlice';
import { TotalEarlySettlementDataSelector } from './TotalEarlySettlementDataSelector';
import { QuoteDetails } from './total-early-settlement-quote-details';
import { OfflinePayment } from './total-early-settlment-offline-payment';
import { WithDefaultCpIntegrationErrors } from '@cp-shared-6/common-utilities';
import { TotalEarlySettlementErrorNotification } from '../error-notification';
import { TotalEarlySettlementSimulationEntryPoint } from './total-early-settlement-simulation-entry-point';
import { FutureQuoteSimulation } from './future-quote-simulation/FutureQuoteSimulation';
import { TotalEarlySettlementViewType } from './TotalEarlySettlementViewType';
import { EarlySettlementHeaderDataSelector } from '../EarlySettlementHeaderDataSelector';
import { fetchEarlySettlementHeader } from '../EarlySettlementHeaderSlice';
import { EarlySettlementLoadingPlaceholder, useAnalyticsActionTracker } from '@cp-shared-6/frontend-ui';

type TotalEarlySettlementProps = {
    encryptedContractId: string;
};

type TotalEarlySettlementComponentProps = {
    contract?: Contract;
    earlySettlementHeader?: EarlySettlementHeader;
    totalEarlySettlement?: TotalEarlySettlementType;
    backToJointEntryPage: () => void;
    lastFetchError?: WithDefaultCpIntegrationErrors<EarlySettlementError>;
};

const TotalEarlySettlementComponent: React.FC<TotalEarlySettlementComponentProps> = ({
    contract,
    earlySettlementHeader,
    totalEarlySettlement,
    lastFetchError,
    backToJointEntryPage,
}) => {
    const [currentView, setCurrentView] = useState<TotalEarlySettlementViewType>('TES_TODAY_QUOTE');
    const [requestDate, setRequestDate] = useState();
    const { onAction: onProceedToPayment } = useAnalyticsActionTracker('onEarlySettlementProceedToPayment');
    useEffect(() => {
        if (currentView === 'TES_TODAY_QUOTE') {
            setRequestDate(undefined);
        }
    }, [currentView]);

    if (lastFetchError) {
        return <TotalEarlySettlementErrorNotification fetchContractError={lastFetchError} />;
    }

    if (!contract || !earlySettlementHeader || !totalEarlySettlement) {
        return <NoConnectionNotification />;
    }

    if (!(contract.isFinanceLeaseContract || contract.isFinancingContract)) {
        return <NoConnectionNotification />;
    }

    const { settlementDetails, subsidyDetails } = totalEarlySettlement;

    const proceedToOfflinePayment = (): void => {
        setCurrentView('TES_OFFLINE_PAYMENT');
        onProceedToPayment();
    };

    return (
        <>
            <ContractTermsHeader contractDetails={contract} earlySettlementHeader={earlySettlementHeader} />
            <Card className="u-mt" element="article">
                {currentView === 'TES_TODAY_QUOTE' && (
                    <>
                        <QuoteDetails
                            cancel={backToJointEntryPage}
                            proceedToOfflinePayment={proceedToOfflinePayment}
                            settlementDetails={settlementDetails}
                            subsidyDetails={subsidyDetails}
                            currentView={currentView}
                        />
                        <TotalEarlySettlementSimulationEntryPoint setCurrentView={setCurrentView} />
                    </>
                )}
                {currentView === 'TES_OFFLINE_PAYMENT' && (
                    <OfflinePayment
                        setCurrentView={setCurrentView}
                        contractId={contract.contractNumber}
                        offlinePaymentLink={totalEarlySettlement?._links?.offlinePayment}
                        offlinePaymentSendingEmailLink={totalEarlySettlement?._links?.offlinePaymentEmailSending}
                        requestDate={requestDate}
                    />
                )}
                {currentView === 'TES_FUTURE_QUOTE_SIMULATION' && (
                    <FutureQuoteSimulation
                        backToTodaysQuote={(): void => setCurrentView('TES_TODAY_QUOTE')}
                        proceedToOfflinePayment={proceedToOfflinePayment}
                        contract={contract}
                        totalEarlySettlement={totalEarlySettlement}
                        setRequestDate={setRequestDate}
                    />
                )}
            </Card>
        </>
    );
};

export const TotalEarlySettlement: React.FC<TotalEarlySettlementProps> = ({ encryptedContractId }) => {
    const { t } = useTranslation('total-early-settlement');
    const history = useHistory();

    const {
        data: contract,
        isLoading: isLoadingContract,
        loadingError: loadingErrorContract,
    } = useContract(encryptedContractId, { encryptedContractId: true });

    const {
        data: earlySettlementHeader,
        isLoading: isLoadingEarlySettlementHeader,
        loadingError: loadingErrorEarlySettlementHeader,
    } = useGetContractBasedApiData(
        contract?.contractNumber || '',
        fetchEarlySettlementHeader,
        EarlySettlementHeaderDataSelector,
        contract?._links?.earlySettlementHeader,
    );

    const {
        data: totalEarlySettlement,
        isLoading: isLoadingTotalEarlySettlement,
        loadingError: loadingErrorTotalEarlySettlement,
    } = useGetContractBasedApiData(
        contract?.contractNumber || '',
        fetchTotalEarlySettlement,
        TotalEarlySettlementDataSelector,
        contract?._links?.totalEarlySettlement,
    );

    const isLoading = isLoadingContract || isLoadingEarlySettlementHeader || isLoadingTotalEarlySettlement;
    const TotalEarlySettlementWithHandlers = withLoadingHandler(TotalEarlySettlementComponent);

    const hasError = loadingErrorContract || loadingErrorEarlySettlementHeader || loadingErrorTotalEarlySettlement;

    const backToDashboard = (): void => history.push(dashboardPagePath());
    const backToJointEntryPage = (): void => history.push(earlySettlementPagePath(encryptedContractId));

    return (
        <Layout>
            <Layout.Item className={'u-mt-large'} default="1/1">
                <TotalEarlySettlementWithHandlers
                    isLoading={isLoading}
                    contract={contract}
                    loadingPlaceholder={
                        <EarlySettlementLoadingPlaceholder withDividedContractHeader summaryElements={1} />
                    }
                    earlySettlementHeader={earlySettlementHeader}
                    totalEarlySettlement={totalEarlySettlement}
                    backToJointEntryPage={backToJointEntryPage}
                    lastFetchError={loadingErrorTotalEarlySettlement?.code}
                />
            </Layout.Item>
            {hasError && (
                <Layout.Item className={'u-text-center'} default="1/1">
                    <Button secondary onClick={backToDashboard} testId="backButton">
                        {t('translation:editableSectionNav.back')}
                    </Button>
                </Layout.Item>
            )}
        </Layout>
    );
};
