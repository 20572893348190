import React from 'react';
import { DefinitionListHorizontal, DefinitionListItem, DataOverview } from '@cp-shared-6/frontend-ui';
import { FinanceFinancialDetails } from '@cp-es/common';
import { TranslationFormat, useTranslationWithFormatting } from 'localization/useTranslationWithFormatting';
import { EditStatus } from '../EditStatus';
import { NotificationForLastEditStatus } from './notification-for-last-edit-status/NotificationForLastEditStatus';
import { WithDefaultMarketApiErrors } from '@cp-shared-6/common-utilities';

export type DueDateConsultViewProps = {
    details: FinanceFinancialDetails;
    startEditing: () => void;
    lastEditStatus: WithDefaultMarketApiErrors<EditStatus>;
    upcomingDueDate?: string;
    hasUnpaidBalance?: boolean;
};
export const ConsultView: React.FC<DueDateConsultViewProps> = ({
    details,
    startEditing,
    lastEditStatus,
    upcomingDueDate,
    hasUnpaidBalance,
}) => {
    const { t, f } = useTranslationWithFormatting('financial-details');

    if (!details) {
        return null;
    }

    const isEditAllowed = !!details._links?.changeMonthlyDueDate;

    const financeDueDateSectionItemList: DefinitionListItem[] = [
        {
            label: t('monthlyDueDateSection.consultView.label'),
            value: f(details.dueDate, TranslationFormat.NUMBER),
            tooltip: t('monthlyDueDateSection.consultView.tooltip'),
        },
    ].filter(item => !!item.value);

    return (
        <DataOverview
            title={t('monthlyDueDateSection.consultView.paymentDay')}
            buttonLabel={isEditAllowed ? t('monthlyDueDateSection.consultView.button.edit') : undefined}
            buttonProps={
                isEditAllowed
                    ? {
                          onClick: startEditing,
                          testId: 'editButton',
                          disabled: !details._links?.changeMonthlyDueDate,
                      }
                    : undefined
            }
            withoutCardEffect={true}
        >
            <DefinitionListHorizontal list={financeDueDateSectionItemList} />
            <NotificationForLastEditStatus
                lastEditStatus={lastEditStatus}
                hasUnpaidBalance={hasUnpaidBalance}
                upcomingDueDate={upcomingDueDate}
            />
        </DataOverview>
    );
};
