import { createSlice } from '@reduxjs/toolkit';
import { DigitalRenewalOptions, FinanceContract, OperatingLeaseContract } from '@cp-es/common';

interface NotificationsState {
    success: boolean;
    contract: FinanceContract | OperatingLeaseContract | null;
    selectedOption: DigitalRenewalOptions | null;
}

const initialState: NotificationsState = {
    success: false,
    contract: null,
    selectedOption: null,
};

export const notificationsSlice = createSlice({
    name: 'notifications',
    initialState,
    reducers: {
        setSuccessNotification: (state, action) => {
            state.success = true;
            state.contract = action.payload.contract;
            state.selectedOption = action.payload.selectedOption;
        },
        clearNotification: state => {
            state.success = false;
            state.contract = null;
            state.selectedOption = null;
        },
    },
});

export const { setSuccessNotification, clearNotification } = notificationsSlice.actions;

export default notificationsSlice.reducer;
