import React from 'react';
import {
    HeroImage,
    StaticPageLoadingPlaceholder,
    openInSameWindow,
    useAnalyticsErrorPageTracker,
} from '@cp-shared-6/frontend-ui';
import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import { useNotFoundErrorPage } from './useNotFoundErrorPage';

const HeroImageWithHandlers = withLoadingAndNoConnectionHandler(HeroImage);

export const NotFoundErrorPage: React.FC = () => {
    const { cmsContent: notFoundErrorContent, isLoading, loadingError } = useNotFoundErrorPage();
    useAnalyticsErrorPageTracker('notFoundError', !!notFoundErrorContent);

    return (
        <HeroImageWithHandlers
            isLoading={isLoading}
            hasError={!!loadingError}
            pretitle={notFoundErrorContent?.pretitle}
            title={notFoundErrorContent?.title || ''}
            subTitle={notFoundErrorContent?.subTitle}
            buttonText={notFoundErrorContent?.buttonText}
            clickHandler={openInSameWindow(notFoundErrorContent?.buttonUrl)}
            loadingPlaceholder={<StaticPageLoadingPlaceholder withHeroTeaser={false} />}
        />
    );
};
