import { License } from '@cp-es/common';

import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import React from 'react';
import frontendLicenses from './oss-license-es.json';

import { ThirdPartyLicensesUi } from './ui';
import { useThirdPartyLicenses } from './useThirdPartyLicenses';

const ThirdPartyLicensesWithHandlers = withLoadingAndNoConnectionHandler(ThirdPartyLicensesUi);

export const ThirdPartyLicenses: React.FC = () => {
    const { cmsContent: thirdPartyLicenses, isLoading, loadingError } = useThirdPartyLicenses();
    const content: License[] = frontendLicenses as License[];

    return thirdPartyLicenses ? (
        <ThirdPartyLicensesWithHandlers
            isLoading={isLoading}
            thirdPartyLicenses={{
                ...thirdPartyLicenses,
                content,
            }}
            hasError={!!loadingError}
        />
    ) : null;
};
