import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import { usePersonalDetails } from 'components/my-profile/usePersonalDetails';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useContracts } from '../contracts/useContracts';
import { PostboxUi } from './ui';
import { usePostbox } from './usePostbox';
import { MyDocumentsLoadingPlaceholder, Notification, NotificationStatus } from '@cp-shared-6/frontend-ui';

const PostboxWithHandlers = withLoadingAndNoConnectionHandler(PostboxUi);

export const Postbox: React.FC = () => {
    const { t } = useTranslation('postbox');

    const { data: postboxDocuments, isLoading: isLoadingPostbox, loadingError: loadingErrorPostbox } = usePostbox();
    const { data: contracts, isLoading: isLoadingContracts, loadingError: loadingErrorContracts } = useContracts();
    const {
        data: personalDetails,
        isLoading: isLoadingPersonalDetails,
        loadingError: loadingErrorPersonalDetails,
    } = usePersonalDetails();

    const isLoading = isLoadingPostbox || isLoadingContracts || isLoadingPersonalDetails;
    const postboxApiOrContractsApiError = !!loadingErrorPostbox || !!loadingErrorContracts;

    const hasInvoiceConsent =
        personalDetails?.invoiceConsent?.signUp !== undefined ? personalDetails?.invoiceConsent?.signUp : false;

    return (
        <>
            <h3>{t('sub-headline')}</h3>
            <p>{t('description')}</p>
            {!postboxApiOrContractsApiError && (
                <PostboxWithHandlers
                    isLoading={isLoading}
                    postboxDocuments={postboxDocuments}
                    contracts={contracts}
                    hasInvoiceConsent={hasInvoiceConsent}
                    hasError={false}
                    hasSignUpCheckError={!!loadingErrorPersonalDetails}
                    loadingPlaceholder={<MyDocumentsLoadingPlaceholder />}
                />
            )}
            {postboxApiOrContractsApiError && (
                <Notification
                    testId="PostboxApiOrContractsApiError"
                    status={NotificationStatus.error}
                    text={t('table.errors.api-not-available')}
                />
            )}
        </>
    );
};
