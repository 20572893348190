import React from 'react';
import { useTranslation } from 'react-i18next';
import { Notification, NotificationStatus } from '@cp-shared-6/frontend-ui';
import { WithDefaultCpIntegrationErrors } from '@cp-shared-6/common-utilities';
import { PersonalDetailsEditStatus } from '@cp-es/common';

export const NotificationForLastEditStatus: React.FC<{
    lastEditStatus: WithDefaultCpIntegrationErrors<PersonalDetailsEditStatus>;
    isSignUp: boolean;
}> = ({ lastEditStatus, isSignUp }) => {
    const { t } = useTranslation('my-profile-invoice-consent');

    const invoiceConsent = isSignUp ? 'sign-up' : 'sign-off';
    switch (lastEditStatus) {
        case 'SUCCESS': {
            return (
                <Notification
                    status={NotificationStatus.success}
                    headline={t(`consult-view.edit-status.success.${invoiceConsent}.title`)}
                    text={t(`consult-view.edit-status.success.${invoiceConsent}.text`)}
                    testId={'lastEditStatus'}
                />
            );
        }
        case 'NO_DATA_ERROR':
        case 'VALIDATION_ERROR':
        case 'MARKET_API_DEFAULT_BUSINESS_ERROR': {
            return (
                <Notification
                    status={NotificationStatus.error}
                    headline={t('consult-view.edit-status.business-error.title')}
                    text={t('consult-view.edit-status.business-error.text')}
                    testId={'lastEditStatus'}
                />
            );
        }
        /*
         * default case is required for entry to consult view (without edit)
         */
        default: {
            return null;
        }
    }
};
