import { onSanitize } from './sanitize';

export const DNI_LETTERS = 'TRWAGMYFPDXBNJZSQVHLCKE';
const DNI_DIGITS = 8;

const DNI_REGEX = new RegExp(`^(\\d{${DNI_DIGITS}})([${DNI_LETTERS}])$`);
const CHECKSUM_MODULO_DIVISOR = 23;

const getLetter = (str: string): string => {
    return DNI_LETTERS.charAt(parseInt(str, 10) % CHECKSUM_MODULO_DIVISOR);
};

const isDni = (str: string): boolean => DNI_REGEX.test(str) && str.endsWith(getLetter(str));

export const isValidDni = onSanitize(isDni);
