import React from 'react';
import { useTranslation } from 'react-i18next';

import { Contact } from '@cp-es/common';
import { DefinitionListHorizontal, DefinitionListItem } from '@cp-shared-6/frontend-ui';

export const ContactList: React.FC<{ contact: Contact }> = ({ contact }) => {
    const { t } = useTranslation('my-profile-contact-details');

    const contactList: DefinitionListItem[] = [
        {
            label: t('consultView.email'),
            value: contact.email,
        },
    ];

    if (contact.invoiceEmail) {
        contactList.push({
            label: t('consultView.invoiceEmail.label'),
            value: contact.invoiceEmail,
            tooltip: t('consultView.invoiceEmail.tooltip'),
        });
    }

    contactList.push({
        label: t('consultView.primaryPhoneNumber'),
        value: contact.primaryPhoneNumber,
    });

    if (contact.secondaryPhoneNumber) {
        contactList.push({
            label: t('consultView.secondaryPhoneNumber'),
            value: contact.secondaryPhoneNumber,
        });
    }

    return <DefinitionListHorizontal list={contactList} />;
};
