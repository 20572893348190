import React from 'react';
import { ContentSection, Layout } from '@vwfs-bronson/bronson-react';
import { DigitalRenewalRefinanceOrPayment } from 'components/digital-renewal/refinance-or-payment/DigitalRenewalRefinanceOrPayment';
import { useParams } from 'react-router-dom';
import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import { useContract } from 'components/contracts/useContract';
import { ContractType } from '@cp-es/common';
import { NoConnectionNotification } from 'components/notifications/no-connection/NoConnectionNotification';
import { usePersonalDetails } from '../components/my-profile/usePersonalDetails';

interface RouteParams {
    contractId: string;
}

const DigitalRenewalRefinanceOrPaymentWithHandlers = withLoadingAndNoConnectionHandler(
    DigitalRenewalRefinanceOrPayment,
);

export const DigitalRenewalRefinanceOrPaymentPage: React.FC = () => {
    const { contractId } = useParams<RouteParams>();
    const { data: contract, isLoading: isLoadingContract, loadingError: loadingErrorContract } = useContract(
        contractId,
        { encryptedContractId: true },
    );

    const {
        data: personalDetails,
        isLoading: isLoadingPersonalDetails,
        loadingError: loadingErrorPersonalDetails,
    } = usePersonalDetails();

    if (
        contract &&
        !(contract.contractType === ContractType.FINANCING || contract.contractType === ContractType.OPERATING_LEASE)
    ) {
        return <NoConnectionNotification />;
    }

    return (
        <ContentSection pageWrapSize="medium">
            <Layout>
                <Layout.Item default={'3/3'} m={'1/1'}>
                    <DigitalRenewalRefinanceOrPaymentWithHandlers
                        contract={contract}
                        personalDetails={personalDetails}
                        isLoading={isLoadingContract || isLoadingPersonalDetails}
                        hasError={!!loadingErrorContract || !!loadingErrorPersonalDetails}
                    />
                </Layout.Item>
            </Layout>
        </ContentSection>
    );
};
