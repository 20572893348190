import React from 'react';
import { ContentSection, Layout } from '@vwfs-bronson/bronson-react';
import { DigitalRenewalReturnOfTheCar } from 'components/digital-renewal/return-of-the-car/DigitalRenewalReturnOfTheCar';
import { useContract } from 'components/contracts/useContract';
import { useParams } from 'react-router-dom';
import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import { NoConnectionNotification } from 'components/notifications/no-connection/NoConnectionNotification';
import { ContractType } from '@cp-es/common';

interface RouteParams {
    contractId: string;
}

const DigitalRenewalReturnOfTheCarPageWithHandlers = withLoadingAndNoConnectionHandler(DigitalRenewalReturnOfTheCar);

export const DigitalRenewalReturnOfTheCarPage: React.FC = () => {
    const { contractId } = useParams<RouteParams>();
    const { data: contract, isLoading, loadingError } = useContract(contractId, {
        encryptedContractId: true,
    });

    if (
        contract &&
        !(contract.contractType === ContractType.FINANCING || contract.contractType === ContractType.OPERATING_LEASE)
    ) {
        return <NoConnectionNotification />;
    }

    return (
        <ContentSection pageWrapSize="medium">
            <Layout>
                <Layout.Item default={'3/3'} m={'1/1'}>
                    {contract && (
                        <DigitalRenewalReturnOfTheCarPageWithHandlers
                            contract={contract}
                            isLoading={isLoading}
                            hasError={!!loadingError}
                        />
                    )}
                </Layout.Item>
            </Layout>
        </ContentSection>
    );
};
