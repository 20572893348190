import { HeroTeaserDto, getLandingPageHeroTeaserEndpoint } from '@cp-es/common';
import { createCmsContentSlice } from 'cms-integration';

const { reducer, fetchContent } = createCmsContentSlice<HeroTeaserDto>({
    contentName: 'landingPageHeroTeaser',
    contentEndpoint: getLandingPageHeroTeaserEndpoint,
});

export default reducer;
export const fetchLandingPageHeroTeaser = fetchContent;
