import { onSanitize } from './sanitize';

const FOREIGNER_FIRST_LETTERS = 'KLM';
const FOREIGNER_DIGITS = 7;
const FOREIGNER_LETTERS = 'ABCDEFGHIJ';
const foreignerNifRegex = new RegExp(
    `^([${FOREIGNER_FIRST_LETTERS}])(\\d{${FOREIGNER_DIGITS}})([${FOREIGNER_LETTERS}])$`,
);
const countChecksum = (str: string): number => {
    const checkSum = str
        .substr(1, FOREIGNER_DIGITS)
        .split('')
        .map(Number)
        .map((value, index) => {
            if (index % 2) return value;
            const val = value * 2;
            return (val % 10) + Math.floor(val / 10);
        })
        .reduce((acc, curr) => acc + curr, 0);
    return 10 - (checkSum % 10) - 1;
};
const getForeignerChecksumLetter = (str: string): string => {
    const letterNumber = countChecksum(str);
    return FOREIGNER_LETTERS[letterNumber];
};

const foreignerNif = (str: string): boolean =>
    foreignerNifRegex.test(str) && str.endsWith(getForeignerChecksumLetter(str));

export const isValidForeignerNif = onSanitize(foreignerNif);
