import { RegistrationDataWithInvitation } from '../../apis';
import * as Yup from 'yup';
import { PersonalIdValidatorError, RegistrationDataWithInvitationError } from '../types';
import { defaultPersonalIdValidator, defaultRegistrationDataWithInvitationError } from '../messages';
import { isValidCif, isValidNif } from './spanish-id';

export const personalIdValidator = (
    errors: PersonalIdValidatorError = defaultPersonalIdValidator,
): Yup.SchemaOf<string> =>
    Yup.string()
        .trim()
        .required(errors.required)
        .test('format', errors.format, id => isValidNif(id) || isValidCif(id));

export const registrationDataWithInvitationValidator = (
    errors: RegistrationDataWithInvitationError = defaultRegistrationDataWithInvitationError,
): Yup.SchemaOf<RegistrationDataWithInvitation> =>
    Yup.object().shape({
        personalId: personalIdValidator(errors.personalId),
        invitationCode: Yup.string().required(errors.invitationCode.required),
    });
