import React from 'react';
import { ItemTeaser, Layout, ButtonContainer, Button, Heading } from '@vwfs-bronson/bronson-react';
import { Notification, NotificationStatus } from '@cp-shared-6/frontend-ui';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { dashboardPagePath } from '../navigation/paths';

export const VehicleReturn: React.FC = () => {
    const { t } = useTranslation('vehicle-return');

    const history = useHistory();

    return (
        <Layout>
            <Layout.Item default="1/1">
                <Heading level={2}>{t('heading')}</Heading>
            </Layout.Item>
            <Layout.Item default="1/1">
                <Notification status={NotificationStatus.info} text={t('notification-message')} />
            </Layout.Item>
            <Layout.Item default="1/2">
                <ItemTeaser
                    icon={t('item-email.icon-name')}
                    title={t('item-email.heading')}
                    linkProps={{
                        href: t('item-email.call-to'),
                        onClick: (): void => {
                            window.open('mailto:' + t('item-email.call-to'), '_blank');
                        },
                    }}
                    linkLabel={t('item-email.call-to')}
                >
                    {t('item-email.text')}
                </ItemTeaser>
            </Layout.Item>
            <Layout.Item default="1/2">
                <ItemTeaser
                    icon={t('item-phone.icon-name')}
                    title={t('item-phone.heading')}
                    linkProps={{
                        href: t('item-phone.call-to'),
                        onClick: (): void => {
                            window.open('tel:' + t('item-phone.call-to'), '_blank');
                        },
                    }}
                    linkLabel={t('item-phone.call-to')}
                ></ItemTeaser>
            </Layout.Item>
            <Layout.Item default="1/1">
                <ButtonContainer center={true}>
                    <Button
                        onClick={() => window.open(t('download-link.download-address'), '_blank')}
                        testId="callToActionButton"
                        secondary={false}
                        icon={t('download-link.icon-name')}
                        iconReversed={true}
                        link={true}
                        simple={true}
                        className={'u-1/4'}
                    >
                        {t('download-link.text')}
                    </Button>
                </ButtonContainer>
            </Layout.Item>
            <Layout.Item>
                <ButtonContainer center={true}>
                    <Button
                        testId="backButton"
                        secondary={true}
                        className={'u-1/5'}
                        onClick={() => history.push(dashboardPagePath())}
                    >
                        {t('return-button.text')}
                    </Button>
                </ButtonContainer>
            </Layout.Item>
        </Layout>
    );
};
