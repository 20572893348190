import * as Yup from 'yup';
import { IbanChange } from '../../apis';
import { IbanChangeError } from '../types';
import { defaultIbanChangeError } from '../messages';

const esIbanRegExp = new RegExp('((ES\\d{2})(([ ]\\d{4}){5}))|(ES)(\\d{22})');

export const ibanChangeValidator = (errors: IbanChangeError = defaultIbanChangeError): Yup.SchemaOf<IbanChange> =>
    Yup.object().shape({
        iban: Yup.string()
            .required(errors.iban.required)
            .matches(esIbanRegExp, errors.iban.matches),
    });
