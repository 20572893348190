import React from 'react';
import { useTranslation } from 'react-i18next';

import { ContentSection } from '@vwfs-bronson/bronson-react';
import { PageHeader } from '../components/page-header/PageHeader';
import { Postbox } from '../components/postbox';

export const PostboxPage: React.FC = () => {
    const { t } = useTranslation('postbox');

    return (
        <ContentSection pageWrapSize="medium">
            <PageHeader title={t('headline')} />
            <Postbox />
        </ContentSection>
    );
};
