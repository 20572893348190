import React from 'react';

import { OperatingLeaseFinancialDetails } from '@cp-es/common';
import { ValueOrDefaultError } from 'components/value-or-default-error';
import { TranslationFormat, useTranslationWithFormatting } from 'localization/useTranslationWithFormatting';
import { DataOverview, DefinitionListHorizontal, DefinitionListItem } from '@cp-shared-6/frontend-ui';

export const BaseSection: React.FC<{ details: OperatingLeaseFinancialDetails }> = ({ details }) => {
    const { startDate, endDate, mileageLastRecalculation, downPayment, bailAmount } = details;
    const { t, f } = useTranslationWithFormatting('financial-details');

    const baseSectionFields: DefinitionListItem[] = [
        {
            label: t('baseSection.startDate'),
            value: <ValueOrDefaultError value={f(startDate, TranslationFormat.DATE)} />,
        },
        {
            label: t('baseSection.endDate'),
            value: <ValueOrDefaultError value={f(endDate, TranslationFormat.DATE)} />,
        },
        {
            label: t('baseSection.mileageLastRecalculation.label'),
            value: f(mileageLastRecalculation, TranslationFormat.DISTANCE),
            tooltip: t('baseSection.mileageLastRecalculation.tooltip'),
        },
        {
            label: t('baseSection.downPayment'),
            value: f(downPayment, TranslationFormat.CURRENCY),
        },
        {
            label: t('baseSection.bailAmount.label'),
            value: f(bailAmount, TranslationFormat.CURRENCY),
            tooltip: t('baseSection.bailAmount.tooltip'),
        },
    ].filter(item => !!item.value);

    return (
        <DataOverview title={t('baseSection.title')} withoutCardEffect={true}>
            <DefinitionListHorizontal list={baseSectionFields} />
        </DataOverview>
    );
};
