import React, { useState } from 'react';
import { CpDataApi } from 'cp-xhr';
import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import { parseErrorResponse, useGetContractBasedApiData } from '@cp-shared-6/frontend-integration';
import { OfflinePaymentContent } from '../../offline-payment/offline-payment-content';
import { OfflinePaymentButtonBar } from '../../offline-payment/offline-payment-button-bar';
import { fetchOfflinePayment } from './OfflinePaymentSlice';
import { OfflinePaymentDataSelector } from './OfflinePaymentDataSelector';
import { EarlySettlementEmailRequest, EarlySettlementOptionType, RequestCertificateError } from '@cp-es/common';
import { OfflinePaymentConfirmationModal } from '../../offline-payment/offline-payment-confirmation-modal';
import { trimAllValues, WithDefaultCpIntegrationErrors } from '@cp-shared-6/common-utilities';
import { Spinner, useAnalyticsActionTracker, useAnalyticsPageViewTracker } from '@cp-shared-6/frontend-ui';

type OfflinePaymentProps = {
    offlinePaymentLink?: string;
    offlinePaymentSendingEmailLink?: string;
    contractId: string;
    setCurrentView: Function;
    requestDate?: string;
};

const OfflinePaymentContentWithHandlers = withLoadingAndNoConnectionHandler(OfflinePaymentContent);

export const OfflinePayment: React.FC<OfflinePaymentProps> = ({
    contractId,
    offlinePaymentLink,
    offlinePaymentSendingEmailLink,
    setCurrentView,
    requestDate,
}) => {
    const { data: offlinePaymentDetails, isLoading, loadingError } = useGetContractBasedApiData(
        contractId,
        fetchOfflinePayment,
        OfflinePaymentDataSelector,
        offlinePaymentLink,
        true,
        {
            params: { requestDate },
        },
    );

    useAnalyticsPageViewTracker('earlySettlementSummary', !!(offlinePaymentLink && offlinePaymentSendingEmailLink));
    const { onAction: onEmailRequestSuccess } = useAnalyticsActionTracker('onEditEarlySettlementSummaryLayerSuccess');
    const { onAction: onEmailRequestFailed } = useAnalyticsActionTracker('onEarlySettlementSummarySubmitFailed');
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [lastSubmissionError, setLastSubmissionError] = useState<
        WithDefaultCpIntegrationErrors<RequestCertificateError>
    >();
    const [lastReceivedEmail, setLastReceivedEmail] = useState('');

    if (!offlinePaymentLink || !offlinePaymentSendingEmailLink) {
        return null;
    }

    const openConfirmationModal = (
        isSuccess = true,
        trackingError?: {
            errorCode: string;
            errorMessage: string;
        },
    ): void => {
        setShowConfirmationModal(true);
        isSuccess ? onEmailRequestSuccess() : onEmailRequestFailed(trackingError);
        setIsSubmitting(false);
    };

    const closeConfirmationModal = (): void => {
        setShowConfirmationModal(false);
    };

    const sendEmail = (value: EarlySettlementOptionType): void => {
        const requestBody: EarlySettlementEmailRequest = {
            optionType: value,
        };

        setIsSubmitting(true);

        CpDataApi.post(offlinePaymentSendingEmailLink, trimAllValues(requestBody))
            .then(response => {
                setLastReceivedEmail(response.data?.email);
                setLastSubmissionError(undefined);
                openConfirmationModal();
            })
            .catch(error => {
                setLastReceivedEmail('');
                const { code, message } = parseErrorResponse<RequestCertificateError>(error);
                setLastSubmissionError(code);
                openConfirmationModal(false, { errorCode: code, errorMessage: message });
            });
    };

    const backToView = requestDate ? 'TES_FUTURE_QUOTE_SIMULATION' : 'TES_TODAY_QUOTE';
    const totalEarlySettlementOptionType = 'STO';

    return (
        <>
            <OfflinePaymentContentWithHandlers
                offlinePaymentDetails={offlinePaymentDetails}
                isLoading={isLoading}
                hasError={!!loadingError}
            />
            {!isLoading && (
                <OfflinePaymentButtonBar
                    back={(): void => setCurrentView(backToView)}
                    success={!!lastReceivedEmail}
                    sendEmail={(): void => sendEmail(totalEarlySettlementOptionType)}
                />
            )}

            {isSubmitting && <Spinner fullPage={true} />}
            <OfflinePaymentConfirmationModal
                shown={showConfirmationModal}
                receivedEmail={lastReceivedEmail}
                errorStatus={lastSubmissionError}
                closeConfirmationModal={closeConfirmationModal}
            />
        </>
    );
};
