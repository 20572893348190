import {
    ContractLinks,
    ContractStatus,
    ContractType,
    EncryptedContractNumber,
    FinanceContract as FinanceContractType,
} from '@cp-es/common';
import {
    Accordion,
    ContractActionItemType,
    ContractActions,
    useAnalyticsActionTracker,
} from '@cp-shared-6/frontend-ui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { AdditionalProducts } from '../additional-products/AdditionalProducts';
import { ContractParties } from '../contract-parties';
import { FinanceFinancialDetails } from '../financial-details';
import { VehicleDetails } from '../vehicle-details';
import { amortizationTablePath, earlySettlementPagePath, requestCertificatePath } from '../../navigation/paths';
import { UnpaidDetailsAlert } from 'components/unpaid-details/unpaid-details-alert';
import { currentBrand } from 'config';
import { Card } from '@vwfs-bronson/bronson-react';
import { LicensePlate } from '../../license-plate';
import { FinanceContractHeaderSummary } from './FinanceContractHeaderSummary';
import { getProductCategoryTranslationLabel } from '../ProductCategoryTranslationLabel';
import { useHistory } from 'react-router-dom';

export type FinanceContractProps = {
    contract: FinanceContractType & EncryptedContractNumber;
    links?: ContractLinks;
    getViewChange: Function;
    defaultExpanded?: boolean;
};

export const FinanceContract: React.FC<FinanceContractProps> = ({
    contract,
    links,
    getViewChange,
    defaultExpanded,
}) => {
    const history = useHistory();
    const { t } = useTranslation('contracts');
    const { onAction: trackOnAccordionItemOpen } = useAnalyticsActionTracker('onOpenContractAccordion');
    const { onAction: trackOnContractActionEntryPointClick } = useAnalyticsActionTracker(
        'onContractActionEntryPointClick',
    );
    const redirectTo = (path: string) => {
        history.push(path);
    };

    const {
        carInformation,
        contractNumber,
        contractStatus,
        contractType,
        _encryptedContractNumber,
        licensePlateNumber,
        unpaidAmount,
        unpaidInstalments,
        nextInstalmentDate,
        nextInstalmentAmount,
        totalAmount,
    } = contract;

    const contractCategory =
        contractType === ContractType.FINANCING
            ? t(getProductCategoryTranslationLabel(ContractType.FINANCING))
            : t(getProductCategoryTranslationLabel(ContractType.FINANCE_LEASE));

    const contractActionItems: ContractActionItemType[] = [
        {
            iconName: 'semantic-file',
            onClick: () => {
                redirectTo(requestCertificatePath(_encryptedContractNumber));
                trackOnContractActionEntryPointClick(t('contract-actions.request-certificate'));
            },
            label: t('contract-actions.request-certificate'),
        },
        {
            iconName: 'semantic-calendar',
            onClick: () => {
                redirectTo(amortizationTablePath(_encryptedContractNumber));
                trackOnContractActionEntryPointClick(t('contract-actions.amortization-table'));
            },
            label: t('contract-actions.amortization-table'),
        },
    ];

    if (links?.totalEarlySettlement) {
        contractActionItems.push({
            iconName: 'semantic-calculator',
            onClick: () => {
                redirectTo(earlySettlementPagePath(_encryptedContractNumber));
                trackOnContractActionEntryPointClick(t('contract-actions.early-settlement'));
            },
            label: t('contract-actions.early-settlement'),
        });
    }

    const licensePlateComponent = <LicensePlate licensePlateNumber={licensePlateNumber} />;

    const isUnpaidPresent = unpaidAmount && unpaidAmount > 0.0 && unpaidInstalments && unpaidInstalments > 0;

    const contractBody = links ? (
        <div data-theme={currentBrand === 'seat' ? 'alternative' : 'primary'}>
            <Accordion
                lazyRender
                onChange={([element]: [string]): void => {
                    if (!!element) {
                        const viewChange = getViewChange(element);
                        trackOnAccordionItemOpen(viewChange);
                    }
                }}
            >
                <ContractActions contractActionItems={contractActionItems} />
                <br />
                {links.financialDetails && (
                    <FinanceFinancialDetails
                        hasUnpaidBalance={!!isUnpaidPresent && contractStatus === ContractStatus.ACTIVE}
                        contractId={contractNumber}
                        encryptedContractId={_encryptedContractNumber}
                        link={links.financialDetails}
                    />
                )}
                {links.additionalProducts && (
                    <AdditionalProducts contractId={contractNumber} link={links.additionalProducts} />
                )}
                {links.vehicleDetails && <VehicleDetails contractId={contractNumber} link={links.vehicleDetails} />}
                {links.contractParties && <ContractParties contractId={contractNumber} link={links.contractParties} />}
            </Accordion>
        </div>
    ) : null;

    const contractUnpaids = isUnpaidPresent ? (
        <UnpaidDetailsAlert
            encryptedContractId={_encryptedContractNumber}
            unpaidAmount={unpaidAmount}
            className="u-mt u-mb-small"
        />
    ) : null;

    return (
        <Card
            element="article"
            expandable
            title={carInformation}
            subtitle={`${contractCategory} ${contractNumber}`}
            contentShort={licensePlateComponent}
            className={'u-mb'}
            contentLarge={contractBody}
            defaultExpanded={defaultExpanded}
        >
            <FinanceContractHeaderSummary
                nextInstalmentDate={nextInstalmentDate}
                nextInstalmentAmount={nextInstalmentAmount}
                totalAmount={totalAmount}
            />
            {contractUnpaids}
        </Card>
    );
};
