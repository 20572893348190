import { formatCpDate } from '@cp-shared-6/common-utilities';
import { DATE_FORMAT } from '@cp-es/common';

export type FormValues = {
    settlementDate: string;
};

export const getInitialValues = (): FormValues => ({
    settlementDate: formatCpDate().format(DATE_FORMAT),
});
