import { ContractLinks, DATE_FORMAT, EarlySettlementError, TotalEarlySettlementSimulationRequest } from '@cp-es/common';
import { preventSubmit, Spinner, useAnalyticsActionTracker, ValidatedDate } from '@cp-shared-6/frontend-ui';
import { Button, Fieldset, Form, Layout } from '@vwfs-bronson/bronson-react';
import { Formik } from 'formik';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { CpDataApi } from '../../../../../cp-xhr';
import { parseErrorResponse } from '@cp-shared-6/frontend-integration';
import { CPDate, formatCpDate } from '@cp-shared-6/common-utilities';
import { getInitialValues, FormValues } from './initialValues';

export type FutureQuoteSimulationFormProps = {
    links?: ContractLinks;
    contractEndDate?: CPDate;
    totalEarlySettlementFutureQuote: Function;
    setRequestDate: Function;
    setLastSubmissionError: Function;
};

export const FutureQuoteSimulationForm: React.FC<FutureQuoteSimulationFormProps> = ({
    contractEndDate,
    links,
    totalEarlySettlementFutureQuote,
    setRequestDate,
    setLastSubmissionError,
}) => {
    const { t, i18n } = useTranslation('total-early-settlement');

    const [isSubmitting, setIsSubmitting] = useState(false);
    const { onAction: onCalculate } = useAnalyticsActionTracker('onEarlySettlementCalculate');
    const language = i18n.languages[0];

    const maxDate = formatCpDate(contractEndDate)
        .subtract(2, 'days')
        .format(DATE_FORMAT);

    const validationSchema = Yup.object().shape({
        futureQuoteDate: Yup.string()
            .required(t('future-quote-simulation.date.validation.required'))
            .test('format', t('future-quote-simulation.date.validation.format'), date =>
                formatCpDate(date, DATE_FORMAT).isValid(),
            )
            .test('no future date', t('future-quote-simulation.date.validation.not-future-date'), date =>
                formatCpDate(date, DATE_FORMAT)
                    .toMoment()
                    .isSameOrAfter(formatCpDate().toMoment()),
            )
            .test('not before expire date', t('future-quote-simulation.date.validation.not-valid-date'), date =>
                formatCpDate(date, DATE_FORMAT)
                    .toMoment()
                    .isSameOrBefore(
                        formatCpDate(contractEndDate)
                            .subtract(2, 'days')
                            .toMoment(),
                    ),
            ),
    });

    const onSubmit = (values: FormValues): void => {
        if (!links?.totalEarlySettlement) {
            return;
        }
        setIsSubmitting(true);
        const requestDate = values.futureQuoteDate
            ?.split('/')
            .reverse()
            .join('-');
        setRequestDate(requestDate);
        const requestBody: TotalEarlySettlementSimulationRequest = {
            requestDate: requestDate as string,
        };
        CpDataApi.get(links?.totalEarlySettlement, { params: requestBody })
            .then(response => {
                setIsSubmitting(false);
                const responseData = response.data;
                totalEarlySettlementFutureQuote(responseData);
                setLastSubmissionError(undefined);
            })
            .catch(error => {
                setIsSubmitting(false);
                const errorCode = parseErrorResponse<EarlySettlementError>(error).code;
                totalEarlySettlementFutureQuote(undefined);
                setLastSubmissionError(errorCode);
            });
    };

    return (
        <>
            <Layout className={'u-mb-small'}>
                <Layout.Item default="1/1">
                    <h3>{t('future-quote-simulation.headline')}</h3>
                    <p>{t('future-quote-simulation.description')}</p>
                </Layout.Item>
            </Layout>
            <Formik initialValues={getInitialValues()} onSubmit={onSubmit} validationSchema={validationSchema}>
                {(formik): JSX.Element => (
                    <Form onSubmit={preventSubmit()} data-testid="futureQuoteSimulationForm">
                        <Fieldset className={'u-text-center'}>
                            <Fieldset.Row>
                                <Layout className={'u-text-center'}>
                                    <Layout.Item default="1/2" m={'1/1'}>
                                        <ValidatedDate
                                            label={t('future-quote-simulation.date.label', {
                                                date: maxDate,
                                            })}
                                            language={language}
                                            outputDateFormat="d/m/Y"
                                            maxDate={maxDate}
                                            minDate={formatCpDate()
                                                .add(1, 'days')
                                                .format(DATE_FORMAT)}
                                            name={'futureQuoteDate'}
                                            testId={'futureQuoteDate'}
                                            type="text"
                                            value={formatCpDate()
                                                .add(1, 'days')
                                                .format(DATE_FORMAT)}
                                        />
                                    </Layout.Item>
                                </Layout>
                            </Fieldset.Row>
                            <Fieldset.Row>
                                <Layout className={'u-mb-small'}>
                                    <Layout.Item className={'u-text-center'} default="1/1">
                                        <Button
                                            onClick={async (): Promise<void> => {
                                                onCalculate();
                                                await formik.submitForm();
                                            }}
                                            testId="submitButton"
                                            type="btn"
                                            disabled={isSubmitting}
                                        >
                                            {t('future-quote-simulation.button')}
                                        </Button>
                                    </Layout.Item>
                                </Layout>
                            </Fieldset.Row>
                        </Fieldset>
                    </Form>
                )}
            </Formik>
            {isSubmitting && <Spinner fullPage={true} />}
        </>
    );
};
