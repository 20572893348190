import React from 'react';
import { NotificationStatus, NotificationWithNavigationButton } from '@cp-shared-6/frontend-ui';
import { unpaidDetailsPath } from 'components/navigation/paths';
import { TranslationFormat, useTranslationWithFormatting } from 'localization/useTranslationWithFormatting';

export type UnpaidDetailsAlertProps = { encryptedContractId: string; unpaidAmount?: number; className?: string };

export const UnpaidDetailsAlert: React.FC<UnpaidDetailsAlertProps> = ({
    unpaidAmount,
    encryptedContractId,
    className,
}) => {
    const { t, f } = useTranslationWithFormatting('unpaid-details');
    const formattedUnpaidAmount = f(unpaidAmount, TranslationFormat.CURRENCY);
    return (
        <NotificationWithNavigationButton
            status={NotificationStatus.warning}
            headline={t('entry-alert.headline')}
            text={t('entry-alert.text', { formattedUnpaidAmount })}
            notificationTestId="unpaid-details-notification"
            submitButtonLabel={t('entry-alert.details-button')}
            pathToNavigate={unpaidDetailsPath(encryptedContractId)}
            className={className}
        />
    );
};
