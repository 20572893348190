import * as Yup from 'yup';
import { RequestCertificateValidatorError } from '../types';
import { defaultRequestCertificateValidatorError } from '../messages';
import { RequestCertificateData, REQUEST_CERTIFICATE_TYPES } from '../../apis';
import { CPDateInterface, formatCpDate } from '@cp-shared-6/common-utilities';
import { DATE_FORMAT } from '../../utils';

export const requestCertificateValidator = (
    errors: RequestCertificateValidatorError = defaultRequestCertificateValidatorError,
): Yup.SchemaOf<RequestCertificateData> =>
    Yup.object()
        .shape({
            certificateType: Yup.string()
                .required(errors.certificateType.required)
                .oneOf(REQUEST_CERTIFICATE_TYPES, errors.certificateType.oneOf),
            requestDate: Yup.mixed().when('certificateType', {
                is: 'OUTSTANDING_DEBT_CONTRACT',
                then: Yup.string()
                    .nullable()
                    .transform(date => {
                        let transformed: CPDateInterface = formatCpDate(date);
                        if (!transformed.isValid()) {
                            transformed = formatCpDate(date, DATE_FORMAT);
                        }
                        if (!transformed.isValid()) {
                            return date;
                        }
                        return transformed.toCpDate();
                    })
                    .required(errors.requestDate.required)
                    .test('valid', errors.requestDate.invalid, date => formatCpDate(date).isValid()),
            }),
        })
        .defined();
