import { PrivacyPolicy, getPrivacyPolicyEndpoint } from '@cp-es/common';
import { createCmsContentSlice } from 'cms-integration';

const { reducer, fetchContent } = createCmsContentSlice<PrivacyPolicy>({
    contentName: 'privacyPolicyPage',
    contentEndpoint: getPrivacyPolicyEndpoint,
});

export default reducer;
export const fetchPrivacyPolicyPage = fetchContent;
