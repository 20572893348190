import { PrivacyPolicy } from '@cp-es/common';
import { Table } from '@vwfs-bronson/bronson-react';
import React from 'react';

export type PrivacyPolicyComponentProps = {
    privacyPolicyContent: PrivacyPolicy | undefined;
};

export const PrivacyPolicyTable: React.FC<PrivacyPolicyComponentProps> = ({ privacyPolicyContent }) => {
    if (!privacyPolicyContent?.tableContent) {
        return null;
    }

    const {
        tableContent,
        tableContent: { rows },
    } = privacyPolicyContent;

    return (
        <Table bordered={true} colored={true} wide={true}>
            <Table.Thead>
                <Table.Tr>
                    <Table.Th colSpan={2} className={'u-text-center'}>
                        {tableContent.title}
                    </Table.Th>
                </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
                {rows.map((row, index) => {
                    return (
                        <Table.Tr key={row.column1}>
                            <Table.Td>{row.column1}</Table.Td>
                            <Table.Td className={index == 1 ? 'u-pt' : ''}>
                                <span dangerouslySetInnerHTML={{ __html: row.column2 }} />
                            </Table.Td>
                        </Table.Tr>
                    );
                })}
            </Table.Tbody>
        </Table>
    );
};
