import { Notification, NotificationStatus, PageHeader, Spinner } from '@cp-shared-6/frontend-ui';
import { ContentSection, Button, Modal } from '@vwfs-bronson/bronson-react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NoConnectionNotification } from 'components/notifications/no-connection/NoConnectionNotification';
import { CpDataApi } from 'cp-xhr';
import {
    Contract,
    FinancialDetails,
    getMyRequestEndpoint,
    OpenRequestRentingCategoriesSalesforce,
    VehicleDetails,
} from '@cp-es/common';
import { ContractTermsHeaderLeasing } from '../contract-terms-header';
import { trimAllValues } from '@cp-shared-6/common-utilities';

import { useHistory } from 'react-router-dom';
import { dashboardPagePath } from 'components/navigation/paths';

type EarlySettlementLeasingUiProps = {
    contract?: Contract;
    email?: string;
    vehicleDetails?: VehicleDetails[];
    financialDetails?: FinancialDetails;
};

export const EarlySettlementLeasingUi: React.FC<EarlySettlementLeasingUiProps> = ({
    contract,
    email,
    vehicleDetails,
    financialDetails,
}) => {
    const { t } = useTranslation('early-settlement-leasing');
    const history = useHistory();
    const [requestSendWithSuccess, setRequestSendWithSuccess] = useState<boolean | null>(null);
    const [isSubmitting, setIsSubmitting] = useState(false);

    if (!contract || !vehicleDetails || !financialDetails || !('mileageLastRecalculation' in financialDetails)) {
        return <NoConnectionNotification />;
    }

    const contractTermsHeaderData = {
        mileageLastRecalculation: financialDetails?.mileageLastRecalculation,
        earlyCanAmount: financialDetails?.earlyCanAmount,
    };

    const handleSubmit = (): void => {
        setIsSubmitting(true);
        const requestBody = {
            email,
            category: OpenRequestRentingCategoriesSalesforce.EARLY_SETTLEMENT_LEASING,
            shortDescription: 'Cancelación para Renting',
            request: `
                Contrato: ${contract.contractNumber}
                Bastidor: ${vehicleDetails[0].vinCode}
                Coste cancelación: ${contractTermsHeaderData.earlyCanAmount} €
                Kilometraje: ${contractTermsHeaderData.mileageLastRecalculation}
            `,
            contractInfo: { contractType: contract.contractType },
        };

        CpDataApi.post(getMyRequestEndpoint(), trimAllValues(requestBody))
            .then(() => {
                setRequestSendWithSuccess(true);
            })
            .catch(() => {
                setRequestSendWithSuccess(false);
            })
            .finally(() => {
                setIsSubmitting(false);
            });
    };

    return (
        <ContentSection pageWrapSize="medium">
            <PageHeader title={t('heading')} />
            {isSubmitting && <Spinner fullPage={true} />}
            <div className="u-mv">
                <Notification text={t('notifications.info')}></Notification>
            </div>
            <ContractTermsHeaderLeasing contractDetails={contract} contractTermsHeaderData={contractTermsHeaderData} />

            <div className="u-mv">
                <Notification
                    text={t('notifications.warning', {
                        settlementAmount: contractTermsHeaderData.earlyCanAmount,
                    })}
                    status={NotificationStatus.warning}
                ></Notification>
            </div>
            <Button onClick={handleSubmit} className="u-mb-small">
                {t('submit-button')}
            </Button>

            <Modal
                shown={requestSendWithSuccess}
                buttonConfirmText={t('message.success.button-text')}
                onConfirm={(): void => history.push(dashboardPagePath())}
                testId="confirmModal"
                title={t('message.success.title')}
                hideCloseButton
                status={NotificationStatus.success}
            >
                {t('message.success.content')}
            </Modal>

            <Notification
                status={NotificationStatus.error}
                headline={t('message.error.title')}
                text={t('message.error.content')}
                testId="submissionError"
                visible={requestSendWithSuccess === false}
            />
        </ContentSection>
    );
};
