import { createGetContractBasedDataFetchSlice } from '@cp-shared-6/frontend-integration';
import { EarlySettlementHeader } from '@cp-es/common';
import { CpDataApi } from 'cp-xhr';
import { DefaultBusinessMarketApiErrorCode } from '@cp-shared-6/common-utilities';

const { reducer, fetchData } = createGetContractBasedDataFetchSlice<
    EarlySettlementHeader,
    DefaultBusinessMarketApiErrorCode
>({
    dataName: 'earlySettlementHeader',
    fetchCallback(link: string) {
        return CpDataApi.get(link).then(response => response.data);
    },
});

export default reducer;
export const fetchEarlySettlementHeader = fetchData;
