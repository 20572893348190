import { combineReducers, createAction, AnyAction } from '@reduxjs/toolkit';
import { connectRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import additionalProductsReducer from 'components/contracts/additional-products/AdditionalProductsSlice';
import amortizationDetailsReducer from 'components/amortization-table/AmortizationTableSlice';
import contractPartiesReducer from 'components/contracts/contract-parties/ContractPartiesSlice';
import contractsReducer from 'components/contracts/ContractsSlice';
import financialDetailsReducer from 'components/contracts/financial-details/FinancialDetailsSlice';
import cookiePolicyReducer from 'components/cookie-policy/CookiePolicySlice';
import customerTypeReducer from 'components/customer-type/CustomerTypeSlice';
import dashboardMarketingCardsReducer from 'components/dashboard-marketing-card/DashboardMarketingCardSlice';
import forbiddenErrorReducer from 'components/error-pages/forbidden-error/ForbiddenErrorPageSlice';
import notFoundErrorReducer from 'components/error-pages/notfound-error/NotFoundErrorPageSlice';
import unauthorizedErrorReducer from 'components/error-pages/unauthorized-error/UnauthorizedErrorPageSlice';
import faqReducer from 'components/faq/FaqSlice';
import logoutReducer from 'components/logout/LogoutSlice';
import iconFooterReducer from 'components/footer/IconFooterSlice';
import legalFooterReducer from 'components/footer/LegalFooterSlice';
import greetingReducer from 'components/greeting/GreetingSlice';
import landingPageHeroTeaserReducer from 'components/landing-page-hero-teaser/LandingPageHeroTeaserSlice';
import landingPageMarketingCardsReducer from 'components/landing-page-marketing-card/LandingPageMarketingCardsSlice';
import legalNoticeReducer from 'components/legal-notice/LegalNoticeSlice';
import myProfileReducer from 'components/my-profile/MyProfileSlice';
import privacyPolicyReducer from 'components/registration/PrivacyPolicySlice';
import privacyPolicyPageReducer from 'components/privacy-policy/PrivacyPolicySlice';
import registrationReducer from 'components/registration/RegistrationSlice';
import unpaidDetailsReducer from 'components/unpaid-details/UnpaidDetailsSlice';
import smeContractsReducer from 'components/contracts/financial-details/iban-section/edit-view/sme-contracts-view/SmeContractsSlice';
import vehicleDetailsReducer from 'components/contracts/vehicle-details/VehicleDetailsSlice';
import postboxReducer from 'components/postbox/PostboxSlice';
import totalEarlySettlementReducer from 'components/early-settlement/total-early-settlement/TotalEarlySettlementSlice';
import offlinePaymentReducer from 'components/early-settlement/total-early-settlement/total-early-settlment-offline-payment/OfflinePaymentSlice';
import earlySettlementHeaderReducer from 'components/early-settlement/EarlySettlementHeaderSlice';
import partialEarlySettlementReducer from 'components/early-settlement/partial-early-settlement/PartialEarlySettlementSlice';
import thirdPartyLicensesReducer from 'components/third-party-licenses/ThirdPartyLicensesSlice';
import productRoutingReducer from 'components/dashboard-marketing-card/productRoutingSlice';
import notificationReducer from 'components/notifications/digital-renewal/DigitalRenewalNotificationSlice';

export const history = createBrowserHistory();

const appReducer = combineReducers({
    router: connectRouter(history),
    customerType: customerTypeReducer,
    contracts: contractsReducer,
    financialDetails: financialDetailsReducer,
    contractParties: contractPartiesReducer,
    additionalProducts: additionalProductsReducer,
    amortizationDetails: amortizationDetailsReducer,
    greeting: greetingReducer,
    vehicleDetails: vehicleDetailsReducer,
    myProfile: myProfileReducer,
    smeContracts: smeContractsReducer,
    unpaidDetails: unpaidDetailsReducer,
    postbox: postboxReducer,
    totalEarlySettlement: totalEarlySettlementReducer,
    offlinePayment: offlinePaymentReducer,
    earlySettlementHeader: earlySettlementHeaderReducer,
    partialEarlySettlement: partialEarlySettlementReducer,
    productRouting: productRoutingReducer,
    notifications: notificationReducer,
    content: combineReducers({
        iconFooter: iconFooterReducer,
        legalFooter: legalFooterReducer,
        logout: logoutReducer,
        privacyPolicy: privacyPolicyReducer,
        privacyPolicyPage: privacyPolicyPageReducer,
        registration: registrationReducer,
        landingPageHeroTeaser: landingPageHeroTeaserReducer,
        landingPageMarketingCards: landingPageMarketingCardsReducer,
        dashboardMarketingCard: dashboardMarketingCardsReducer,
        cookiePolicy: cookiePolicyReducer,
        faq: faqReducer,
        unauthorizedError: unauthorizedErrorReducer,
        forbiddenError: forbiddenErrorReducer,
        notFoundError: notFoundErrorReducer,
        legalNotice: legalNoticeReducer,
        thirdPartyLicenses: thirdPartyLicensesReducer,
    }),
});

export type RootState = ReturnType<typeof appReducer>;

export const resetStore = createAction('store/reset');

const rootReducer = (state: RootState | undefined, action: AnyAction): RootState => {
    if (action.type === resetStore.toString()) {
        state = undefined;
    }
    return appReducer(state, action);
};

export default rootReducer;
