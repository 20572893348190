import {
    ContractLinks,
    ContractStatus,
    ContractType,
    EncryptedContractNumber,
    OperatingLeaseFinancialDetails as OperatingLeaseFinancialDetailsType,
    OperatingLeaseContract as OperatingLeaseContractType,
} from '@cp-es/common';
import { formatCpDate } from '@cp-shared-6/common-utilities';
import {
    Accordion,
    ContractActionItemType,
    ContractActions,
    Notification,
    NotificationStatus,
    useAnalyticsActionTracker,
} from '@cp-shared-6/frontend-ui';
import { Card } from '@vwfs-bronson/bronson-react';
import {
    requestSubragationPagePath,
    requestVehiclePurchasePagePath,
    requestVehicleReturnPagePath,
    contractRecalculation,
    earlySettlementLeasingPagePath,
} from 'components/navigation/paths';
import { TranslationFormat, useTranslationWithFormatting } from 'localization/useTranslationWithFormatting';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { currentBrand } from '../../../config';
import { LicensePlate } from '../../license-plate';
import { AdditionalProducts } from '../additional-products/AdditionalProducts';
import { ContractParties } from '../contract-parties';
import { fetchFinancialDetails, OperatingLeaseFinancialDetails } from '../financial-details';
import { getProductCategoryTranslationLabel } from '../ProductCategoryTranslationLabel';
import { VehicleDetails } from '../vehicle-details';
import { OperatingLeaseContractHeaderSummary } from './OperatingLeaseContractHeaderSummary';
import { useGetContractBasedApiData } from '@cp-shared-6/frontend-integration';
import { FinancialDetailsDataSelector } from '../financial-details/FinancialDetailsDataSelector';
import { boundry } from '../../contract-recalculation/contract-recalculation-form/ContractRecalculationFormValues';

export type OperatingLeaseContractProps = {
    contract: OperatingLeaseContractType & EncryptedContractNumber;
    links?: ContractLinks;
    getViewChange: Function;
    defaultExpanded?: boolean;
};

export const OperatingLeaseContract: React.FC<OperatingLeaseContractProps> = ({
    contract,
    links,
    getViewChange,
    defaultExpanded,
}) => {
    const { t, f } = useTranslationWithFormatting('unpaid-details');
    const { onAction: trackOnAccordionItemOpen } = useAnalyticsActionTracker('onOpenContractAccordion');
    const { onAction: trackOnContractActionEntryPointClick } = useAnalyticsActionTracker(
        'onContractActionEntryPointClick',
    );
    const history = useHistory();

    const {
        carInformation,
        contractNumber,
        _encryptedContractNumber,
        licensePlateNumber,
        unpaidAmount,
        unpaidInstalments,
        nextInstalmentAmount,
        vinCode,
        contractEndDate,
        contractStatus,
    } = contract;

    const contractCategory = t(getProductCategoryTranslationLabel(ContractType.OPERATING_LEASE));

    const licensePlateComponent = <LicensePlate licensePlateNumber={licensePlateNumber} />;

    const redirectTo = (path: string) => {
        history.push(path);
    };

    const contractActionItems: ContractActionItemType[] = [
        {
            iconName: 'semantic-accessoires',
            onClick: () => {
                history.push(requestSubragationPagePath(_encryptedContractNumber));
                trackOnContractActionEntryPointClick(t('contract-actions.request-subrogation'));
            },
            label: t('contract-actions.request-subrogation'),
        },
        {
            iconName: 'semantic-car-handover',
            onClick: () => history.push(requestVehicleReturnPagePath()),
            label: t('contract-actions.vehicle-return'),
        },
        {
            iconName: 'car-circle-eurosign',
            onClick: () => history.push(requestVehiclePurchasePagePath(_encryptedContractNumber)),
            label: t('contract-actions.request-vehicle-purchase'),
        },
    ];

    if (contractStatus === ContractStatus.ACTIVE) {
        contractActionItems.push({
            iconName: 'semantic-calculator',
            onClick: () => {
                redirectTo(earlySettlementLeasingPagePath(_encryptedContractNumber));
            },
            label: t('contract-actions.early-settlement'),
        });
    }

    const { data: details } = useGetContractBasedApiData(
        contractNumber,
        fetchFinancialDetails,
        FinancialDetailsDataSelector,
        links?.financialDetails,
    );

    const operatingLeaseFinancialDetails: OperatingLeaseFinancialDetailsType | undefined = details;

    const currentDuration = formatCpDate(operatingLeaseFinancialDetails?.endDate).diff(
        operatingLeaseFinancialDetails?.startDate,
        'M',
        false,
    );

    if (
        contractStatus === ContractStatus.ACTIVE &&
        operatingLeaseFinancialDetails?.mileageLastRecalculation &&
        operatingLeaseFinancialDetails?.mileageLastRecalculation <= boundry.distance.maximum &&
        operatingLeaseFinancialDetails?.startDate &&
        currentDuration <= boundry.duration.maximum
    ) {
        contractActionItems.push({
            iconName: 'calculator',
            onClick: () => {
                trackOnContractActionEntryPointClick(t('contract-actions.contract-recalculation'));
                history.push(contractRecalculation(_encryptedContractNumber, vinCode));
            },
            label: t('contract-actions.contract-recalculation'),
        });
    }

    const contractBody = links ? (
        <div data-theme={currentBrand === 'seat' ? 'alternative' : 'primary'}>
            <Accordion
                lazyRender
                onChange={([element]: [string]): void => {
                    if (!!element) {
                        const viewChange = getViewChange(element);
                        trackOnAccordionItemOpen(viewChange);
                    }
                }}
            >
                <ContractActions contractActionItems={contractActionItems} />
                {links.financialDetails && (
                    <OperatingLeaseFinancialDetails
                        encryptedContractId={_encryptedContractNumber}
                        contractId={contractNumber}
                        link={links.financialDetails}
                    />
                )}
                {links.additionalProducts && (
                    <AdditionalProducts contractId={contractNumber} link={links.additionalProducts} />
                )}
                {links.vehicleDetails && <VehicleDetails contractId={contractNumber} link={links.vehicleDetails} />}
                {links.contractParties && <ContractParties contractId={contractNumber} link={links.contractParties} />}
            </Accordion>
        </div>
    ) : null;

    const isUnpaidPresent = unpaidAmount && unpaidAmount > 0.0 && unpaidInstalments && unpaidInstalments > 0;
    const formattedUnpaidAmount = f(unpaidAmount, TranslationFormat.CURRENCY);
    const contractUnpaids = isUnpaidPresent ? (
        <Notification
            status={NotificationStatus.warning}
            headline={t('entry-alert.headline')}
            text={t('entry-alert.text', { formattedUnpaidAmount })}
            className="u-mt u-mb-small"
        />
    ) : null;

    return (
        <Card
            element="article"
            expandable
            title={carInformation}
            subtitle={`${contractCategory} ${contractNumber}`}
            contentShort={licensePlateComponent}
            className={'u-mb'}
            contentLarge={contractBody}
            defaultExpanded={defaultExpanded}
        >
            <OperatingLeaseContractHeaderSummary
                vinCode={vinCode}
                nextInstalmentAmount={nextInstalmentAmount}
                contractEndDate={contractEndDate ? formatCpDate(contractEndDate).toCpDate() : undefined}
            />
            {contractUnpaids}
        </Card>
    );
};
