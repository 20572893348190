import { getForbiddenErrorEndpoint, HeroTeaserDto } from '@cp-es/common';
import { createCmsContentSlice } from 'cms-integration';

const { reducer, fetchContent } = createCmsContentSlice<HeroTeaserDto>({
    contentName: 'unauthorizedErrorContent',
    contentEndpoint: getForbiddenErrorEndpoint,
});

export default reducer;
export const fetchForbiddenError = fetchContent;
