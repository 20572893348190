import React from 'react';

type DigitalRenewalEntryNotificationContent = {
    content: string;
    handleClick: () => void;
};
const DigitalRenewalEntryNotificationContent: React.FC<DigitalRenewalEntryNotificationContent> = ({
    content,
    handleClick,
}) => {
    const [beforeLink, linkText, afterLink] = content.split(/<a href='#'>|<\/a>/);
    return (
        <>
            <span dangerouslySetInnerHTML={{ __html: beforeLink }} />
            <a href="#" onClick={handleClick}>
                {linkText}
            </a>
            {afterLink}
        </>
    );
};

export default DigitalRenewalEntryNotificationContent;
