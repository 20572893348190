import { ContentSection } from '@vwfs-bronson/bronson-react';
import React from 'react';

import { UnauthorizedErrorPage } from 'components/error-pages/unauthorized-error/UnauthorizedErrorPage';

export const NotAuthorizedPage: React.FC = () => (
    <ContentSection pageWrap pageWrapSize="medium">
        <UnauthorizedErrorPage />
    </ContentSection>
);
