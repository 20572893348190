import React, { useEffect } from 'react';
import { InvitationParams } from '@cp-es/common';
import { useLocation } from 'react-router-dom';
import { Spinner } from '@cp-shared-6/frontend-ui';
import { useLogin } from 'auth/useLogin';
import { dashboardPagePath } from 'components/navigation/paths';

export const InvitationRedirect: React.FC = () => {
    const location = useLocation();

    const searchParams = new URLSearchParams(location.search);

    // example params for testing /invitation?language=es&email=dev.unregister@mailinator.com&invitation=MDNUQjMyM1IZ&cid=email.customerportal.bienvenida.01-04-2019.skoda
    const invitationParams: InvitationParams = {
        email: searchParams.get('email') ?? undefined,
        invitation: searchParams.get('invitation') ?? undefined,
        language: searchParams.get('language') ?? undefined,
    };
    const login = useLogin(dashboardPagePath, invitationParams.email);

    useEffect(() => {
        login();
    }, [login]);

    return <Spinner center={true} />;
};
