export const currentBrand = process.env.REACT_APP_WEBSITE_BRAND || 'vwfs';
export const integrationApiBaseUrl = process.env.REACT_APP_INTEGRATION_API_BASE_URL;
export const integrationApiBaseUrlLocal = process.env.REACT_APP_API_BASE_URL_LOCAL;
export const cecaUrl = process.env.REACT_APP_CECA_URL ?? '';
export const bankName = process.env.REACT_APP_BANK_NAME ?? '';
export const iban = process.env.REACT_APP_IBAN ?? '';
export const idpHint = process.env.REACT_APP_IDP_HINT ?? '';
export const apiGatewayConsumerName = 'vwfs-du-cpes';
export const isAfterRegistrationLocalStorageKey = 'isAfterRegistration';
export const isDigitalRenewalEnabled = process.env.REACT_APP_IS_DIGITAL_RENEWAL_ENABLED === 'true';
