import React, { useState } from 'react';
import { FutureQuoteSimulationForm } from './future-quote-simulation-form/FutureQuoteSimulationForm';
import { Contract, TotalEarlySettlement, EarlySettlementError } from '@cp-es/common';
import { FutureQuoteSimulationButtonBar } from './future-quote-simulation-button-bar';
import { QuoteDetails } from '../total-early-settlement-quote-details';
import { Notification, NotificationStatus, useAnalyticsPageViewTracker } from '@cp-shared-6/frontend-ui';
import { useTranslationWithFormatting } from '../../../../localization/useTranslationWithFormatting';
import { WithDefaultCpIntegrationErrors } from '@cp-shared-6/common-utilities';

type FutureQuoteSimulationProps = {
    backToTodaysQuote: () => void;
    proceedToOfflinePayment: () => void;
    contract: Contract;
    totalEarlySettlement: TotalEarlySettlement;
    setRequestDate: Function;
};

export const FutureQuoteSimulation: React.FC<FutureQuoteSimulationProps> = ({
    backToTodaysQuote,
    contract,
    proceedToOfflinePayment,
    setRequestDate,
}) => {
    useAnalyticsPageViewTracker('earlySettlementSimulation', !!contract?._links?.totalEarlySettlement);
    const [totalEarlySettlementFutureQuote, setTotalEarlySettlementFutureQuote] = useState<TotalEarlySettlement>();
    const [lastSubmissionError, setLastSubmissionError] = useState<
        WithDefaultCpIntegrationErrors<EarlySettlementError>
    >();
    const { t } = useTranslationWithFormatting('total-early-settlement');

    if (!contract?._links?.totalEarlySettlement) {
        return null;
    }

    return (
        <>
            <FutureQuoteSimulationForm
                links={contract._links}
                contractEndDate={contract.contractEndDate}
                totalEarlySettlementFutureQuote={setTotalEarlySettlementFutureQuote}
                setRequestDate={setRequestDate}
                setLastSubmissionError={setLastSubmissionError}
            />
            {lastSubmissionError && (
                <Notification
                    className="u-text-left"
                    status={NotificationStatus.warning}
                    headline={t('future-quote-simulation.error.headline')}
                    text={t('future-quote-simulation.error.text')}
                />
            )}
            {totalEarlySettlementFutureQuote && (
                <QuoteDetails
                    cancel={backToTodaysQuote}
                    proceedToOfflinePayment={proceedToOfflinePayment}
                    settlementDetails={totalEarlySettlementFutureQuote?.settlementDetails}
                    subsidyDetails={totalEarlySettlementFutureQuote?.subsidyDetails}
                    currentView={'TES_FUTURE_QUOTE_SIMULATION'}
                />
            )}
            {!totalEarlySettlementFutureQuote && <FutureQuoteSimulationButtonBar back={backToTodaysQuote} />}
        </>
    );
};
