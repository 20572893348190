import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Hr } from '@vwfs-bronson/bronson-react';
import { FinanceFinancialDetails as FinanceFinancialDetailsType } from '@cp-es/common';
import { useGetContractBasedApiData } from '@cp-shared-6/frontend-integration';
import { AccordionItem } from '@cp-shared-6/frontend-ui';
import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import { FinancialDetailsProps } from '../FinancialDetailsProps';
import { fetchFinancialDetails } from '../FinancialDetailsSlice';
import { BaseSection } from './base-section/BaseSection';
import { IbanSection } from './iban-section/IbanSection';
import { DueDateSection } from './due-date-section/DueDateSection';
import { NextEngagementSection } from './next-engagement-section/NextEngagementSection';
import { FinancialDetailsDataSelector } from '../FinancialDetailsDataSelector';
import { useUpdateFinancialDetailsPerContract as useUpdateFinancialDetails } from '../useUpdateFinancialDetails';

const FinancialDetails: React.FC<{
    contractId: string;
    encryptedContractId: string;
    details?: FinanceFinancialDetailsType;
    hasUnpaidBalance?: boolean;
}> = ({ contractId, details, hasUnpaidBalance, encryptedContractId }) => {
    if (!details) {
        return null;
    }
    return (
        <>
            <BaseSection details={details} />
            <Hr className="u-mb-none" />
            <IbanSection encryptedContractId={encryptedContractId} contractId={contractId} details={details} />
            <Hr className="u-mb-none" />
            <DueDateSection
                hasUnpaidBalance={hasUnpaidBalance}
                encryptedContractId={encryptedContractId}
                contractId={contractId}
                details={details}
            />
            {details.nextEngagement && (
                <>
                    <Hr className="u-mb-none" />
                    <NextEngagementSection nextEngagement={details.nextEngagement} />
                </>
            )}
        </>
    );
};
const FinancialDetailsWithWrappers = withLoadingAndNoConnectionHandler(FinancialDetails);

export const FinanceFinancialDetails: React.FC<FinancialDetailsProps> = ({
    contractId,
    link,
    hasUnpaidBalance,
    encryptedContractId,
}) => {
    const { t } = useTranslation('contracts');

    const { data: details, isLoading, loadingError } = useGetContractBasedApiData(
        contractId,
        fetchFinancialDetails,
        FinancialDetailsDataSelector,
        link,
    );
    const { updateFinancialDetails, financialDetailsData } = useUpdateFinancialDetails(contractId);

    const resetLastEditStatus = (): void => {
        if (financialDetailsData)
            updateFinancialDetails({
                ...financialDetailsData,
                lastEditStatus: {
                    dueDate: 'NOT_PERFORMED',
                    iban: 'NOT_PERFORMED',
                },
            });
    };

    useEffect(() => {
        resetLastEditStatus();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <AccordionItem title={t('financialDetails.headline')}>
            <div>
                <FinancialDetailsWithWrappers
                    isLoading={isLoading}
                    hasError={!!loadingError}
                    contractId={contractId}
                    encryptedContractId={encryptedContractId}
                    details={details}
                    hasUnpaidBalance={hasUnpaidBalance}
                />
            </div>
        </AccordionItem>
    );
};
