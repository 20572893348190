import {
    AddressValidationErrors,
    CityValidationErrors,
    ComplementaryInformationValidationErrors,
    StreetValidationErrors,
} from '../types';

export const defaultStreetValidationErrors: StreetValidationErrors = {
    name: {
        matches: 'Street name should match to Spanish alphanumeric.',
        max: 'Street name is too long!',
        required: 'Street name is required!',
    },
    number: {
        matches: 'Street number should match to Spanish alphanumeric.',
        max: 'Street number is too long!',
        required: 'Street number is required!',
    },
};

export const defaultCityValidationErrors: CityValidationErrors = {
    zip: {
        matches: 'City zip should match to Spanish zip code patten.',
        max: 'City zip is too long!',
        required: 'City zip is required!',
    },
    locality: {
        matches: 'City locality should match to Spanish alphanumeric.',
        max: 'City locality is too long!',
        required: 'City locality is required!',
    },
};

export const defaultComplementaryInformationValidationErrors: ComplementaryInformationValidationErrors = {
    block: { matches: 'Block should match to Spanish alphanumeric.', max: 'Block is too long!' },
    stairs: { matches: 'Stairs should match to Spanish alphanumeric.', max: 'Stairs is too long!' },
    floor: { matches: 'Floor should match to Spanish alphanumeric.', max: 'Floor is too long!' },
    door: { matches: 'Door should match to Spanish alphanumeric.', max: 'Door is too long!' },
    details: { matches: 'Details should match to Spanish alphanumeric.', max: 'Details is too long!' },
};

export const defaultAddressValidationErrors: AddressValidationErrors = {
    city: defaultCityValidationErrors,
    complementaryInformation: defaultComplementaryInformationValidationErrors,
    street: defaultStreetValidationErrors,
};
