import {
    Contract,
    DashboardMarketingCard,
    PersonalDetails,
    TotalEarlySettlement,
    TotalEarlySettlementOfflinePayment,
} from '@cp-es/common';
import { withLoadingHandler } from 'components/integration-wrapper';
import React, { useEffect } from 'react';
import { MarketingCardComponent } from '../marketing-card-component';
import { useProductRouting } from '../useProductRouting';

const ExtendedWarrantyMarketingCardWithHandlers = withLoadingHandler(MarketingCardComponent);

type ExtendedWarrantyMarketingCardProps = {
    mostRecentSeatContract?: Contract;
    personalDetails?: PersonalDetails;
    dashboardMarketingCard?: DashboardMarketingCard;
    testId?: string;
    className?: string;
    setProductRoutingLoaded: (isLoaded: boolean) => void;
    totalEarlySettelment?: TotalEarlySettlement;
    offlinePayment?: TotalEarlySettlementOfflinePayment;
};

export const ExtendedWarrantyMarketingCard: React.FC<ExtendedWarrantyMarketingCardProps> = ({
    mostRecentSeatContract,
    personalDetails,
    dashboardMarketingCard,
    testId,
    className,
    setProductRoutingLoaded,
    offlinePayment,
}) => {
    const { data: productRouting, isLoading: productRoutingIsLoading, loadingError } = useProductRouting(
        mostRecentSeatContract,
        personalDetails,
        offlinePayment,
    );

    useEffect(() => {
        if (productRouting?.url) setProductRoutingLoaded(true);
        if (!!loadingError) setProductRoutingLoaded(false);
    }, [productRouting, setProductRoutingLoaded, loadingError]);

    if (!dashboardMarketingCard) return null;

    return (
        <ExtendedWarrantyMarketingCardWithHandlers
            isLoading={productRoutingIsLoading}
            content={dashboardMarketingCard}
            buttonUrl={productRouting?.url}
            testId={testId}
            className={className}
        />
    );
};
