import {
    Address,
    Contact,
    Contract,
    ContractStatus,
    ContractType,
    Identification,
    InvoiceConsent,
    PersonalDetails,
    PostboxDocument,
} from '@cp-es/common';
import { formatCpDate } from '@cp-shared-6/common-utilities';

export const PostboxDocument1: PostboxDocument = {
    date: formatCpDate('2020-04-07').toCpDate(),
    _links: {
        downloadEndpoint: '/postbox-document-1-download',
    },
    type: 'INVOICE',
    invoiceNumber: 'S18095659',
    invoiceAmount: 458.56,
};

export const PostboxDocument2: PostboxDocument = {
    contractNumber: '440128700001',
    operationCode: '440128700001',
    date: formatCpDate('2020-11-14').toCpDate(),
    _links: {
        downloadEndpoint: '/postbox-document-2-download',
    },
    type: 'FINANCING_CONTRACT',
};

export const PostboxDocument3: PostboxDocument = {
    contractNumber: '897946541354',
    operationCode: '897946541354',
    date: formatCpDate('2021-01-12').toCpDate(),
    _links: {
        downloadEndpoint: '/postbox-document-3-download',
    },
    type: 'WELCOME_LETTER',
};

export const PostboxDocumentsWithAllValues: PostboxDocument[] = [PostboxDocument1, PostboxDocument2, PostboxDocument3];

export const ContractsWithAllValues: Contract[] = [
    {
        contractNumber: '440128700001',
        _encryptedContractNumber: '440128700001encrypted',
        carInformation: 'VW NEW YORK 2.0 BITDI SE 180 4DR 4MTN DSG',
        contractStatus: ContractStatus.ACTIVE,
        contractType: ContractType.FINANCING,
        licensePlateNumber: '5776 CNSE',
        isActiveContract: true,
        isExpiredContract: false,
        isFinanceLeaseContract: false,
        isFinancingContract: false,
        isOperatingLeaseContract: false,
    },
    {
        contractNumber: '440128700002',
        _encryptedContractNumber: '440128700002encrypted',
        carInformation: 'VW CALIFORNIA BEETLE DSG',
        contractStatus: ContractStatus.ACTIVE,
        contractType: ContractType.OPERATING_LEASE,
        licensePlateNumber: 'A 0849 CS',
        isActiveContract: true,
        isExpiredContract: false,
        isFinanceLeaseContract: false,
        isFinancingContract: false,
        isOperatingLeaseContract: true,
    },
    {
        contractNumber: '440128700003',
        _encryptedContractNumber: '440128700003encrypted',
        carInformation: 'VW CALIFORNIA BEETLE DSG',
        contractStatus: ContractStatus.ACTIVE,
        contractType: ContractType.FINANCE_LEASE,
        licensePlateNumber: 'A 1234 CS',
        isActiveContract: true,
        isExpiredContract: false,
        isFinanceLeaseContract: true,
        isFinancingContract: false,
        isOperatingLeaseContract: false,
    },
];

export const IdentificationWithAllValues: Identification = {
    name: 'OLIVER PRAGER',
    id: '07221753Y',
};

export const InvoiceConsentWithSignUpNeeded: InvoiceConsent = {
    signUp: true,
};

export const InvoiceConsentWithSignUpNotNeeded: InvoiceConsent = {
    signUp: false,
};

export const AddressWithAllValues: Address = {
    roadType: 'Calle',
    name: 'MARIA GUERRERO',
    number: '3',
    door: '2B',
    floor: '4',
    block: '6',
    stairs: '1',
    details: 'URBANIZACIÓN DE LOS PICOS',
    zip: '28000',
    locality: 'Navalcarnero',
    province: 'BARCELONA',
    country: 'ESPAÑA',
    _links: {
        changeLink: '/customers/personal-details/address',
    },
};

export const ContactWithAllValues: Contact = {
    email: 'david.torres@digitalunit.berlin',
    invoiceEmail: 'david.torres@digitalunit.berlin',
    primaryPhoneNumber: '6205360',
    secondaryPhoneNumber: '717330854',
    _links: {
        changeContactDetails: '/customers/personal-details/contact-details',
    },
};

export const MyProfileWithAllValues: PersonalDetails = {
    identification: IdentificationWithAllValues,
    address: AddressWithAllValues,
    contact: ContactWithAllValues,
    invoiceConsent: InvoiceConsentWithSignUpNeeded,
};
