import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { AnalyticsProvider, MasterPage as MasterPageShared, useAuthentication } from '@cp-shared-6/frontend-ui';

import { currentBrand } from 'config';
import { IconFooter } from 'components/footer/IconFooter';
import { LegalFooter } from 'components/footer/LegalFooter';
import { NavigationBar } from 'components/navigation/NavigationBar';
import { registrationPagePath, loginPath, identityBrokerErrorPagePath } from 'components/navigation/paths';
import { useCustomerType } from 'components/customer-type/useCustomerType';
import { useLocation } from 'react-router-dom';

type MasterAnalyticsType = { isAuthenticated: boolean };
const MasterAnalytics: React.FC<MasterAnalyticsType> = memo(({ children, isAuthenticated }) => {
    const { i18n } = useTranslation();
    const currentLanguage = i18n.languages[0];
    const { data: customerType } = useCustomerType();
    const userGroup = isAuthenticated && !!customerType ? customerType : undefined;
    return (
        <AnalyticsProvider
            market="es"
            isAuthenticated={isAuthenticated}
            version="10"
            releaseDate="2021-06-21"
            language={currentLanguage}
            registrationPath={registrationPagePath()}
            loginPath={loginPath()}
            userGroup={userGroup}
            brand={currentBrand}
        >
            {children}
        </AnalyticsProvider>
    );
});
MasterAnalytics.displayName = 'MasterAnalytics';

export const MasterPage: React.FC = ({ children }) => {
    const { isAuthenticated } = useAuthentication();
    return <MasterPageComponent isAuthenticated={isAuthenticated}>{children}</MasterPageComponent>;
};
const MasterPageComponent: React.FC<{ isAuthenticated: boolean }> = memo(({ isAuthenticated, children }) => {
    const { t } = useTranslation();
    const location = useLocation();
    const currentPathName = location.pathname;
    const masterPageProps =
        currentPathName !== identityBrokerErrorPagePath()
            ? {
                  iconFooter: <IconFooter />,
                  label: t('backToTop'),
              }
            : {};
    return (
        <MasterAnalytics isAuthenticated={isAuthenticated}>
            <MasterPageShared navigationBar={<NavigationBar />} legalFooter={<LegalFooter />} {...masterPageProps}>
                {children}
            </MasterPageShared>
        </MasterAnalytics>
    );
});
