import {
    Contract,
    ContractDataWithLinksAndEncryptedContractNumber,
    ContractStatus,
    ContractType,
    getAdditionalProductsEndpoint,
    getAmortizationDetailsEndpoint,
    getAmortizationDetailsPdfDownloadEndpoint,
    getContractPartiesEndpoint,
    getEarlySettlementHeaderEndpoint,
    getFinancialDetailsEndpoint,
    getPartialEarlySettlementEndpoint,
    getTotalEarlySettlementEndpoint,
    getUnpaidDetailsEndpoint,
    getVehicleDetailsEndpoint,
} from '@cp-es/common';
import { formatCpDate } from '@cp-shared-6/common-utilities';

export const ContractsWithoutLinks: Contract[] = [
    {
        contractNumber: '440128700001',
        _encryptedContractNumber: '440128700001encrypted',
        carInformation: 'VW NEW YORK 2.0 BITDI SE 180 4DR 4MTN DSG',
        contractStatus: ContractStatus.ACTIVE,
        contractType: ContractType.FINANCING,
        contractEndDate: formatCpDate('2022-12-31').toCpDate(),
        licensePlateNumber: '5776 CNSE',
        isActiveContract: true,
        isExpiredContract: false,
        isFinanceLeaseContract: false,
        isFinancingContract: true,
        isOperatingLeaseContract: false,
    },
    {
        contractNumber: '440128700002',
        _encryptedContractNumber: '440128700002encrypted',
        carInformation: 'VW CALIFORNIA BEETLE DSG',
        contractStatus: ContractStatus.ACTIVE,
        contractType: ContractType.OPERATING_LEASE,
        licensePlateNumber: 'A 0849 CS',
        isActiveContract: true,
        isExpiredContract: false,
        isFinanceLeaseContract: false,
        isFinancingContract: false,
        isOperatingLeaseContract: true,
        contractEndDate: formatCpDate('2022-12-31').toCpDate(),
    },
    {
        contractNumber: '440128700003',
        _encryptedContractNumber: '440128700003encrypted',
        carInformation: 'VW CALIFORNIA BEETLE DSG',
        contractStatus: ContractStatus.ACTIVE,
        contractType: ContractType.FINANCE_LEASE,
        licensePlateNumber: 'A 1234 CS',
        isActiveContract: true,
        isExpiredContract: false,
        isFinanceLeaseContract: true,
        isFinancingContract: false,
        isOperatingLeaseContract: false,
        contractEndDate: formatCpDate('2022-12-31').toCpDate(),
    },
    {
        contractNumber: '440128700004',
        _encryptedContractNumber: '440128700004encrypted',
        carInformation: 'VOLKSWAGEN POLO',
        contractStatus: ContractStatus.ACTIVE,
        contractType: ContractType.FINANCE_LEASE,
        contractEndDate: formatCpDate('2022-12-31').toCpDate(),
        licensePlateNumber: 'A 1234 CS',
        isActiveContract: true,
        isExpiredContract: false,
        isFinanceLeaseContract: true,
        isFinancingContract: false,
        isOperatingLeaseContract: false,
    },
    {
        contractType: ContractType.FINANCING,
        contractStatus: ContractStatus.ACTIVE,
        contractNumber: '440128795350',
        _encryptedContractNumber: '440128795350encrypted',
        carInformation: 'VW NEW YORK 2',
        nextInstalmentAmount: 900.19,
        nextInstalmentDate: formatCpDate('2019-12-21').toCpDate(),
        totalAmount: 607.19,
        licensePlateNumber: 'ABC-12-1',
        unpaidAmount: 853.9,
        unpaidInstalments: 6,
        productModality: 'CPC',
        beginDate: '2020-09-09T22:00:00.000Z',
        contractEndDate: formatCpDate('2024-02-01').toCpDate(),
        isFinancingContract: true,
        isFinanceLeaseContract: false,
        isOperatingLeaseContract: false,
        isActiveContract: true,
        isExpiredContract: false,
    },
];

export const ContractsWithAllValues: ContractDataWithLinksAndEncryptedContractNumber[] = [
    {
        contractType: ContractType.FINANCING,
        contractStatus: ContractStatus.ACTIVE,
        contractNumber: '440128795350',
        _encryptedContractNumber: '440128795350encrypted',
        carInformation: 'VW NEW YORK 2.0BITDI SE 180 4DR 4MTN DSG',
        nextInstalmentAmount: 885.19,
        nextInstalmentDate: formatCpDate('2019-12-21').toCpDate(),
        totalAmount: 607.19,
        licensePlateNumber: 'ABC-12-1',
        unpaidAmount: 853.9,
        unpaidInstalments: 6,
        productModality: 'CPC',
        beginDate: '2020-09-09T22:00:00.000Z',
        contractEndDate: formatCpDate('2024-02-01').toCpDate(),
        _links: {
            financialDetails: getFinancialDetailsEndpoint('440128795350'),
            contractParties: getContractPartiesEndpoint('440128795350'),
            vehicleDetails: getVehicleDetailsEndpoint('440128795350'),
            additionalProducts: getAdditionalProductsEndpoint('440128795350'),
            amortizationDetails: getAmortizationDetailsEndpoint('440128795350'),
            unpaidDetails: getUnpaidDetailsEndpoint('440128795350'),
            totalEarlySettlement: getTotalEarlySettlementEndpoint('440128795350'),
            earlySettlementHeader: getEarlySettlementHeaderEndpoint('440128795350'),
            partialEarlySettlement: getPartialEarlySettlementEndpoint('440128795350'),
            amortizationTablePdfDownload: getAmortizationDetailsPdfDownloadEndpoint('440128795350'),
        },
    },
    {
        contractType: ContractType.OPERATING_LEASE,
        contractStatus: ContractStatus.EXPIRED,
        contractNumber: '897946541354',
        _encryptedContractNumber: '897946541354encrypted',
        carInformation: 'VW CALIFORNIA BEETLE DSG',
        nextInstalmentAmount: 1231.19,
        vinCode: '127361823612390712368',
        contractEndDate: formatCpDate('2020-09-30').toCpDate(),
        unpaidAmount: 853.9,
        unpaidInstalments: 6,
        _links: {
            financialDetails: getFinancialDetailsEndpoint('897946541354'),
            contractParties: getContractPartiesEndpoint('897946541354'),
            vehicleDetails: getVehicleDetailsEndpoint('897946541354'),
            additionalProducts: getAdditionalProductsEndpoint('897946541354'),
            amortizationDetails: getAmortizationDetailsEndpoint('897946541354'),
            unpaidDetails: getUnpaidDetailsEndpoint('897946541354'),
            totalEarlySettlement: getTotalEarlySettlementEndpoint('897946541354'),
        },
    },
    {
        contractType: ContractType.FINANCE_LEASE,
        contractStatus: ContractStatus.ACTIVE,
        contractNumber: '8768465567523',
        _encryptedContractNumber: '8768465567523encrypted',
        carInformation: 'VW BeepBeep 2.0BITDI SE 180 4DR 4MTN DSG',
        nextInstalmentAmount: 231.19,
        nextInstalmentDate: formatCpDate('2021-12-21').toCpDate(),
        totalAmount: 1040.5,
        licensePlateNumber: 'GEZ-12-1',
        _links: {
            financialDetails: getFinancialDetailsEndpoint('8768465567523'),
            contractParties: getContractPartiesEndpoint('8768465567523'),
            vehicleDetails: getVehicleDetailsEndpoint('8768465567523'),
            additionalProducts: getAdditionalProductsEndpoint('8768465567523'),
            amortizationDetails: getAmortizationDetailsEndpoint('8768465567523'),
        },
    },
    {
        contractType: ContractType.FINANCING,
        contractStatus: ContractStatus.ACTIVE,
        contractNumber: '440128795350',
        _encryptedContractNumber: '440128795350encrypted',
        carInformation: 'VW NEW YORK 2.0BITDI SE 180 4DR 4MTN DSG',
        nextInstalmentAmount: 885.19,
        nextInstalmentDate: formatCpDate('2019-12-21').toCpDate(),
        totalAmount: 607.19,
        licensePlateNumber: 'ABC-12-1',
        unpaidAmount: 853.9,
        unpaidInstalments: 6,
        productModality: 'CPC',
        beginDate: '2020-09-09T22:00:00.000Z',
        contractEndDate: formatCpDate('2024-02-01').toCpDate(), // Adjusted date

        _links: {
            financialDetails: getFinancialDetailsEndpoint('440128795350'),
            contractParties: getContractPartiesEndpoint('440128795350'),
            vehicleDetails: getVehicleDetailsEndpoint('440128795350'),
            additionalProducts: getAdditionalProductsEndpoint('440128795350'),
            amortizationDetails: getAmortizationDetailsEndpoint('440128795350'),
            unpaidDetails: getUnpaidDetailsEndpoint('440128795350'),
            totalEarlySettlement: getTotalEarlySettlementEndpoint('440128795350'),
            earlySettlementHeader: getEarlySettlementHeaderEndpoint('440128795350'),
            partialEarlySettlement: getPartialEarlySettlementEndpoint('440128795350'),
            amortizationTablePdfDownload: getAmortizationDetailsPdfDownloadEndpoint('440128795350'),
        },
    },
];

export const ContractsWithMultipleMixedEntries: ContractDataWithLinksAndEncryptedContractNumber[] = ContractsWithAllValues;

export const ContractsForDifferentBrandError = {
    status: 502,
    message: 'Existing customer has a contract. However, this customer does not have a contract for this brand: skoda',
    code: 'NO_CONTRACT_FOUND_FOR_GIVEN_BRAND',
};

export const OperatingLeaseContractExample: Contract = {
    contractNumber: '440128700002',
    _encryptedContractNumber: '440128700002encrypted',
    carInformation: 'VW CALIFORNIA BEETLE DSG',
    contractStatus: ContractStatus.ACTIVE,
    contractType: ContractType.OPERATING_LEASE,
    licensePlateNumber: 'A 0849 CS',
    isActiveContract: true,
    isExpiredContract: false,
    isFinanceLeaseContract: false,
    isFinancingContract: false,
    isOperatingLeaseContract: true,
    contractEndDate: formatCpDate('2022-12-31').toCpDate(),
};
