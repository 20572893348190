import {
    DATE_FORMAT,
    getRegistrationEndpoint,
    isValidCif,
    isValidNif,
    RegistrationData,
    UserRegistrationError,
} from '@cp-es/common';
import { formatCpDate, trimAllValues } from '@cp-shared-6/common-utilities';
import { parseErrorResponse } from '@cp-shared-6/frontend-integration';
import {
    CleaveInput,
    FocusFirstError,
    preventSubmit,
    Spinner,
    useAnalyticsActionTracker,
    useAnalyticsFormTracker,
    ValidatedCheckbox,
    ValidatedInput,
} from '@cp-shared-6/frontend-ui';
import { Button, ErrorMessage, Fieldset, Form, Layout } from '@vwfs-bronson/bronson-react';
import { CpDataApi } from 'cp-xhr';
import { Formik } from 'formik';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { RegistrationFormProps } from '../RegistrationFormProps';
import { PrivacyPolicyComponent } from '../ui/PrivacyPolicy';
import { maxIbanLength } from '../validations/validationSettings';
import { isEmpty } from 'lodash';
import { useTrackerOnBeforeUnload } from '../../../hooks';

export const PrivateRegistrationForm: React.FC<RegistrationFormProps> = ({
    showErrorCallback,
    successCallback,
    privacyPolicyContent,
}) => {
    const { t } = useTranslation('registration');
    const [showSpinner, setShowSpinner] = useState(false);
    const [showPrivacyPolicy, togglePrivacyPolicy] = useState(false);
    const {
        onTyping,
        onSubmit: trackOnSubmit,
        onError: trackOnError,
        onSuccess: trackOnSuccess,
    } = useAnalyticsFormTracker({
        startTyping: 'onStartTypingConfirmIdentity',
        confirm: 'onConfirmConfirmIdentiy',
        confirmError: 'onConfirmIdentiyAuthFailed',
        confirmSuccess: 'onConfirmIdentiyAuthSuccess',
    });

    const { onAction } = useAnalyticsActionTracker('onFormValidationErrorConfirmIdentity');
    const getErrors = (errors: { [k: string]: string | undefined }): string => Object.keys(errors).join(`, `);
    const initialListErrors = 'birthDate, iban, nif, acceptPrivacyPolicy';
    const setLastTouchedField = useTrackerOnBeforeUnload();

    const validationSchema = (): Yup.SchemaOf<object> => {
        return Yup.object().shape({
            birthDate: Yup.string()
                .required(t('error.birth-date.required'))
                .test('format', t('error.birth-date.format'), date => formatCpDate(date, DATE_FORMAT).isValid())
                .test('no future date', t('error.birth-date.no-future'), date =>
                    formatCpDate(date, DATE_FORMAT)
                        .toMoment()
                        .isSameOrBefore(formatCpDate().toMoment()),
                )
                .test('too young', t('error.birth-date.too-young'), date =>
                    formatCpDate(date, DATE_FORMAT)
                        .toMoment()
                        .isSameOrBefore(
                            formatCpDate()
                                .subtract(18, 'years')
                                .toMoment(),
                        ),
                )
                .test('too old', t('error.birth-date.too-old'), date =>
                    formatCpDate(date, DATE_FORMAT)
                        .toMoment()
                        .isSameOrAfter(
                            formatCpDate(
                                formatCpDate()
                                    .subtract(100, 'years')
                                    .format(DATE_FORMAT),
                                DATE_FORMAT,
                            ).toMoment(),
                        ),
                ),
            iban: Yup.string()
                .required(t('error.too-short-bank-account'))
                .matches(RegExp('^[0-9]*$'), t('error.too-short-bank-account'))
                .test('length', t('error.too-short-bank-account'), iban => !!iban && iban.length === maxIbanLength),
            nif: Yup.string()
                .trim()
                .required(t('error.nif.required'))
                .test('is valid cif', t('error.nif.is-valid-cif'), input => !isValidCif(input))
                .test('nif format', t('error.nif.format'), identification => isValidNif(identification)),
            acceptPrivacyPolicy: Yup.bool().oneOf([true], t('error.accept-privacy-policy-required')),
        });
    };

    const labels = {
        iban: {
            text: t('bank-account.label'),
            addon: t('bank-account.addon'),
        },
        birthDate: {
            text: t('birth-date.label'),
        },
        nif: {
            text: t('nif.label'),
            tooltip: t('nif.tooltip'),
        },
        acceptPrivacyPolicy: {
            text: t('accept-privacy-policy'),
            link: t('accept-privacy-policy-link'),
        },
    };

    return (
        <Formik
            initialValues={{
                iban: '',
                birthDate: '',
                nif: '',
                acceptPrivacyPolicy: false,
            }}
            validationSchema={validationSchema()}
            onSubmit={(values): void => {
                setShowSpinner(true);
                const requestBody: RegistrationData = {
                    personalId: values.nif,
                    ibanDigits: values.iban,
                    identificationDate: formatCpDate(values.birthDate, DATE_FORMAT).toCpDate(),
                };
                CpDataApi.post(getRegistrationEndpoint(), trimAllValues(requestBody))
                    .then(() => {
                        trackOnSuccess();
                        successCallback();
                    })
                    .catch(error => {
                        trackOnSubmit();
                        trackOnError();
                        showErrorCallback(parseErrorResponse<UserRegistrationError>(error).code);
                    })
                    .finally(() => setShowSpinner(false));
            }}
            data-testid={'privateRegistrationForm'}
        >
            {(formik): JSX.Element => (
                <Form
                    onSubmit={preventSubmit()}
                    onChange={(): void => {
                        !formik.values.birthDate && onTyping(formik.errors, formik.touched);
                    }}
                >
                    {showSpinner && <Spinner fullPage />}
                    <FocusFirstError />

                    <Fieldset>
                        <Fieldset.Row>
                            <Layout.Item default={'1/1'}>
                                <CleaveInput
                                    cleaveOptions={{
                                        numericOnly: true,
                                    }}
                                    maxLength={maxIbanLength}
                                    className={'iban-input'}
                                    label={labels.iban.text}
                                    name={'iban'}
                                    addonText={labels.iban.addon}
                                    reversed={true}
                                    inputMode={'numeric'}
                                    testId={'iban'}
                                    onFocus={(): void => setLastTouchedField(labels.iban.text)}
                                />
                            </Layout.Item>
                        </Fieldset.Row>

                        <Fieldset.Row>
                            <Layout.Item default={'1/2'} m={'1/1'}>
                                <CleaveInput
                                    cleaveOptions={{
                                        delimiter: '/',
                                        blocks: [2, 2, 4],
                                        numericOnly: true,
                                    }}
                                    inputMode={'numeric'}
                                    className={'birth-date-input'}
                                    label={labels.birthDate.text}
                                    name={'birthDate'}
                                    testId={'birthDate'}
                                    handleChange={(): void => {
                                        onTyping(formik.errors, formik.touched);
                                    }}
                                    onFocus={(): void => setLastTouchedField(labels.birthDate.text)}
                                />
                            </Layout.Item>
                            <Layout.Item default={'1/2'} m={'1/1'}>
                                <ValidatedInput
                                    label={labels.nif.text}
                                    tooltip={labels.nif.tooltip}
                                    name={'nif'}
                                    testId={'nif'}
                                    id={'nif'}
                                    onFocus={(): void => setLastTouchedField(labels.nif.text)}
                                />
                            </Layout.Item>
                        </Fieldset.Row>

                        <Fieldset.Row>
                            <Layout.Item default={'1/1'}>
                                <ValidatedCheckbox
                                    name={'acceptPrivacyPolicy'}
                                    testId={'acceptPrivacyPolicy'}
                                    label={
                                        <>
                                            {labels.acceptPrivacyPolicy.text}{' '}
                                            <Button
                                                onClick={(e: MouseEvent): void => {
                                                    e.preventDefault();
                                                    togglePrivacyPolicy(!showPrivacyPolicy);
                                                }}
                                                link={true}
                                            >
                                                {labels.acceptPrivacyPolicy.link}
                                            </Button>
                                        </>
                                    }
                                    onFocus={(): void =>
                                        setLastTouchedField(
                                            `${labels.acceptPrivacyPolicy.text} ${labels.acceptPrivacyPolicy.link}`,
                                        )
                                    }
                                />
                            </Layout.Item>
                        </Fieldset.Row>

                        {showPrivacyPolicy && <PrivacyPolicyComponent privacyPolicyContent={privacyPolicyContent} />}

                        <Layout.Item default={'1/1'}>
                            <Button
                                full={true}
                                onClick={async (): Promise<void> => {
                                    await formik.submitForm();
                                    if (!isEmpty(formik.errors) || isEmpty(formik.touched)) {
                                        const errorToString = getErrors(formik.errors).toString();
                                        if (!errorToString) {
                                            onAction(initialListErrors);
                                        } else onAction(getErrors(formik.errors));
                                    }
                                }}
                                testId={'submitButton'}
                            >
                                {t('translation:editableSectionNav.confirm')}
                            </Button>
                            {!formik.isValid && !formik.isSubmitting && formik.submitCount > 0 && (
                                <ErrorMessage>{t('error.not-all-steps-done')}</ErrorMessage>
                            )}
                        </Layout.Item>
                    </Fieldset>
                </Form>
            )}
        </Formik>
    );
};
