import { PostboxDocumentType as PostboxDocumentTypeShared } from '@cp-es/common';
import { PostboxDocument } from '@cp-es/common/build/apis/services/types/postbox-documents-types';

export type AllDocumentType = 'ALL';

export type PostboxDocumentType = PostboxDocumentTypeShared | AllDocumentType;

export const documentTypeTranslationMapping: Record<PostboxDocumentType, string> = {
    ALL: 'all',
    CARDIF_INSURANCE_POLICY: 'cardif-insurance-policy',
    CONSUMER_CREDIT_INFORMATION: 'consumer-credit-information',
    FINANCING_CONTRACT: 'financing-contract',
    INVOICE: 'invoice',
    LONG_DRIVE_SERVICE_CONTRACT: 'long-drive-service-contract',
    PARTICULAR_CONDITIONS: 'particular-conditions',
    SEPA_MANDATE: 'sepa-mandate',
    WELCOME_LETTER: 'welcome-letter',
};

export type AvailableDocument = {
    type: PostboxDocumentType;
    translation: string;
};

export const getListOfAvailableDocumentTypes = (postboxDocuments: PostboxDocument[]): AvailableDocument[] => {
    const listOfAvailableDocuments: AvailableDocument[] = [
        { type: 'ALL', translation: documentTypeTranslationMapping['ALL'] },
    ];
    postboxDocuments.forEach(document => {
        if (!listOfAvailableDocuments.find(o => o.type === document.type)) {
            listOfAvailableDocuments.push({
                type: document.type,
                translation: documentTypeTranslationMapping[document.type],
            });
        }
    });
    return listOfAvailableDocuments;
};
