import { DueDateChange, FetchMonthlyDueDateError } from '@cp-es/common';
import { CPDate, formatCpDate, WithDefaultMarketApiErrors } from '@cp-shared-6/common-utilities';
import { parseErrorResponse } from '@cp-shared-6/frontend-integration';
import {
    DataOverview,
    Notification,
    NotificationStatus,
    useAnalyticsActionTracker,
    withAutoScroll,
} from '@cp-shared-6/frontend-ui';
import { Button, ButtonContainer, Fieldset, Modal, RadioButton, RadioButtonGroup } from '@vwfs-bronson/bronson-react';
import { CpDataApi } from 'cp-xhr';
import { Formik, Form } from 'formik';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EditStatus } from '../EditStatus';

export type EditViewProps = {
    dueDate: string;
    changeMonthlyDueDateEndpoint: string;
    cancelEditing: () => void;
    finishEditing: (
        newEditStatus: WithDefaultMarketApiErrors<EditStatus>,
        selectedDueDate?: number,
        upcomingDueDate?: CPDate,
    ) => void;
    onEditFail: () => void;
};

export const EditView: React.FC<EditViewProps> = withAutoScroll(
    ({ dueDate, cancelEditing, finishEditing, changeMonthlyDueDateEndpoint, onEditFail }) => {
        const { onAction: onEditCancel } = useAnalyticsActionTracker('financialDetailsEditDueDateSectionCancel');
        const { onAction: onConfirmation } = useAnalyticsActionTracker(
            'financialDetailsEditDueDateSectionConfirmation',
        );
        const { onAction: onConfirmationCancel } = useAnalyticsActionTracker(
            'financialDetailsEditDueDateSectionConfirmationCancel',
        );

        const presetMonthlyDueDates = ['4', '9', '14', '21', '28'];

        const [selectedDueDate, setSelectedDueDate] = useState(dueDate);
        const [showConfirmationDialogue, setShowConfirmationDialogue] = useState(false);
        const openConfirmationDialogue = (): void => {
            const changeOccured: boolean = dueDate !== selectedDueDate;
            if (!changeOccured) {
                finishEditing('SAME_DATE_SELECTED');
            } else {
                setShowConfirmationDialogue(true);
            }
        };
        const closeConfirmationDialogue = (): void => setShowConfirmationDialogue(false);

        const { t } = useTranslation('financial-details');

        const initialValues = {
            monthlyPaymentDueDate: dueDate,
        };

        const onSubmit = (): void => {
            const requestBody: DueDateChange = {
                dueDate: selectedDueDate,
            };
            CpDataApi.put(changeMonthlyDueDateEndpoint, requestBody)
                .then(response => {
                    const dueDate = response.data;
                    if (response.status === 202) finishEditing('SUCCESS_PENDING');
                    else finishEditing('SUCCESS', Number(selectedDueDate), formatCpDate(dueDate).toCpDate());
                })
                .catch(error => {
                    onEditFail();
                    const errorCode = parseErrorResponse<FetchMonthlyDueDateError>(error).code;
                    switch (errorCode) {
                        case 'CANT_CHANGE_MORE_THAN_TWO_TIMES':
                        case 'NO_INSTALMENTS_REMAINING':
                            finishEditing(errorCode);
                            break;
                        default:
                            finishEditing('MARKET_API_DEFAULT_BUSINESS_ERROR');
                            break;
                    }
                });
        };

        return (
            <DataOverview title={t('monthlyDueDateSection.editView.title')} withoutCardEffect={true}>
                <p>{t('monthlyDueDateSection.editView.heading', { dueDate })}</p>
                <Notification
                    className="u-mb"
                    status={NotificationStatus.warning}
                    headline={t('monthlyDueDateSection.notification.onlyTwoChanges.headline')}
                    text={t('monthlyDueDateSection.notification.onlyTwoChanges.text')}
                />
                <Formik initialValues={initialValues} onSubmit={onSubmit}>
                    {(formik): JSX.Element => (
                        <>
                            <Form
                                onSubmit={(e): void => {
                                    e.preventDefault();
                                }}
                            >
                                <Fieldset>
                                    <Fieldset.Row>
                                        <RadioButtonGroup>
                                            {presetMonthlyDueDates.map((dueDateItem, index) => (
                                                <RadioButton
                                                    key={index}
                                                    testId={`cp-due-date-edit-view-radio-button-${dueDateItem}`}
                                                    name="monthlyPaymentDueDate"
                                                    value={dueDateItem}
                                                    defaultChecked={dueDate === dueDateItem}
                                                    onChange={(): void => {
                                                        setSelectedDueDate(dueDateItem);
                                                    }}
                                                >
                                                    <div
                                                        dangerouslySetInnerHTML={{
                                                            __html: t(
                                                                `monthlyDueDateSection.editView.presetMonthlyPaymentDays.${dueDateItem}`,
                                                            ),
                                                        }}
                                                    />
                                                </RadioButton>
                                            ))}
                                        </RadioButtonGroup>
                                    </Fieldset.Row>
                                </Fieldset>
                                <Fieldset>
                                    <Fieldset.Row>
                                        <ButtonContainer nav>
                                            <Button
                                                secondary
                                                onClick={(): void => {
                                                    onEditCancel();
                                                    cancelEditing();
                                                }}
                                                testId="cancelButton"
                                            >
                                                {t('translation:editableSectionNav.cancel')}
                                            </Button>
                                            <Button
                                                onClick={(): void => {
                                                    onConfirmation();
                                                    openConfirmationDialogue();
                                                }}
                                                testId="submitButton"
                                            >
                                                {t('monthlyDueDateSection.editView.button.confirm')}
                                            </Button>
                                        </ButtonContainer>
                                    </Fieldset.Row>
                                </Fieldset>
                            </Form>
                            <Modal
                                shown={showConfirmationDialogue}
                                title={t('monthlyDueDateSection.editView.confirmationDialogue.headline')}
                                buttonConfirmText={t(
                                    'monthlyDueDateSection.editView.confirmationDialogue.confirmButton',
                                )}
                                buttonCancelText={t('translation:editableSectionNav.cancel')}
                                onConfirm={(): void => {
                                    closeConfirmationDialogue();
                                    formik.handleSubmit();
                                }}
                                onCancel={(): void => {
                                    onConfirmationCancel();
                                    closeConfirmationDialogue();
                                }}
                                onClickOutside={(): void => {
                                    onConfirmationCancel();
                                    closeConfirmationDialogue();
                                }}
                                onClose={(): void => {
                                    onConfirmationCancel();
                                    closeConfirmationDialogue();
                                }}
                                testId="confirmModal"
                            >
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: t('monthlyDueDateSection.editView.confirmationDialogue.subHeading'),
                                    }}
                                />
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: t('monthlyDueDateSection.editView.confirmationDialogue.text'),
                                    }}
                                />
                            </Modal>
                        </>
                    )}
                </Formik>
                <Notification
                    className="u-mt"
                    status={NotificationStatus.info}
                    headline={t('monthlyDueDateSection.notification.selectedDueDate.headline', {
                        selectedDueDate,
                    })}
                    text={t('monthlyDueDateSection.notification.selectedDueDate.text')}
                />
            </DataOverview>
        );
    },
    'DueDateSectionEditView',
);
