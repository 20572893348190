import React from 'react';
import { useCmsContent } from '@cp-shared-6/frontend-integration';
import {
    IconFooter as IconFooterShared,
    IconFooterLoadingPlaceholder,
    useAnalyticsActionTracker,
} from '@cp-shared-6/frontend-ui';
import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import { RootState } from 'store/rootReducer';
import { fetchIconFooter } from './IconFooterSlice';
import { useLocation } from 'react-router-dom';
import { featureAppLicensesPagePath } from '../navigation/paths';

const pagesWithoutIconFooter = [featureAppLicensesPagePath()];

const IconFooterWithHandlers = withLoadingAndNoConnectionHandler(IconFooterShared);

export const IconFooter: React.FC = () => {
    const { onAction } = useAnalyticsActionTracker('onClickEmailLink');
    const { cmsContent: iconFooter, isLoading, loadingError } = useCmsContent(fetchIconFooter, (state: RootState) => {
        return state.content.iconFooter;
    });
    const location = useLocation();
    const currentPageName = location.pathname.split('/')[1];

    const { items, title } = iconFooter || {};

    const currentPathName = useLocation().pathname;

    const hidden = pagesWithoutIconFooter.includes(currentPathName);

    if (hidden) {
        return null;
    }

    return (
        <IconFooterWithHandlers
            hasError={!!loadingError}
            title={title}
            isLoading={isLoading}
            items={items || []}
            trackingFunction={onAction}
            currentPageName={currentPageName}
            loadingPlaceholder={<IconFooterLoadingPlaceholder />}
        />
    );
};
