import { IbanChange as IbanValidation, IbanError } from '@cp-es/common';
import {
    CleaveInput,
    DataOverview,
    UiBlockingSpinner,
    useAnalyticsActionTracker,
    withAutoScroll,
} from '@cp-shared-6/frontend-ui';
import { Button, ButtonContainer, Fieldset, Layout } from '@vwfs-bronson/bronson-react';
import { CpDataApi } from 'cp-xhr';
import { Formik, Form } from 'formik';
import { useTranslationWithFormatting } from 'localization/useTranslationWithFormatting';
import React, { useState } from 'react';
import * as Yup from 'yup';
import { isEmpty } from 'lodash';
import { IbanValidationError } from './IbanValidationError';
import { NotificationForLastValidation } from './NotificationForLastValidation';
import { ValidatedIban } from './ValidatedIban';
import { parseErrorResponse } from '@cp-shared-6/frontend-integration';
import { WithDefaultBusinessMarketApiError } from '@cp-shared-6/common-utilities';

export type IbanValidationViewProps = {
    validateIbanEndpoint: string;
    previousIban: string;
    onValidIban: (validateIban: ValidatedIban) => void;
    toPreviousView: () => void;
    isFirstView?: boolean;
    forOperatingLease?: boolean;
    onEditFail: () => void;
};

type FormType = {
    iban: string;
};

export const IbanValidationView: React.FC<IbanValidationViewProps> = withAutoScroll(
    ({
        previousIban,
        validateIbanEndpoint,
        toPreviousView,
        onValidIban,
        isFirstView,
        forOperatingLease,
        onEditFail,
    }) => {
        const { t } = useTranslationWithFormatting('financial-details');
        const [lastValidationError, setLastValidationError] = useState<
            WithDefaultBusinessMarketApiError<IbanValidationError>
        >('NO_ERROR');
        const [isValidating, setIsValidating] = useState(false);
        const [lastIbanInput, setLastIbanInput] = useState('');
        const { onAction: onStartTyping } = useAnalyticsActionTracker('financialDetailsEditIbanSectionStartTyping');
        const { onAction: onEditCancel } = useAnalyticsActionTracker('financialDetailsEditIbanSectionCancel');
        const { onAction: onValidationError } = useAnalyticsActionTracker(
            'financialDetailsEditIbanSectionValidationError',
        );

        function stripWhitespaces(iban: string): string {
            return iban.replace(/\s/g, '');
        }

        const ibanPrefix = t('ibanSection.editView.ibanValidationView.iban.prefix');
        const previousIbanFormatted = stripWhitespaces(previousIban).replace(ibanPrefix, '');

        const initialValues: FormType = {
            iban: lastIbanInput,
        };

        const validationSchema = Yup.object().shape({
            iban: Yup.string()
                // The required does not work as with Cleave the initial value becomes undefined
                .test(
                    'required',
                    t('ibanSection.editView.ibanValidationView.iban.validations.required'),
                    iban => !!iban,
                )
                .test(
                    'length',
                    t('ibanSection.editView.ibanValidationView.iban.validations.length'),
                    iban => !!iban && stripWhitespaces(iban).length === 22,
                )
                .test(
                    'notSameAsPrevious',
                    t('ibanSection.editView.ibanValidationView.iban.validations.sameAsPrevious'),
                    iban => !!iban && stripWhitespaces(iban) !== previousIbanFormatted,
                ),
        });

        const onSubmit = (values: FormType): void => {
            const ibanToSubmit = `${t('ibanSection.editView.ibanValidationView.iban.prefix')}${values.iban}`;
            setLastIbanInput(values.iban);
            setIsValidating(true);
            const requestBody: IbanValidation = {
                iban: ibanToSubmit,
            };
            CpDataApi.put(validateIbanEndpoint, requestBody)
                .then(response => {
                    setIsValidating(false);
                    setLastValidationError('NO_ERROR');
                    onValidIban(Object.assign({ iban: ibanToSubmit }, response.data));
                })
                .catch(error => {
                    onEditFail();
                    setIsValidating(false);
                    const errorCode = parseErrorResponse<WithDefaultBusinessMarketApiError<IbanError>>(error).code;
                    switch (errorCode) {
                        case 'MARKET_API_DEFAULT_BUSINESS_ERROR':
                        case 'NO_LOAN_PAYMENT_HOLDER':
                        case 'SAME_BANK_ACCOUNT':
                        case 'WRONG_BANK_CODE':
                            setLastValidationError(errorCode);
                            break;
                        default:
                            setLastValidationError('MARKET_API_DEFAULT_BUSINESS_ERROR');
                            break;
                    }
                });
        };

        return (
            <DataOverview title={t('ibanSection.editView.title')} withoutCardEffect={true}>
                <UiBlockingSpinner isBlocking={isValidating}>
                    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
                        {(formik): JSX.Element => (
                            <Form
                                onSubmit={(e): void => {
                                    e.preventDefault();
                                }}
                                onChange={onStartTyping}
                            >
                                <Fieldset>
                                    <Fieldset.Row>
                                        <Layout>
                                            <Layout.Item default="1/2" s="1/1">
                                                <CleaveInput
                                                    cleaveOptions={{
                                                        numericOnly: true,
                                                        blocks: [2, 4, 4, 4, 4, 4],
                                                    }}
                                                    className={'iban-input'}
                                                    label={t('ibanSection.editView.ibanValidationView.iban.label')}
                                                    name={'iban'}
                                                    testId="iban"
                                                    addonText={t('ibanSection.editView.ibanValidationView.iban.prefix')}
                                                    reversed
                                                    tooltip={t('ibanSection.editView.ibanValidationView.iban.tooltip')}
                                                    inputMode="numeric"
                                                />
                                            </Layout.Item>
                                        </Layout>
                                    </Fieldset.Row>
                                </Fieldset>
                                {lastValidationError !== 'NO_ERROR' && (
                                    <Fieldset>
                                        <NotificationForLastValidation
                                            lastValidationError={lastValidationError}
                                            forOperatingLease={forOperatingLease}
                                        />
                                    </Fieldset>
                                )}
                                <Fieldset>
                                    <Fieldset.Row>
                                        <ButtonContainer nav>
                                            <Button
                                                secondary
                                                onClick={(): void => {
                                                    onEditCancel();
                                                    toPreviousView();
                                                }}
                                                testId="cancelButton"
                                            >
                                                {isFirstView
                                                    ? t('translation:editableSectionNav.cancel')
                                                    : t('translation:editableSectionNav.back')}
                                            </Button>
                                            <Button
                                                onClick={(): void => {
                                                    if (isEmpty(formik.touched) && isEmpty(formik.errors)) {
                                                        onValidationError({ iban: 'Iban' });
                                                    }
                                                    if (!isEmpty(formik.errors)) {
                                                        onValidationError(formik.errors);
                                                    }
                                                    formik.handleSubmit();
                                                }}
                                                testId="submitButton"
                                            >
                                                {t('translation:editableSectionNav.continue')}
                                            </Button>
                                        </ButtonContainer>
                                    </Fieldset.Row>
                                </Fieldset>
                            </Form>
                        )}
                    </Formik>
                </UiBlockingSpinner>
            </DataOverview>
        );
    },
    'IbanValidationView',
);
