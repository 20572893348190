import React, { useState } from 'react';
import { ContentSection, Layout } from '@vwfs-bronson/bronson-react';
import { useTranslation } from 'react-i18next';
import { ContractsOverview } from 'components/contracts/ContractsOverview';
import { DashboardMarketingCard } from 'components/dashboard-marketing-card/DashboardMarketingCard';
import { CustomerHappinessIndex } from 'components/customer-happiness-index/CustomerHappinessIndex';
import { Greeting } from 'components/greeting/Greeting';
import { PageHeader } from 'components/page-header/PageHeader';
import { useContracts } from 'components/contracts/useContracts';
import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import { DigitalRenewalSuccessNotification } from 'components/notifications/digital-renewal/success-notification';
import { usePersonalDetails } from '../components/my-profile/usePersonalDetails';
import { DigitalRenewalEntryNotification } from 'components/digital-renewal/DigitalRenewalEntryNotification';
import { isDigitalRenewalEnabled } from '../config';

const DashboardMarketingCardWithContractsAndHandlers = withLoadingAndNoConnectionHandler(DashboardMarketingCard);

export const DashboardPage: React.FC = () => {
    const { t } = useTranslation('dashboard');
    const { data: contracts, isLoading: isLoadingContracts } = useContracts();
    const { data: personalDetails } = usePersonalDetails();
    const [hasMarketingCard, setHasMarketingCard] = useState(false);
    const isContactEmailDefined = !!personalDetails?.contact?.email;

    return (
        <ContentSection pageWrapSize="medium">
            <PageHeader title={t('headline')} />
            <Greeting />

            <Layout>
                <Layout.Item default={hasMarketingCard ? '2/3' : '1/1'} m={'1/1'}>
                    <DigitalRenewalSuccessNotification />
                    {isDigitalRenewalEnabled ? <DigitalRenewalEntryNotification /> : <></>}
                    <ContractsOverview
                        contracts={contracts}
                        isLoadingContractsOverview={isLoadingContracts}
                        contractsOverviewLoadingError={!!isLoadingContracts}
                    />
                </Layout.Item>
                <Layout.Item default={'1/3'} m={'1/1'}>
                    <DashboardMarketingCardWithContractsAndHandlers
                        contracts={contracts}
                        isLoading={isLoadingContracts}
                        hasError={!!isLoadingContracts}
                        setHasMarketingCard={setHasMarketingCard}
                    />
                </Layout.Item>
                <Layout.Item default={hasMarketingCard ? '2/3' : '1/1'} m={'1/1'}>
                    {isContactEmailDefined && <CustomerHappinessIndex />}
                </Layout.Item>
            </Layout>
        </ContentSection>
    );
};
