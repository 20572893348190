import { ContractType, Contract, FinanceContract, OperatingLeaseContract } from '@cp-es/common';
import moment from 'moment';

export const isEligibleFinancingContract = (contract: Contract): boolean => {
    if (
        contract.contractType === ContractType.FINANCING &&
        ['CP', 'CPC', 'AC', 'AF', 'C5'].includes(contract.productModality ?? '')
    ) {
        const currentDate = moment();
        const contractEndDate = moment(contract.contractEndDate);

        if (currentDate.isSameOrAfter(contractEndDate)) {
            return false;
        }
        return true;
    }
    return false;
};

export const isEligibleLeasing = (contract: Contract): boolean => {
    if (contract.contractType === ContractType.OPERATING_LEASE && contract.productModality === 'AL') {
        const currentDate = new Date();
        if (contract.contractEndDate) {
            const contractEndDate = new Date(contract?.contractEndDate);
            if (currentDate >= contractEndDate) {
                return false;
            }
            const remainingMonths =
                (contractEndDate.getFullYear() - currentDate.getFullYear()) * 12 +
                contractEndDate.getMonth() -
                currentDate.getMonth();

            if (remainingMonths <= 1 || remainingMonths <= 2 || remainingMonths <= 5) {
                return true;
            }
        }
    }
    return false;
};

export const checkTheMonthsLeft = (
    contract: Contract | FinanceContract | OperatingLeaseContract,
    monthsLeft: number,
): boolean => {
    const currentDate = new Date();
    if (contract.contractEndDate) {
        const contractEndDate = new Date(contract.contractEndDate);

        const differenceInMillis = contractEndDate.getTime() - currentDate.getTime();

        const differenceInDays = differenceInMillis / (1000 * 60 * 60 * 24);

        const differenceInMonths = Math.ceil(differenceInDays / 30);
        if (differenceInMonths <= monthsLeft) {
            return true;
        }
    }
    return false;
};

export const calculateMonthsLeft = (
    contract: Contract | FinanceContract | OperatingLeaseContract,
): number | undefined => {
    if (contract.contractEndDate) {
        const currentDate = new Date();
        const contractEndDate = new Date(contract.contractEndDate);
        return moment(contractEndDate).diff(currentDate, 'months', true);
    }
};
