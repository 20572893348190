import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store/rootReducer';
import { useEffect } from 'react';
import { BrandHeader, Contract, EncryptedContractNumber } from '@cp-es/common';
import { fetchVehicleDetails } from 'components/contracts/vehicle-details';
import { shouldRefetch } from 'components/dashboard-marketing-card/additional-products/useAdditionalProducts';

function capitalizeFirstLetter(string: string): string {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function useBrandName(contract?: Contract & EncryptedContractNumber): string | null {
    const dispatch = useDispatch();
    const memorizedExistingDataSelector = createSelector(
        (state: RootState) => state.vehicleDetails,
        vehicleDetails => vehicleDetails,
    );

    const existingData = useSelector(memorizedExistingDataSelector);

    useEffect(() => {
        if (contract) {
            const data = existingData[contract.contractNumber];
            if (!data || (shouldRefetch(data) && contract._links)) {
                dispatch(fetchVehicleDetails(contract.contractNumber, contract._links?.vehicleDetails));
            }
        }
    }, [contract, dispatch]);

    if (existingData && contract) {
        const contractData = contract && existingData[contract.contractNumber];
        const dataExists = contractData && contractData.data && contractData?.data?.length > 0;

        if (dataExists && contractData.data) {
            const brandHeader = contractData.data[0].brandHeader;
            if (brandHeader) {
                if (brandHeader === BrandHeader.VW) {
                    return brandHeader.toUpperCase();
                }
                return capitalizeFirstLetter(brandHeader);
            }
        }
    }
    return null;
}
