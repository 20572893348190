import { createGetContractBasedDataFetchSlice } from '@cp-shared-6/frontend-integration';
import { TotalEarlySettlement, EarlySettlementError } from '@cp-es/common';
import { CpDataApi } from 'cp-xhr';

const { reducer, fetchData } = createGetContractBasedDataFetchSlice<TotalEarlySettlement, EarlySettlementError>({
    dataName: 'totalEarlySettlement',
    fetchCallback(link: string) {
        return CpDataApi.get(link).then(response => response.data);
    },
});

export default reducer;
export const fetchTotalEarlySettlement = fetchData;
