import { PostboxDocument } from '@cp-es/common';
import { formatCpDate } from '@cp-shared-6/common-utilities';

export type GroupedDocuments = {
    [yearLabel: string]: PostboxDocument[];
};

export const getSortedPostboxDocuments = (postboxDocuments: PostboxDocument[]): PostboxDocument[] => {
    const postboxDocumentCopy: PostboxDocument[] = Object.assign([], postboxDocuments);
    return postboxDocumentCopy.sort((a, b) => formatCpDate(a.date).unix() - formatCpDate(b.date).unix());
};

/**
 * Groups all postbox documents by the document retrieval year.
 * @param postboxDocuments
 */
export const getGroupedDocuments = (postboxDocuments: PostboxDocument[]): GroupedDocuments => {
    const result: GroupedDocuments = {};
    postboxDocuments.forEach(document => {
        const year = formatCpDate(document.date)
            .toMoment()
            .year()
            .toString();
        const yearGroupExists = result[year] !== undefined;
        if (yearGroupExists) {
            result[year].push(document);
        } else {
            result[year] = [document];
        }
    });
    return result;
};

export const getSortedGroupKeys = (groupedDocuments: GroupedDocuments): string[] => {
    const result: string[] = Object.keys(groupedDocuments)
        .sort((a, b) => Number.parseInt(a) - Number.parseInt(b))
        .reverse();
    return result;
};
