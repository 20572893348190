import { PrivacyPolicy } from '@cp-es/common';
import { Card, Layout } from '@vwfs-bronson/bronson-react';
import React from 'react';
import { PrivacyPolicyTable } from '../../privacy-policy/PrivacyPolicyTable';

export type PrivacyPolicyComponentProps = {
    privacyPolicyContent: PrivacyPolicy | undefined;
};

export const PrivacyPolicyComponent: React.FC<PrivacyPolicyComponentProps> = ({ privacyPolicyContent }) => {
    if (!privacyPolicyContent) {
        return null;
    }

    const { title, content } = privacyPolicyContent;

    return (
        <Layout.Item default={'1/1'}>
            <Card element={'article'} scrollable={true}>
                <h3>{title}</h3>
                <PrivacyPolicyTable privacyPolicyContent={privacyPolicyContent} />
                <div className={'u-text-left'} dangerouslySetInnerHTML={{ __html: content }} />
            </Card>
            <br />
        </Layout.Item>
    );
};
