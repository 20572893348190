import { LandingPageMarketingCardDto, getLandingPageMarketingCardsEndpoint } from '@cp-es/common';
import { createCmsContentSlice } from 'cms-integration';

const { reducer, fetchContent } = createCmsContentSlice<Array<LandingPageMarketingCardDto>>({
    contentName: 'landingPageMarketingCards',
    contentEndpoint: getLandingPageMarketingCardsEndpoint,
});

export default reducer;
export const fetchLandingPageMarketingCards = fetchContent;
