import { RequestCertificateValidatorError } from '../types';
import { REQUEST_CERTIFICATE_TYPES } from '../../apis';

export const defaultRequestCertificateValidatorError: RequestCertificateValidatorError = {
    certificateType: {
        required: 'Certification type is required.',
        oneOf: `It must be one of: ${REQUEST_CERTIFICATE_TYPES.join(', ')}`,
    },
    requestDate: { required: 'For this type request date is required.', invalid: 'Date is invalid.' },
};
