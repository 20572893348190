import { ScrollToTopOnNavigation } from '@cp-shared-6/frontend-ui';
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import {
    amortizationTablePath,
    changeAddressDetailsPath,
    changeContactDetailsPath,
    changeIbanPath,
    changeInvoiceConsentPath,
    changeMonthlyDueDatePath,
    contractRecalculation,
    cookiePolicyPath,
    dashboardPagePath,
    earlySettlementPagePath,
    faqPagePath,
    featureAppLicensesPagePath,
    identityBrokerErrorPagePath,
    invitationPagePath,
    landingPagePath,
    legalNoticePath,
    myProfilePagePath,
    myRequestPagePath,
    notAuthorizedPagePath,
    notFoundPagePath,
    partialEarlySettlementPagePath,
    paymentErrorPath,
    paymentSuccessPath,
    logoutPath,
    postboxPagePath,
    privacyPolicyPath,
    registrationPagePath,
    requestCertificatePath,
    requestSubragationPagePath,
    requestVehicleReturnPagePath,
    requestVehiclePurchasePagePath,
    totalEarlySettlementContractSelectorPagePath,
    totalEarlySettlementFutureQuotePath,
    totalEarlySettlementPagePath,
    unpaidDetailsPath,
    earlySettlementLeasingPagePath,
    digitalRenewalPagePath,
    digitalRenewalRefinanceOrPaymentPagePath,
    digitalRenewalReturnOfTheCarPath,
    digitalRenewalJointContractPagePath,
} from './components/navigation/paths';
import {
    AmortizationTablePage,
    CookiePolicyPage,
    DashboardPage,
    EarlySettlementPage,
    FaqPage,
    IdentityBrokerErrorPage,
    LandingPage,
    LegalNoticePage,
    MyProfilePage,
    MyRequestPage,
    NotAuthorizedPage,
    NotFoundPage,
    PaymentFeedbackPage,
    PostboxPage,
    PrivacyPolicyPage,
    RegistrationPage,
    RequestCertificatePage,
    TotalEarlySettlementPage,
    UnpaidDetailsPage,
    VehicleReturnPage,
    LogoutPage,
    DigitalRenewalPage,
    DigitalRenewalRefinanceOrPaymentPage,
    DigitalRenewalReturnOfTheCarPage,
    DigitalRenewalJointContractPage,
} from './pages';
import { MasterPage } from './pages/MasterPage';

import { AuthenticatedRoute, AuthorizedRoute } from './auth';
import { FeatureAppLicensesPage } from './pages/FeatureAppLicensesPage';
import { InvitationHandlerPage } from './pages/InvitationHandlerPage';
import { PartialEarlySettlementPage } from './pages/PartialEarlySettlementPage';
import { RequestSubrogationPage } from './pages/RequestSubrogationPage';
import { RequestVehiclePurchasePage } from 'pages/RequestVehiclePurchasePage';
import { Providers } from './Providers';
import { RedirectWithParams } from './utils/redirectWithParams';
import { ContractRecalculationPage } from 'pages/ContractRecalculationPage';
import { EarlySettlementLeasingPage } from 'pages/early-settlement-leasing/EarlySettlementLeasingPage';

const scrollToExceptions = [
    { from: /\S*/, to: changeContactDetailsPath() },
    { from: /\S*/, to: changeInvoiceConsentPath() },
    { from: /\S*/, to: changeAddressDetailsPath() },
    { from: changeContactDetailsPath(), to: myProfilePagePath() },
    { from: changeInvoiceConsentPath(), to: myProfilePagePath() },
    { from: changeAddressDetailsPath(), to: myProfilePagePath() },
    { from: /\S*/, to: changeMonthlyDueDatePath('[\\w-]+') },
    { from: changeMonthlyDueDatePath('[\\w-]+'), to: dashboardPagePath() },
    { from: /\S*/, to: new RegExp(changeIbanPath('[\\w-]+')) },
    { from: new RegExp(changeIbanPath('[\\w-]+')), to: dashboardPagePath() },
    {
        from: /\S*/,
        to: new RegExp(totalEarlySettlementFutureQuotePath('[\\w-]+')),
    },
    {
        from: new RegExp(totalEarlySettlementFutureQuotePath('[\\w-]+')),
        to: totalEarlySettlementContractSelectorPagePath(),
    },
];

const App: React.FC = () => (
    <div className="App">
        <Providers>
            <ScrollToTopOnNavigation exceptions={scrollToExceptions} />
            <MasterPage>
                <Switch>
                    <Route exact path={landingPagePath()}>
                        <LandingPage />
                    </Route>
                    <Route path={logoutPath()}>
                        <LogoutPage />
                    </Route>
                    <Route path={faqPagePath()}>
                        <FaqPage />
                    </Route>
                    <Route path={faqPagePath()}>
                        <FaqPage />
                    </Route>
                    <Route path={cookiePolicyPath()}>
                        <CookiePolicyPage />
                    </Route>
                    <Route path={legalNoticePath()}>
                        <LegalNoticePage />
                    </Route>
                    <Route path={privacyPolicyPath()}>
                        <PrivacyPolicyPage />
                    </Route>
                    <Route path={featureAppLicensesPagePath()}>
                        <FeatureAppLicensesPage />
                    </Route>
                    <Route path={invitationPagePath()}>
                        <InvitationHandlerPage />
                    </Route>
                    <AuthorizedRoute path={digitalRenewalPagePath()}>
                        <DigitalRenewalPage />
                    </AuthorizedRoute>
                    <AuthorizedRoute exact path={digitalRenewalRefinanceOrPaymentPagePath()}>
                        <DigitalRenewalRefinanceOrPaymentPage />
                    </AuthorizedRoute>
                    <AuthorizedRoute exact path={digitalRenewalReturnOfTheCarPath()}>
                        <DigitalRenewalReturnOfTheCarPage />
                    </AuthorizedRoute>
                    <AuthorizedRoute exact path={digitalRenewalJointContractPagePath()}>
                        <DigitalRenewalJointContractPage />
                    </AuthorizedRoute>
                    <AuthorizedRoute path={dashboardPagePath()}>
                        <DashboardPage />
                    </AuthorizedRoute>
                    <AuthorizedRoute path={myProfilePagePath()}>
                        <MyProfilePage />
                    </AuthorizedRoute>
                    <AuthorizedRoute path={earlySettlementLeasingPagePath()}>
                        <EarlySettlementLeasingPage />
                    </AuthorizedRoute>
                    <AuthorizedRoute path={totalEarlySettlementPagePath()}>
                        <TotalEarlySettlementPage />
                    </AuthorizedRoute>
                    <AuthorizedRoute path={totalEarlySettlementContractSelectorPagePath()}>
                        <TotalEarlySettlementPage />
                    </AuthorizedRoute>
                    <AuthorizedRoute path={requestCertificatePath()}>
                        <RequestCertificatePage />
                    </AuthorizedRoute>
                    <AuthorizedRoute path={contractRecalculation()}>
                        <ContractRecalculationPage />
                    </AuthorizedRoute>
                    <AuthorizedRoute path={amortizationTablePath()}>
                        <AmortizationTablePage />
                    </AuthorizedRoute>
                    <AuthorizedRoute path={unpaidDetailsPath()}>
                        <UnpaidDetailsPage />
                    </AuthorizedRoute>
                    <AuthorizedRoute path={paymentSuccessPath()}>
                        <PaymentFeedbackPage />
                    </AuthorizedRoute>
                    <AuthorizedRoute path={paymentErrorPath()}>
                        <PaymentFeedbackPage asErrorFeedback />
                    </AuthorizedRoute>
                    <AuthorizedRoute path={postboxPagePath()}>
                        <PostboxPage />
                    </AuthorizedRoute>
                    <AuthorizedRoute path={earlySettlementPagePath()}>
                        <EarlySettlementPage />
                    </AuthorizedRoute>
                    <AuthorizedRoute path={partialEarlySettlementPagePath()}>
                        <PartialEarlySettlementPage />
                    </AuthorizedRoute>
                    <AuthorizedRoute path={myRequestPagePath()}>
                        <MyRequestPage />
                    </AuthorizedRoute>
                    <AuthorizedRoute path={requestSubragationPagePath()}>
                        <RequestSubrogationPage />
                    </AuthorizedRoute>
                    <AuthorizedRoute path={requestVehicleReturnPagePath()}>
                        <VehicleReturnPage />
                    </AuthorizedRoute>
                    <AuthorizedRoute path={requestVehiclePurchasePagePath()}>
                        <RequestVehiclePurchasePage />
                    </AuthorizedRoute>
                    <AuthenticatedRoute path={registrationPagePath()}>
                        <RegistrationPage />
                    </AuthenticatedRoute>
                    <Route path={notAuthorizedPagePath()}>
                        <NotAuthorizedPage />
                    </Route>
                    <Route path={notFoundPagePath()}>
                        <NotFoundPage />
                    </Route>
                    <Route path={identityBrokerErrorPagePath()}>
                        <IdentityBrokerErrorPage />
                    </Route>
                    <Route path="*">
                        <RedirectWithParams to={notFoundPagePath()} />
                    </Route>
                </Switch>
            </MasterPage>
        </Providers>
    </div>
);
export default App;
