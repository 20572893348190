import { OpenRequestGeneralCategoriesSalesforce, SelectItemLabelValueCategories } from '@cp-es/common';
import { ValidatedSelect, ValidatedSelectItem } from '@cp-shared-6/frontend-ui';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const generalRequestCategories: SelectItemLabelValueCategories[] = [
    ['other-issues-financing', OpenRequestGeneralCategoriesSalesforce.OTHER_ISSUES_FINANCING],
    ['other-issues-operative-leasing', OpenRequestGeneralCategoriesSalesforce.OTHER_ISSUES_OPERATIVE_LEASING],
];
export const GeneralRequestDropdown: React.FC<{ name: string }> = ({ name }) => {
    const { t } = useTranslation('my-request');

    const validatedSelectItems: ValidatedSelectItem[] = generalRequestCategories.map((category, index) => {
        const categoryEntryText = t(`form.general-request-categories.${category[0]}`);
        return {
            label: categoryEntryText,
            value: category[1],
            key: index,
        };
    });

    return (
        <>
            <ValidatedSelect
                label={t('form.category.headline')}
                name={name}
                selectItems={validatedSelectItems}
                testId={`general-${name}`}
                placeholder={t('form.category.placeholder')}
                disablePlaceholder={true}
            />
        </>
    );
};
