export const sanitize = (str: string): string => {
    // Ensure uppercase and remove whitespace ang hyphens
    return str
        .toUpperCase()
        .replace(/\s/g, '')
        .replace(/-/g, '');
};

export const onSanitize = (fn: (str: string) => boolean) => (str?: string | null): boolean =>
    str ? fn(sanitize(str)) : false;
