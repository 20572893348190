import React from 'react';
import { FinanceFinancialDetails } from '@cp-es/common';
import { ConsultView } from './consult-view/ConsultView';
import { IbanSection as IbanSectionShared } from '../../iban-section/IbanSection';

export const IbanSection: React.FC<{
    contractId: string;
    details: FinanceFinancialDetails;
    encryptedContractId: string;
}> = ({ contractId, details, encryptedContractId }) => (
    <IbanSectionShared
        encryptedContractId={encryptedContractId}
        contractId={contractId}
        details={details}
        consultView={ConsultView}
        forOperatingLease={false}
    />
);
