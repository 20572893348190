import React from 'react';
import { FinanceFinancialDetails } from '@cp-es/common';
import { TranslationFormat, useTranslationWithFormatting } from 'localization/useTranslationWithFormatting';
import { DataOverview, DefinitionListHorizontal, DefinitionListItem } from '@cp-shared-6/frontend-ui';

export const BaseSection: React.FC<{ details: FinanceFinancialDetails }> = ({ details }) => {
    const { t, f } = useTranslationWithFormatting('financial-details');

    const financeBaseSectionItemList: DefinitionListItem[] = [
        {
            label: t('baseSection.startDate'),
            value: f(details.startDate, TranslationFormat.DATE),
        },
        {
            label: t('baseSection.endDate'),
            value: f(details.endDate, TranslationFormat.DATE),
        },
        {
            label: t('baseSection.pendingCapital.label'),
            value: f(details.pendingCapital, TranslationFormat.CURRENCY),
            tooltip: t('baseSection.pendingCapital.tooltip'),
        },
        {
            label: t('baseSection.amortizedCapital'),
            value: f(details.amortizedCapital, TranslationFormat.CURRENCY),
        },
        {
            label: t('baseSection.nominalAmount.label'),
            value: f(details.nominalAmount, TranslationFormat.CURRENCY),
        },
    ].filter(item => !!item.value);

    return (
        <DataOverview title={t('baseSection.title')} withoutCardEffect={true}>
            <DefinitionListHorizontal list={financeBaseSectionItemList} />
        </DataOverview>
    );
};
