import { PostboxDocument } from '@cp-es/common';
import { TFunction } from 'i18next';

import { PostboxDocumentType } from './DocumentType';
import { AllTimeframes, Timeframe, timeframeMomentMapping } from './Timeframe';

const filterDocumentsByDocumentType = (
    documents: PostboxDocument[],
    filter: PostboxDocumentType,
): PostboxDocument[] => {
    if (filter === 'ALL') return documents;
    return documents.filter(document => document.type === filter);
};

const filterDocumentsByContract = (documents: PostboxDocument[], filter: string): PostboxDocument[] => {
    if (filter === 'ALL') return documents;

    return documents.filter(document => document.operationCode === filter);
};

const filterDocumentsByTimeframe = (
    documents: PostboxDocument[],
    filter: Timeframe | AllTimeframes,
): PostboxDocument[] => {
    if (filter === 'ALL') return documents;
    const timeframeMomentDate = timeframeMomentMapping()[filter];
    return documents.filter(document => document.date >= timeframeMomentDate);
};

export const getFilteredDocuments = (
    documents: PostboxDocument[],
    documentTypeFilter: PostboxDocumentType,
    contractFilter: string,
    timeframeFilter: Timeframe | AllTimeframes,
): PostboxDocument[] => {
    const documentsCopy = [...documents];
    const documentTypeFilteredDocuments = filterDocumentsByDocumentType(documentsCopy, documentTypeFilter);
    const contractFilteredDocuments = filterDocumentsByContract(documentTypeFilteredDocuments, contractFilter);
    return filterDocumentsByTimeframe(contractFilteredDocuments, timeframeFilter);
};

type Localization = {
    [month: string]: string;
};

export const getTableMonthLocalizations = (t: TFunction): Localization => {
    const prefix = 'table.months';
    return {
        'month.0.name': t(`${prefix}.january`),
        'month.0.name.short': t(`${prefix}.january-short`),
        'month.1.name': t(`${prefix}.february`),
        'month.1.name.short': t(`${prefix}.february-short`),
        'month.2.name': t(`${prefix}.march`),
        'month.2.name.short': t(`${prefix}.march-short`),
        'month.3.name': t(`${prefix}.april`),
        'month.3.name.short': t(`${prefix}.april-short`),
        'month.4.name': t(`${prefix}.may`),
        'month.4.name.short': t(`${prefix}.may-short`),
        'month.5.name': t(`${prefix}.june`),
        'month.5.name.short': t(`${prefix}.june-short`),
        'month.6.name': t(`${prefix}.july`),
        'month.6.name.short': t(`${prefix}.july-short`),
        'month.7.name': t(`${prefix}.august`),
        'month.7.name.short': t(`${prefix}.august-short`),
        'month.8.name': t(`${prefix}.september`),
        'month.8.name.short': t(`${prefix}.september-short`),
        'month.9.name': t(`${prefix}.october`),
        'month.9.name.short': t(`${prefix}.october-short`),
        'month.10.name': t(`${prefix}.november`),
        'month.10.name.short': t(`${prefix}.november-short`),
        'month.11.name': t(`${prefix}.december`),
        'month.11.name.short': t(`${prefix}.december-short`),
    } as Localization;
};
