import { useMemo } from 'react';
import { AbstractDataState, useGetSimpleApiDataWithTransformer } from '@cp-shared-6/frontend-integration';
import { fetchContracts } from './ContractsSlice';
import { ContractsDataSelector } from './ContractsDataSelector';
import { Contract, ContractDataWithLinks, enrichContracts, FetchContractError } from '@cp-es/common';

type ContractTransformer = (contracts?: ContractDataWithLinks[]) => Contract | undefined;

function getFilterForContractId(contractId: string, encryptedContractId?: boolean): ContractTransformer {
    const filter: ContractTransformer = contracts =>
        contracts
            ? enrichContracts(contracts).filter(contract => {
                  const filterVal = encryptedContractId ? contract._encryptedContractNumber : contract.contractNumber;
                  return filterVal === contractId;
              })[0]
            : undefined;
    return filter;
}

type ConfigProps = {
    encryptedContractId?: boolean;
};

/**
 * Fetches the contracts and filters for the contract with given ID. If not found, undefined will be returned as data.
 * @param contractId Contract ID to get the contract for.
 */
export function useContract(
    contractId: string,
    config: ConfigProps = {},
): AbstractDataState<Contract | undefined, FetchContractError> {
    const { encryptedContractId } = config;
    const contractFilter = useMemo(() => getFilterForContractId(contractId, encryptedContractId), [
        contractId,
        encryptedContractId,
    ]);
    return useGetSimpleApiDataWithTransformer(fetchContracts, ContractsDataSelector, contractFilter);
}
