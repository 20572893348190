import React, { useState } from 'react';
import { InfoIcon, useAnalyticsActionTracker } from '@cp-shared-6/frontend-ui';
import {
    TotalEarlySettlementDetails,
    EarlySettlementSubsidyDetails,
    PartialEarlySettlementDetails,
} from '@cp-es/common';
import { DescriptionListAccordion } from '@vwfs-bronson/bronson-react';
import { TranslationFormat, useTranslationWithFormatting } from 'localization/useTranslationWithFormatting';
import { CPDate } from '@cp-shared-6/common-utilities';

type IconLabelProps = {
    text: string;
    tooltip: string;
};
const IconLabel: React.FC<IconLabelProps> = ({ text, tooltip }) => {
    return (
        <>
            {text}
            <InfoIcon text={tooltip} />
        </>
    );
};

const getField = (
    val: string | number | undefined,
    label: string,
    visible: boolean,
    tooltip?: string,
): React.ReactNode => {
    return visible ? (
        <DescriptionListAccordion.DL label={tooltip ? <IconLabel text={label} tooltip={tooltip} /> : label}>
            {val}
        </DescriptionListAccordion.DL>
    ) : null;
};

const getValueOrZero = (val: number | undefined): number => {
    return val ? val : 0;
};

const getPartialEarlySettlementAmount = (
    amortizedCapital: number | undefined,
    accruedInterestAmount: number | undefined,
    settlementFeeAmount: number | undefined,
    pendingSettlements: number | undefined,
    openingComAmount: number | undefined,
): number => {
    return (
        getValueOrZero(amortizedCapital) +
        getValueOrZero(accruedInterestAmount) +
        getValueOrZero(settlementFeeAmount) -
        getValueOrZero(pendingSettlements) +
        getValueOrZero(openingComAmount)
    );
};

type CalculationDetailsProps = {
    settlementDetails: TotalEarlySettlementDetails | PartialEarlySettlementDetails;
    subsidyDetails: EarlySettlementSubsidyDetails;
    partialSettlementPaymentDate?: CPDate;
    partialSettlementTotalAmount?: number;
};

export const CalculationDetails: React.FC<CalculationDetailsProps> = ({
    settlementDetails,
    subsidyDetails,
    partialSettlementPaymentDate,
    partialSettlementTotalAmount,
}) => {
    const { t, f } = useTranslationWithFormatting('early-settlement');
    const openedAccordionsInitialState = {
        penaltyDetails: false,
        calculationDetails: false,
    };
    const [accordionsState, setAccordionsState] = useState<Record<'penaltyDetails' | 'calculationDetails', boolean>>(
        openedAccordionsInitialState,
    );

    const { onAction: onOpenAccordion } = useAnalyticsActionTracker('onOpenEarlySettlementDetails');

    const isPartialEarlySettlement = !!partialSettlementPaymentDate;

    const {
        installmentsToSettle,
        accruedInterestAmount,
        settlementFeeAmount,
        settlementFeeRate,
        pendingSettlements,
        unpaidBalance,
        nextDueDate,
        openingComAmount,
    } = settlementDetails;

    const totalEarlySettlementDetails = settlementDetails as TotalEarlySettlementDetails;
    const partialEarlySettlementDetails = settlementDetails as PartialEarlySettlementDetails;
    const outstandingCapital = !isPartialEarlySettlement ? totalEarlySettlementDetails.outstandingCapital : undefined;
    const amortizedCapital = isPartialEarlySettlement ? partialEarlySettlementDetails.amortizedCapital : undefined;
    const pendingArrears = isPartialEarlySettlement ? partialEarlySettlementDetails.pendingArrears : undefined;
    const pendingCollateral = !isPartialEarlySettlement ? totalEarlySettlementDetails.pendingCollateral : undefined;
    const settlementAmount = isPartialEarlySettlement
        ? getPartialEarlySettlementAmount(
              amortizedCapital,
              accruedInterestAmount,
              settlementFeeAmount,
              pendingSettlements,
              openingComAmount,
          )
        : totalEarlySettlementDetails.settlementAmount;
    const paymentDate = isPartialEarlySettlement
        ? partialSettlementPaymentDate
        : totalEarlySettlementDetails.paymentDate;
    const totalAmount = isPartialEarlySettlement
        ? partialSettlementTotalAmount
        : totalEarlySettlementDetails.totalAmount;

    const unpaidBalanceTitle = isPartialEarlySettlement
        ? `${t('calculation-details.unpaid-balance.field')} + ${t(
              'calculation-details.settlement-details.field.pending-arrears',
          )}`
        : t('calculation-details.unpaid-balance.field');

    const unpaidBalanceAmount = isPartialEarlySettlement
        ? getValueOrZero(unpaidBalance) + getValueOrZero(pendingArrears)
        : unpaidBalance;

    const {
        freeInstallment,
        autoInsurance,
        breakdownInsurance,
        maintenance,
        discountPlanE,
        primaryDiscountVWFS,
        secondaryDiscountVWFS,
        subsidyAmount,
    } = subsidyDetails;

    const trackOnOpenAccordion = (accordionType: 'penaltyDetails' | 'calculationDetails'): void | null => {
        const isCurrentAccordionOpen = accordionsState[accordionType];
        if (isCurrentAccordionOpen) {
            setAccordionsState({ ...accordionsState, [accordionType]: false });
        } else {
            onOpenAccordion();
            setAccordionsState({ ...openedAccordionsInitialState, [accordionType]: true });
        }
    };

    return (
        <DescriptionListAccordion
            headerSplit
            totalLabel={t('calculation-details.total-amount')}
            totalValue={f(totalAmount, TranslationFormat.CURRENCY)}
            totalLabelNote={
                <>
                    <div>
                        {t('calculation-details.payment-date') + ' '}
                        {partialSettlementPaymentDate
                            ? f(partialSettlementPaymentDate, TranslationFormat.DATE)
                            : f(paymentDate, TranslationFormat.DATE)}
                    </div>
                    <div>
                        {t('calculation-details.next-due-date') + ' '}
                        {f(nextDueDate, TranslationFormat.DATE)}
                    </div>
                </>
            }
        >
            {subsidyAmount ? (
                <DescriptionListAccordion.Item
                    title={t('penalty-amount-details.header.headline')}
                    titleSplit={f(subsidyAmount, TranslationFormat.CURRENCY)}
                    icon="semantic-info"
                    tooltip={t('penalty-amount-details.header.tooltip')}
                    onMouseDown={(): void => {
                        trackOnOpenAccordion('penaltyDetails');
                    }}
                >
                    {getField(
                        f(freeInstallment, TranslationFormat.CURRENCY),
                        t('penalty-amount-details.free-installment'),
                        !!freeInstallment,
                    )}
                    {getField(
                        f(autoInsurance, TranslationFormat.CURRENCY),
                        t('penalty-amount-details.auto-insurance'),
                        !!autoInsurance,
                    )}
                    {getField(
                        f(breakdownInsurance, TranslationFormat.CURRENCY),
                        t('penalty-amount-details.breakdown-insurance'),
                        !!breakdownInsurance,
                    )}
                    {getField(
                        f(maintenance, TranslationFormat.CURRENCY),
                        t('penalty-amount-details.maintenance'),
                        !!maintenance,
                    )}
                    {getField(
                        f(discountPlanE, TranslationFormat.CURRENCY),
                        t('penalty-amount-details.discount-plan-e'),
                        !!discountPlanE,
                    )}
                    {getField(
                        f(primaryDiscountVWFS, TranslationFormat.CURRENCY),
                        t('penalty-amount-details.discount-vwfs'),
                        !!primaryDiscountVWFS,
                    )}
                    {getField(
                        f(secondaryDiscountVWFS, TranslationFormat.CURRENCY),
                        t('penalty-amount-details.discount-vwfs'),
                        !!secondaryDiscountVWFS,
                    )}
                </DescriptionListAccordion.Item>
            ) : null}
            <DescriptionListAccordion.Item
                title={t('calculation-details.header.headline')}
                titleSplit={f(settlementAmount, TranslationFormat.CURRENCY)}
                onMouseDown={(): void => {
                    trackOnOpenAccordion('calculationDetails');
                }}
            >
                {getField(
                    installmentsToSettle,
                    t('calculation-details.settlement-details.field.settled-period'),
                    !!installmentsToSettle,
                    t('calculation-details.settlement-details.tooltip.settled-period'),
                )}
                {getField(
                    f(outstandingCapital, TranslationFormat.CURRENCY),
                    t('calculation-details.settlement-details.field.pending-capital-amount'),
                    !isPartialEarlySettlement,
                )}

                {getField(
                    f(amortizedCapital, TranslationFormat.CURRENCY),
                    t('calculation-details.settlement-details.field.amortization-capital'),
                    isPartialEarlySettlement,
                )}
                {getField(
                    f(accruedInterestAmount, TranslationFormat.CURRENCY),
                    t('calculation-details.settlement-details.field.accrued-interest-amount'),
                    !!accruedInterestAmount,
                    t('calculation-details.settlement-details.tooltip.accrued-interest-amount'),
                )}
                {getField(
                    f(settlementFeeAmount, TranslationFormat.CURRENCY),
                    t('calculation-details.settlement-details.field.settlement-fee-amount'),
                    !!settlementFeeAmount,
                    t('calculation-details.settlement-details.tooltip.settlement-fee-amount'),
                )}
                {getField(
                    f(settlementFeeRate, TranslationFormat.PERCENT_NOT_ROUNDED),
                    t('calculation-details.settlement-details.field.settlement-fee-rate'),
                    !!settlementFeeRate,
                    t('calculation-details.settlement-details.tooltip.settlement-fee-rate'),
                )}
                {getField(
                    f(pendingSettlements, TranslationFormat.CURRENCY),
                    t('calculation-details.settlement-details.field.upfront-paid-installment'),
                    !!pendingSettlements,
                )}
                {getField(
                    f(pendingCollateral, TranslationFormat.CURRENCY),
                    t('calculation-details.settlement-details.field.pending-collateral'),
                    !!pendingCollateral,
                )}
                {getField(
                    f(openingComAmount, TranslationFormat.CURRENCY),
                    t('calculation-details.settlement-details.field.opening-com-amount'),
                    !!openingComAmount,
                )}
            </DescriptionListAccordion.Item>
            {typeof unpaidBalance !== 'undefined' ? (
                <DescriptionListAccordion.Item
                    nonCollapsable
                    title={unpaidBalanceTitle}
                    titleSplit={f(unpaidBalanceAmount, TranslationFormat.CURRENCY)}
                    icon="semantic-info"
                    tooltip={t('calculation-details.unpaid-balance.tooltip')}
                />
            ) : null}
        </DescriptionListAccordion>
    );
};
