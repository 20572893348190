import React from 'react';

import { LandingPageHeroTeaser } from 'components/landing-page-hero-teaser/LandingPageHeroTeaser';
import { LandingPageMarketingCards } from 'components/landing-page-marketing-card/LandingPageMarketingCards';

export const LandingPage: React.FC = () => {
    return (
        <div>
            <LandingPageHeroTeaser />
            <LandingPageMarketingCards />
        </div>
    );
};
