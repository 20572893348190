import { PersonalDetails, getPersonalDetailsEndpoint } from '@cp-es/common';
import { getSimpleDataFetchSlice } from '@cp-shared-6/frontend-integration';
import { CpDataApi } from 'cp-xhr';
import { DefaultBusinessMarketApiErrorCode } from '@cp-shared-6/common-utilities';

const { reducer, fetchData, updateData } = getSimpleDataFetchSlice<PersonalDetails, DefaultBusinessMarketApiErrorCode>({
    dataName: 'details',
    fetchCallback() {
        return CpDataApi.get<PersonalDetails>(getPersonalDetailsEndpoint()).then(response => response.data);
    },
});

export default reducer;
export const fetchMyProfile = fetchData;
export const updateMyProfile = updateData;
