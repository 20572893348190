import { Address, Contact, Identification, InvoiceConsent, PersonalDetails } from '@cp-es/common';

export const IdentificationWithAllValues: Identification = {
    name: 'OLIVER PRAGER',
    id: '07221753Y',
};

export const IdentificationWithOnlyBusinessValues: Identification = {
    name: 'OLIVER PRAGER',
};

export const AddressWithAllValues: Address = {
    roadType: 'Calle',
    name: 'MARIA GUERRERO',
    number: '3',
    door: '2B',
    floor: '4',
    block: '6',
    stairs: '1',
    details: 'URBANIZACIÓN DE LOS PICOS',
    zip: '28000',
    locality: 'Navalcarnero',
    province: 'BARCELONA',
    country: 'ESPAÑA',
    _links: {
        changeLink: '/customers/personal-details/address',
    },
};

export const AddressWithOnlyBusinessMandatoryValues: Address = {
    roadType: 'Calle',
    name: 'MARIA GUERRERO',
    zip: '28000',
    country: 'ESPAÑA',
};

const getAddressWithoutLinks = (): Address => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { _links, ...rest } = AddressWithOnlyBusinessMandatoryValues;
    return rest;
};

export const AddressWithoutLinks: Address = Object.assign({}, getAddressWithoutLinks());

export const AddressWithoutBusinessMandatoryValues: Address = {
    name: 'MARIA GUERRERO',
    country: 'ESPAÑA',
};

export const AddressWithWhiteSpaceValues: Address = {
    roadType: 'Calle',
    name: 'MARIA GUERRERO',
    number: '3',
    door: '',
    floor: '',
    block: '',
    stairs: '',
    details: '',
    zip: '28000',
    locality: 'Navalcarnero',
    province: 'BARCELONA',
    country: 'ESPAÑA',
};

const getAddressWithoutStairs = (): Address => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { stairs, ...rest } = AddressWithAllValues;
    return rest;
};

export const AddressWithoutStairs: Address = getAddressWithoutStairs();

export const ContactWithAllValues: Contact = {
    email: 'david.torres@digitalunit.berlin',
    invoiceEmail: 'david.torres@digitalunit.berlin',
    primaryPhoneNumber: '6205360',
    secondaryPhoneNumber: '717330854',
    _links: {
        changeContactDetails: '/customers/personal-details/contact-details',
    },
};

const getContactWithoutLinks = (): Contact => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { _links, ...rest } = ContactWithAllValues;
    return rest;
};

export const ContactWithoutLinks: Contact = Object.assign({}, getContactWithoutLinks());

export const InvoiceConsentWithSignUpNeeded: InvoiceConsent = {
    signUp: true,
};

export const InvoiceConsentWithSignUpNotNeeded: InvoiceConsent = {
    signUp: false,
};

export const MyProfileWithAllValues: PersonalDetails = {
    identification: IdentificationWithAllValues,
    address: AddressWithAllValues,
    contact: ContactWithAllValues,
    invoiceConsent: InvoiceConsentWithSignUpNeeded,
};

export const MyProfileWithOnlyMandatoryValues: PersonalDetails = {
    identification: {},
    address: {},
    contact: {},
};

const getMyProfileWithoutConsent = (): PersonalDetails => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { invoiceConsent, ...rest } = MyProfileWithAllValues;
    return rest;
};

export const MyProfileWithoutConsent: PersonalDetails = getMyProfileWithoutConsent();
