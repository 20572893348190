import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store/rootReducer';
import { useEffect } from 'react';
import { DataState } from '@cp-shared-6/frontend-integration';
import { fetchAdditionalProducts } from 'components/contracts/additional-products/AdditionalProductsSlice';
import { Contract } from '@cp-es/common';

export const REFETCH_ATTEMPTS_ON_FAILURE = 3;

export function shouldRefetch<TAppErrorCode extends string>({
    isLoading,
    loadingError,
    failedLoadingAttempts,
    hasReceivedResponse,
}: DataState<TAppErrorCode>): boolean {
    return (
        !isLoading &&
        ((loadingError &&
            loadingError.code === (('API_NOT_REACHABLE' as unknown) as TAppErrorCode) &&
            failedLoadingAttempts < REFETCH_ATTEMPTS_ON_FAILURE) ||
            (!loadingError && !hasReceivedResponse))
    );
}

export function useAdditionalProducts(contracts?: Contract[]) {
    const dispatch = useDispatch();
    const memorizedExistingDataSelector = createSelector(
        (state: RootState) => state,
        state => state.additionalProducts,
    );

    const existingData = useSelector(memorizedExistingDataSelector);

    useEffect(() => {
        if (contracts) {
            for (const contract of contracts) {
                const data = existingData[contract.contractNumber];
                if (!data || shouldRefetch(data)) {
                    dispatch(fetchAdditionalProducts(contract.contractNumber, contract._links?.additionalProducts));
                }
            }
        }
    }, [contracts, existingData, dispatch]);

    return existingData;
}
