import React, { useEffect } from 'react';
import { Contract, enrichContracts, FetchContractError, getContractsEndpoint } from '@cp-es/common';
import { useTranslation } from 'react-i18next';
import { DataOverview, Notification, NotificationStatus, withAutoScroll } from '@cp-shared-6/frontend-ui';
import { Button, ButtonContainer, Layout } from '@vwfs-bronson/bronson-react';
import { SmeContractHeader } from './SmeContractHeader';
import { NotificationForSmeContractsFetch } from './NotificationForSmeContractsFetch';
import { fetchSmeContracts } from './SmeContractsSlice';
import { RootState } from 'store/rootReducer';
import { withLoadingHandler } from 'components/integration-wrapper';
import {
    getContractBasedDataSelector,
    transformOnlyDefinedValues,
    useGetContractBasedApiDataWithTransformer,
} from '@cp-shared-6/frontend-integration';
import { WithDefaultCpIntegrationErrors } from '@cp-shared-6/common-utilities';

export type SmeContractsViewUiProps = {
    smeContracts?: Contract[];
    lastFetchError?: WithDefaultCpIntegrationErrors<FetchContractError>;
};

const SmeContractsViewUi: React.FC<SmeContractsViewUiProps> = ({ smeContracts, lastFetchError }) => {
    const { t } = useTranslation('financial-details');

    if (lastFetchError) {
        return <NotificationForSmeContractsFetch fetchContractError={lastFetchError} />;
    }

    if (smeContracts) {
        return (
            <>
                <Notification
                    status={NotificationStatus.info}
                    headline={t('ibanSection.editView.smeContractsView.forAllCostCenterContractsAlert.headline')}
                >
                    <span
                        dangerouslySetInnerHTML={{
                            __html: t('ibanSection.editView.smeContractsView.forAllCostCenterContractsAlert.text'),
                        }}
                    />
                </Notification>

                <Layout className="u-mt" equalHeight>
                    {smeContracts.map((contract, index) => (
                        <Layout.Item key={index} default="1/2" s="1/1" m="1/1" className="u-mt-none@m">
                            <SmeContractHeader
                                licensePlateNumber={contract.licensePlateNumber}
                                carInformation={contract.carInformation}
                                contractNumber={contract.contractNumber}
                            />
                        </Layout.Item>
                    ))}
                </Layout>
            </>
        );
    }

    return null;
};

export type SmeContractsViewProps = {
    contractId: string;
    costCenter?: string;
    onAcceptSmeContracts: (skipSmeContracts: boolean, contractWithSameCostCenterIds?: string[]) => void;
    toPreviousView: () => void;
};

export const SmeContractsDataSelector = getContractBasedDataSelector((state: RootState) => state.smeContracts);

export const SmeContractsView: React.FC<SmeContractsViewProps> = withAutoScroll(
    ({ contractId, costCenter, onAcceptSmeContracts, toPreviousView }) => {
        const { t } = useTranslation('financial-details');

        const {
            data: contractsWithSameCostCenter,
            isLoading,
            loadingError,
        } = useGetContractBasedApiDataWithTransformer(
            contractId,
            fetchSmeContracts,
            SmeContractsDataSelector,
            transformOnlyDefinedValues(enrichContracts),
            getContractsEndpoint(),
            false,
            {
                params: { 'cost-center': costCenter },
            },
        );

        const contractWithSameCostCenterIds = contractsWithSameCostCenter?.map(contract => contract.contractNumber);

        useEffect(() => {
            if (Array.isArray(contractsWithSameCostCenter) && contractsWithSameCostCenter.length <= 1) {
                onAcceptSmeContracts(true);
            }
        }, [onAcceptSmeContracts, contractsWithSameCostCenter]);

        const SmeContractsViewWithHandlers = withLoadingHandler(SmeContractsViewUi);

        return (
            <DataOverview title={t('ibanSection.editView.title')} withoutCardEffect={true}>
                <SmeContractsViewWithHandlers
                    isLoading={isLoading}
                    smeContracts={contractsWithSameCostCenter}
                    lastFetchError={loadingError?.code}
                />
                <ButtonContainer nav className="u-mt">
                    <Button secondary onClick={toPreviousView} testId="cancelButton">
                        {t('translation:editableSectionNav.cancel')}
                    </Button>
                    {!isLoading && !loadingError && (
                        <Button
                            onClick={() => onAcceptSmeContracts(false, contractWithSameCostCenterIds)}
                            testId="submitButton"
                        >
                            {t('translation:editableSectionNav.continue')}
                        </Button>
                    )}
                </ButtonContainer>
            </DataOverview>
        );
    },
    'SmeContractsView',
);
