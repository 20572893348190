import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store/rootReducer';
import { useEffect } from 'react';
import { Contract } from '@cp-es/common';
import { shouldRefetch } from '../additional-products/useAdditionalProducts';
import { fetchVehicleDetails } from 'components/contracts/vehicle-details';

export function useVehicalDetails(contracts?: Contract[]) {
    const dispatch = useDispatch();
    const memorizedExistingDataSelector = createSelector(
        (state: RootState) => state,
        state => state.vehicleDetails,
    );

    const existingData = useSelector(memorizedExistingDataSelector);

    useEffect(() => {
        if (contracts) {
            for (const contract of contracts) {
                const data = existingData[contract.contractNumber];
                if (!data || shouldRefetch(data)) {
                    dispatch(fetchVehicleDetails(contract.contractNumber, contract._links?.vehicleDetails));
                }
            }
        }
    }, [contracts, existingData, dispatch]);

    return existingData;
}
