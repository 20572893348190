import { getLogoutEndpoint } from '@cp-es/common';
import { createCmsContentSlice } from '../../cms-integration';
import { Logout } from '@cp-es/common/src/apis/cms/types/Logout';

const { reducer, fetchContent } = createCmsContentSlice<Logout>({
    contentName: 'logout',
    contentEndpoint: getLogoutEndpoint,
});

export default reducer;
export const fetchLogout = fetchContent;
