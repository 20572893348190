import { LicensePlate as LicensePlateShared } from '@vwfs-bronson/bronson-react';
import React from 'react';
import { useTranslation } from 'react-i18next';

export type LicensePlateProps = {
    licensePlateNumber?: string;
    numberOfCars?: number;
    size?: 'large' | 'medium' | 'small' | 'xsmall';
    className?: string;
};

export const LicensePlate: React.FC<LicensePlateProps> = ({
    licensePlateNumber,
    numberOfCars,
    size = 'medium',
    className,
}) => {
    const { t } = useTranslation();
    const registrationNumber = licensePlateNumber
        ? licensePlateNumber
        : numberOfCars && numberOfCars > 1
        ? t('licensePlate.several')
        : t('licensePlate.unknown');
    return (
        <LicensePlateShared
            className={`u-text-base${className ? ' ' + className : ''}`}
            size={size}
            country={t('licensePlate.country')}
            countryCode={t('licensePlate.country-code')}
            registrationNumber={registrationNumber}
            horizontalStripEu={true}
            euStars={true}
        />
    );
};
