import * as Yup from 'yup';
import { Street, ChangeAddressData, City, ComplementaryInformation } from '../../apis';
import {
    AddressValidationErrors,
    CityValidationErrors,
    ComplementaryInformationValidationErrors,
    StreetValidationErrors,
} from '../types';
import {
    defaultAddressValidationErrors,
    defaultCityValidationErrors,
    defaultComplementaryInformationValidationErrors,
    defaultStreetValidationErrors,
} from '../messages';

const spanishAlphanumericPattern = /^[0-9A-Za-zÀ-ÖÙ-öù-ý ]*$/;
const spanishZipCodePattern = /^(?:0[1-9]|[1-4]\d|5[0-2])\d{3}$/;

const streetSchema = (errors: StreetValidationErrors = defaultStreetValidationErrors): Yup.SchemaOf<Street> =>
    Yup.object()
        .shape({
            name: Yup.string()
                .trim()
                .required(errors.name.required)
                .max(30, errors.name.max)
                .matches(spanishAlphanumericPattern, errors.name.matches),
            number: Yup.string()
                .trim()
                .required(errors.number.required)
                .max(4, errors.number.max)
                .matches(spanishAlphanumericPattern, errors.number.matches),
        })
        .defined();

const citySchema = (errors: CityValidationErrors = defaultCityValidationErrors): Yup.SchemaOf<City> =>
    Yup.object()
        .shape({
            zip: Yup.string()
                .trim()
                .required(errors.zip.required)
                .max(5, errors.zip.max)
                .matches(spanishZipCodePattern, errors.zip.matches),
            locality: Yup.string()
                .trim()
                .required(errors.locality.required)
                .max(26, errors.locality.max)
                .matches(spanishAlphanumericPattern, errors.locality.matches),
        })
        .defined();

const complementaryInformationSchema = (
    errors: ComplementaryInformationValidationErrors = defaultComplementaryInformationValidationErrors,
): Yup.SchemaOf<ComplementaryInformation> =>
    Yup.object()
        .shape({
            block: Yup.string()
                .trim()
                .max(4, errors.block.max)
                .matches(spanishAlphanumericPattern, errors.block.matches),
            stairs: Yup.string()
                .trim()
                .max(4, errors.stairs.max)
                .matches(spanishAlphanumericPattern, errors.stairs.matches),
            floor: Yup.string()
                .trim()
                .max(4, errors.floor.max)
                .matches(spanishAlphanumericPattern, errors.floor.matches),
            door: Yup.string()
                .trim()
                .max(4, errors.door.max)
                .matches(spanishAlphanumericPattern, errors.door.matches),
            details: Yup.string()
                .trim()
                .max(40, errors.details.max)
                .matches(spanishAlphanumericPattern, errors.details.matches),
        })
        .defined();

export const addressValidator = (
    error: AddressValidationErrors = defaultAddressValidationErrors,
): Yup.SchemaOf<ChangeAddressData> =>
    Yup.object()
        .shape({
            street: streetSchema(error.street),
            complementaryInformation: complementaryInformationSchema(error.complementaryInformation),
            city: citySchema(error.city),
        })
        .defined();
