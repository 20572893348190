import React from 'react';

import { Contact, PersonalDetailsEditStatus } from '@cp-es/common';
import { ConsultView } from './consult-view/ConsultView';
import { EditView } from './edit-view/EditView';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import { changeContactDetailsPath } from '../../navigation/paths';
import { useUpdateMyProfile } from '../useUpdateMyProfile';
import { useAnalyticsActionTracker } from '@cp-shared-6/frontend-ui';

export type ContactSectionProps = {
    contact: Contact;
    hasOperativeLeaseContracts: boolean;
};

export const ContactSection: React.FC<ContactSectionProps> = ({ contact, hasOperativeLeaseContracts }) => {
    const history = useHistory();
    const { path } = useRouteMatch();
    const { updateMyProfile, myProfileData } = useUpdateMyProfile();

    const trackingSection = 'Contact';
    const { onAction: onEdit } = useAnalyticsActionTracker('onEditProfileContact');
    const { onAction: onCancel } = useAnalyticsActionTracker('onEditProfileCancel');

    const setLastEditStatus = (newEditStatus: PersonalDetailsEditStatus, updatedContact?: Contact) => {
        updateMyProfile({
            ...myProfileData,
            contact: updatedContact ?? contact,
            lastEditStatus: { ...myProfileData?.lastEditStatus, contact: newEditStatus },
        });
    };

    const startEditing = (): void => {
        onEdit();
        setLastEditStatus('NOT_PERFORMED');
        history.push(changeContactDetailsPath());
    };

    const cancelEditing = (): void => {
        onCancel(trackingSection);
        setLastEditStatus('NOT_PERFORMED');
        history.push(path);
    };

    const finishEditing = (newEditStatus: PersonalDetailsEditStatus, updatedContact?: Contact): void => {
        setLastEditStatus(newEditStatus, updatedContact);
        history.push(path);
    };

    const isChangeAllowed = !!contact._links?.changeContactDetails;

    return (
        <Switch>
            {isChangeAllowed && (
                <Route exact path={changeContactDetailsPath()}>
                    <EditView
                        contact={contact}
                        cancelEditing={cancelEditing}
                        finishEditing={finishEditing}
                        hasOperativeLeaseContracts={hasOperativeLeaseContracts}
                        lastEditStatus={myProfileData?.lastEditStatus?.contact ?? 'NOT_PERFORMED'}
                    />
                </Route>
            )}
            <Route path={path}>
                <ConsultView
                    contact={contact}
                    startEditing={startEditing}
                    lastEditStatus={myProfileData?.lastEditStatus?.contact ?? 'NOT_PERFORMED'}
                />
            </Route>
        </Switch>
    );
};
