import { useTranslation } from 'react-i18next';
import { RequestCertificateData, requestCertificateValidator, RequestCertificateValidatorError } from '@cp-es/common';
import * as Yup from 'yup';

export const useValidationSchema = (): Yup.SchemaOf<RequestCertificateData> => {
    const { t } = useTranslation('request-certificate');
    const prefix = 'form';

    const messages: RequestCertificateValidatorError = {
        certificateType: { oneOf: '', required: t(`${prefix}.dropdown.validation.required`) },
        requestDate: {
            invalid: t(`${prefix}.date.validation.format`),
            required: t(`${prefix}.date.validation.required`),
        },
    };

    return requestCertificateValidator(messages);
};
