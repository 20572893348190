import { VehicleReturn } from 'components/vehicle-return/VehicleReturn';
import React from 'react';
import { ContentSection } from '@vwfs-bronson/bronson-react';

export const VehicleReturnPage: React.FC = () => {
    return (
        <ContentSection pageWrapSize="medium">
            <VehicleReturn />
        </ContentSection>
    );
};
