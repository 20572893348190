import { LegalNoticeDto, getLegalNoticeEndpoint } from '@cp-es/common';
import { createCmsContentSlice } from 'cms-integration';

const { reducer, fetchContent } = createCmsContentSlice<LegalNoticeDto>({
    contentName: 'legalNotice',
    contentEndpoint: getLegalNoticeEndpoint,
});

export default reducer;
export const fetchLegalNotice = fetchContent;
