import { useGetSimpleUpdateData } from '@cp-shared-6/frontend-integration';
import { updateMyProfile } from './MyProfileSlice';
import { MyProfileSelector } from './MyProfileSelector';
import { PersonalDetails } from '@cp-es/common';

type UpdateMyProfileType = {
    updateMyProfile: (data: PersonalDetails) => void;
    myProfileData: PersonalDetails;
};

export function useUpdateMyProfile(): UpdateMyProfileType {
    const {
        updateData,
        dataState: { data },
    } = useGetSimpleUpdateData(updateMyProfile, MyProfileSelector);
    return {
        updateMyProfile: updateData,
        myProfileData: data as PersonalDetails,
    };
}
