import {
    Button,
    ButtonContainer,
    Modal,
    Heading,
    Layout,
    Form,
    Input,
    CustomRangeSlider,
} from '@vwfs-bronson/bronson-react';
import React, { useState } from 'react';
import {
    Notification,
    ValidatedCheckbox,
    ValidatedRadioButtonGroup,
    ValidatedInput,
    NotificationStatus,
    preventSubmit,
    Spinner,
    useAnalyticsActionTracker,
} from '@cp-shared-6/frontend-ui';
import { useTranslation } from 'react-i18next';
import {
    Contract,
    ContractInfo,
    ContractType,
    getMyRequestEndpoint,
    MyRequest,
    OpenRequestRentingCategoriesSalesforce,
    OperatingLeaseFinancialDetails,
    formatAsNumber,
} from '@cp-es/common';
import { useHistory } from 'react-router-dom';
import { dashboardPagePath } from 'components/navigation/paths';
import { Formik } from 'formik';
import { CpDataApi } from '../../../cp-xhr';
import {
    boundry,
    ContractRecalculationFormValues,
    getInitialValues,
    recalculationType,
} from './ContractRecalculationFormValues';
import { FormRangeSlider } from './form-range-slider/FormRangeSlider';
import { getContractRecalculationValidationSchema } from './Validation';
import { formatCpDate, trimAllValues } from '@cp-shared-6/common-utilities';
import { usePersonalDetails } from '../../my-profile/usePersonalDetails';

type ContractRecalculationFormUiProps = {
    contract: Contract;
    financialDetails: OperatingLeaseFinancialDetails;
    vinCode: string;
};

export const ContractRecalculationForm: React.FC<ContractRecalculationFormUiProps> = ({
    contract,
    financialDetails,
    vinCode,
}) => {
    const { t } = useTranslation('contract-recalculation');
    const history = useHistory();

    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [submitError, setSubmitError] = useState(false);
    const currentMileage = financialDetails.mileageLastRecalculation
        ? financialDetails.mileageLastRecalculation
        : (boundry.distance.minimum + boundry.distance.maximum) / 2;
    const [inputDistanceValue, setInputDistanceValue] = useState<number>(currentMileage);
    const [sliderCurrentMileage, setSliderCurrentMileage] = useState<number>(currentMileage);
    const currentDuration = Math.round(
        formatCpDate(financialDetails.endDate).diff(financialDetails.startDate, 'M', true),
    );
    const { onAction: onSubmitSuccess } = useAnalyticsActionTracker('contractRecalculationOnSubmitSuccess');
    const cancel = (): void => {
        history.push(dashboardPagePath());
    };

    const getRecalculationTypeRadioItems = (t: Function) => [
        {
            label: t('form.radio-button.extension-label'),
            value: 'extension',
        },
        {
            label: t('form.radio-button.reduction-label'),
            value: 'reduction',
        },
    ];

    const { data: myDetailsList } = usePersonalDetails();

    const getContractTypeFromGeneralCategory = (category: string): ContractType =>
        category === OpenRequestRentingCategoriesSalesforce.RECALCULATION
            ? ContractType.OPERATING_LEASE
            : ContractType.FINANCING;
    const getContractInfo = (contract: Contract, category: string, contractId?: string): ContractInfo | undefined => {
        if (contractId && contractId !== '0') {
            const { contractNumber, licensePlateNumber, contractType } = contract;
            return { licensePlate: licensePlateNumber, contractId: contractNumber, contractType };
        } else {
            return { contractType: getContractTypeFromGeneralCategory(category) };
        }
    };

    const handleChangeForInputDistanceValue = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const intValue = parseInt(event.target.value);
        if (!intValue) {
            setInputDistanceValue(0);
            setSliderCurrentMileage(0);
        } else {
            setInputDistanceValue(intValue);
            setSliderCurrentMileage(intValue);
        }
    };

    const handleChangeForSliderValue = (value: string): void => {
        const intValue = parseInt(value);
        setInputDistanceValue(intValue);
        setSliderCurrentMileage(intValue);
    };

    const onSubmit = (values: ContractRecalculationFormValues): void => {
        setIsSubmitting(true);
        values.distanceValue = inputDistanceValue;
        let email = '';
        if (myDetailsList?.contact?.email) {
            email = myDetailsList?.contact?.email;
        }

        //It is requested by the business that the information must be received in Spanish
        const requestObj = {
            'Bastidor (VIN)': vinCode,
            'Tipo de recalculo (Recalculation Type)': values.recalculationType,
            'Tiempo (Period)': values.durationValue,
            'Kilometros (Mileage)': values.distanceValue,
            'Nuevo Kilometraje (New Mileage)': values.newMileage,
            'Nueva Duracion (New Duration)': values.newPeriod,
            'Comentario (Comment)': values.comment,
        };
        const requestBody: MyRequest = {
            email: email,
            category: OpenRequestRentingCategoriesSalesforce.RECALCULATION,
            shortDescription: 'Recálculo operación Renting',
            request: JSON.stringify(requestObj),
            contractInfo: getContractInfo(
                contract,
                OpenRequestRentingCategoriesSalesforce.RECALCULATION,
                contract.contractNumber,
            ),
        };
        CpDataApi.post(getMyRequestEndpoint(), trimAllValues(requestBody))

            .then(() => {
                setShowConfirmationModal(true);
                setSubmitError(false);
                const recalculationChangeArr = [];
                if (values.newMileage) {
                    recalculationChangeArr.push('mileage');
                }
                if (values.newPeriod) {
                    recalculationChangeArr.push('duration');
                }
                const recalculationChange = recalculationChangeArr.join(' & ');
                onSubmitSuccess(values.recalculationType, recalculationChange);
            })
            .catch(() => {
                setSubmitError(true);
            })
            .finally(() => setIsSubmitting(false));
    };

    return (
        <>
            <Heading level={'3'}>{t('form.headline')}</Heading>
            {isSubmitting && <Spinner fullPage={true} />}
            <Formik
                initialValues={getInitialValues(currentMileage, currentDuration)}
                onSubmit={onSubmit}
                validationSchema={getContractRecalculationValidationSchema(t)}
            >
                {formik => (
                    <Form onSubmit={preventSubmit()}>
                        <Layout>
                            <Layout.Item default="1/1">
                                <ValidatedRadioButtonGroup
                                    className={'u-mt-xsmall'}
                                    testId={'radioRecalculationType'}
                                    radioButtons={getRecalculationTypeRadioItems(t)}
                                    name="recalculationType"
                                />
                            </Layout.Item>
                            {(formik.values.recalculationType as recalculationType) && (
                                <>
                                    <Layout.Item default="1/1">
                                        <Heading level={'4'}>{t('form.sub-headline')}</Heading>
                                        <ValidatedCheckbox
                                            label={t('form.checkbox.new-period-label')}
                                            testId="newPeriodCheckbox"
                                            name="newPeriod"
                                        />
                                    </Layout.Item>
                                    {formik.values.newPeriod && (
                                        <Layout.Item default="1/1" className="u-pb-small">
                                            <FormRangeSlider
                                                type="duration"
                                                name="durationValue"
                                                currentValue={currentDuration}
                                            />
                                        </Layout.Item>
                                    )}
                                    <Layout.Item default="1/1" className="u-pb-small">
                                        <ValidatedCheckbox
                                            label={t('form.checkbox.new-milage-label')}
                                            testId="newMileageCheckbox"
                                            name="newMileage"
                                        />
                                    </Layout.Item>
                                    {formik.values.newMileage && (
                                        <>
                                            <Layout className="u-text-center">
                                                <Layout.Item default="1/4" s={'1/1'}>
                                                    <Input
                                                        testId="inputDistanceValue"
                                                        type="text"
                                                        addonText={t('distance-unit')}
                                                        onChange={(
                                                            event: React.ChangeEvent<HTMLInputElement>,
                                                        ): void => {
                                                            handleChangeForInputDistanceValue(event);
                                                        }}
                                                        value={inputDistanceValue}
                                                    />
                                                </Layout.Item>
                                            </Layout>
                                            <Layout.Item default="1/1" s="1/1" className="u-pb-small">
                                                <CustomRangeSlider
                                                    testId="sliderDistanceValue"
                                                    value={sliderCurrentMileage}
                                                    range={{
                                                        min: boundry.distance.minimum,
                                                        max: boundry.distance.maximum,
                                                    }}
                                                    pips={{
                                                        mode: 'steps',
                                                        density: boundry.distance.maximum,
                                                        format: {
                                                            to: (value: string): string => {
                                                                const intValue = parseInt(value);
                                                                if (intValue > 1) {
                                                                    return `max. ${formatAsNumber(parseInt(value))} ${t(
                                                                        'distance-unit',
                                                                    )}`;
                                                                }
                                                                return `min. ${formatAsNumber(parseInt(value))} ${t(
                                                                    'distance-unit',
                                                                )}`;
                                                            },
                                                        },
                                                    }}
                                                    tooltips={{
                                                        to: (value: string): string => {
                                                            return `${formatAsNumber(parseInt(value))} ${t(
                                                                'distance-unit',
                                                            )}`;
                                                        },
                                                    }}
                                                    onSet={(value: string): void => handleChangeForSliderValue(value)}
                                                    className="fix-range-slider-knob"
                                                />
                                            </Layout.Item>
                                        </>
                                    )}
                                    <Layout.Item default="1/1">
                                        <ValidatedInput
                                            label={''}
                                            name={'comment'}
                                            testId={'comment'}
                                            id={'comment'}
                                            placeholder={t('form.input-placeholder')}
                                        />
                                    </Layout.Item>
                                </>
                            )}
                            <Layout.Item default="1/1">
                                <ButtonContainer className="u-mb-small" center>
                                    <Button secondary onClick={cancel} testId="backButton">
                                        {t('button.cancel')}
                                    </Button>
                                    <Button secondary onClick={formik.submitForm} testId="submitButton">
                                        {t('button.submit')}
                                    </Button>
                                </ButtonContainer>
                                <Modal
                                    shown={showConfirmationModal}
                                    buttonConfirmText={t('on-submit.success.confirm')}
                                    onConfirm={(): void => {
                                        setShowConfirmationModal(false);
                                    }}
                                    testId="confirmModal"
                                    title={t('on-submit.success.headline')}
                                    hideCloseButton
                                    status={NotificationStatus.success}
                                >
                                    {t('on-submit.success.text')}
                                </Modal>
                                <Notification
                                    status={NotificationStatus.error}
                                    headline={t('on-submit.error.headline')}
                                    text={t('on-submit.error.text')}
                                    testId="submissionError"
                                    visible={submitError}
                                />
                            </Layout.Item>
                        </Layout>
                    </Form>
                )}
            </Formik>
        </>
    );
};
