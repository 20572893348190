import React from 'react';
import { useTranslation } from 'react-i18next';

import { getSimpleDataSelector, useGetSimpleApiData } from '@cp-shared-6/frontend-integration';
import { Greeting as GreetingUi } from '@cp-shared-6/frontend-ui';
import { RootState } from 'store/rootReducer';

import { fetchGreeting } from './GreetingSlice';

export const GreetingSelector = getSimpleDataSelector((state: RootState) => state.greeting);

export const Greeting: React.FC = () => {
    const { t } = useTranslation('dashboard');

    const { data: greetingName } = useGetSimpleApiData(fetchGreeting, GreetingSelector);

    const fullGreetingText = t('greeting', { greetingName: greetingName ? greetingName : '' });

    return <GreetingUi fullGreetingText={fullGreetingText} />;
};
