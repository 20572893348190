import { ContentSection, Layout, Logo, PageWrap, Tabs } from '@vwfs-bronson/bronson-react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PrivacyPolicy, Registration as RegistrationCms, UserRegistrationError } from '@cp-es/common';
import { useCmsContent } from '@cp-shared-6/frontend-integration';
import {
    Notification,
    NotificationStatus,
    RegistrationLoadingPlaceholder,
    ScrollToTopOnNavigation,
    useAnalyticsPageViewTracker,
} from '@cp-shared-6/frontend-ui';
import { RootState } from 'store/rootReducer';
import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import { fetchPrivacyPolicy } from './PrivacyPolicySlice';
import { PrivateRegistrationForm } from './private/PrivateRegistrationForm';
import { InvitationCodeRegistrationForm } from './invitation-code/InvitationCodeRegistrationForm';
import { useInvitationParams } from 'components/registration/useInvitationParams';
import { WithDefaultCpIntegrationErrors } from '@cp-shared-6/common-utilities';
import { tabs } from './availableTabs';
import { BusinessRegistrationForm } from './business/BusinessRegistrationForm';
import { fetchRegistration } from './RegistrationSlice';
import { isAfterRegistrationLocalStorageKey } from 'config';
import { useLastLocation } from 'react-router-last-location';
import { dashboardPagePath } from '../navigation/paths';
import { getAbsoluteUrlWithParams, getUrlSearchParams } from '../../utils/urlSearchParams';

const RegistrationView: React.FC<{
    privacyPolicy: PrivacyPolicy | undefined;
    registrationCms: RegistrationCms | undefined;
}> = ({ privacyPolicy, registrationCms }) => {
    const { t } = useTranslation('registration');
    const invitationParams = useInvitationParams();
    const showInvitationForm = invitationParams?.invitation;

    const lastLocation = useLastLocation();
    const redirectUrl =
        getUrlSearchParams().get('redirectUrl') ||
        getAbsoluteUrlWithParams(lastLocation?.pathname || dashboardPagePath());

    const [lastErrorNotificationLabel, setLastErrorNotificationLabel] = useState('');

    function showError(code?: WithDefaultCpIntegrationErrors<UserRegistrationError>): void {
        switch (code) {
            case 'NOT_A_CUSTOMER':
                setLastErrorNotificationLabel('error.not-a-customer');
                break;
            case 'NO_FORMALIZED_CONTRACTS':
                setLastErrorNotificationLabel('error.no-formalized-contracts');
                break;
            default:
                setLastErrorNotificationLabel('error.no-connection');
                break;
        }
    }

    function onSuccessfulRegistration(): void {
        window.localStorage.setItem(isAfterRegistrationLocalStorageKey, 'true');
        window.location.assign(redirectUrl);
    }

    return (
        <ContentSection>
            <PageWrap size={'xsmall'}>
                <Layout className={'u-mt-large'}>
                    <Layout.Item default={'1/1'}>
                        <Logo className={'u-block-center'} />
                    </Layout.Item>
                    <Layout.Item default={'1/1'} className={'u-mb'}>
                        <div className={'u-text-center'}>
                            <h1>{t('headline')}</h1>
                            <p>{t('privacy-info')}</p>
                            <p>{registrationCms?.info}</p>
                        </div>
                        {lastErrorNotificationLabel && (
                            <ScrollToTopOnNavigation>
                                <Notification
                                    testId={'errorNotification'}
                                    status={NotificationStatus.error}
                                    headline={t(`${lastErrorNotificationLabel}.headline`)}
                                >
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html: t(`${lastErrorNotificationLabel}.text`),
                                        }}
                                    />
                                </Notification>
                            </ScrollToTopOnNavigation>
                        )}
                    </Layout.Item>
                    {!showInvitationForm && (
                        <Layout.Item default={'1/1'}>
                            <Tabs defaultSelected={tabs.private} testId={'registrationTabs'}>
                                <Tabs.Header>
                                    <Tabs.HeaderItem tabId={tabs.private} className={`cp-tab-button-${tabs.private}`}>
                                        {t('private-button')}
                                    </Tabs.HeaderItem>
                                    <Tabs.HeaderItem tabId={tabs.business} className={`cp-tab-button-${tabs.business}`}>
                                        {t('business-button')}
                                    </Tabs.HeaderItem>
                                </Tabs.Header>
                                <Tabs.Content>
                                    <Tabs.ContentItem tabId={tabs.private} testId={`tabContent${tabs.private}`}>
                                        <PrivateRegistrationForm
                                            showErrorCallback={showError}
                                            successCallback={onSuccessfulRegistration}
                                            privacyPolicyContent={privacyPolicy}
                                        />
                                    </Tabs.ContentItem>
                                    <Tabs.ContentItem tabId={tabs.business} testId={`tabContent${tabs.business}`}>
                                        <BusinessRegistrationForm
                                            showErrorCallback={showError}
                                            successCallback={onSuccessfulRegistration}
                                            privacyPolicyContent={privacyPolicy}
                                        />
                                    </Tabs.ContentItem>
                                </Tabs.Content>
                            </Tabs>
                        </Layout.Item>
                    )}
                    {invitationParams?.invitation && (
                        <InvitationCodeRegistrationForm
                            privacyPolicyContent={privacyPolicy}
                            showErrorCallback={showError}
                            successCallback={onSuccessfulRegistration}
                            invitationCode={invitationParams.invitation}
                        />
                    )}
                </Layout>
            </PageWrap>
        </ContentSection>
    );
};

const RegistrationWithWrappers = withLoadingAndNoConnectionHandler(RegistrationView);

export const Registration: React.FC = () => {
    const {
        cmsContent: privacyPolicy,
        isLoading: isPrivacyPolicyLoading,
        loadingError: privacyLoadingError,
    } = useCmsContent(fetchPrivacyPolicy, (state: RootState) => {
        return state.content.privacyPolicy;
    });

    const {
        cmsContent: registrationCms,
        isLoading: isRegistrationLoading,
        loadingError: registrationLoadingError,
    } = useCmsContent(fetchRegistration, (state: RootState) => {
        return state.content.registration;
    });

    useAnalyticsPageViewTracker('confirmIdentity', !!(privacyPolicy && registrationCms));

    return (
        <RegistrationWithWrappers
            isLoading={isRegistrationLoading || isPrivacyPolicyLoading}
            hasError={!!privacyLoadingError || !!registrationLoadingError}
            privacyPolicy={privacyPolicy}
            registrationCms={registrationCms}
            loadingPlaceholder={<RegistrationLoadingPlaceholder />}
        />
    );
};
