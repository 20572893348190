import { ContentSection } from '@vwfs-bronson/bronson-react';
import { ContractRecalculation } from 'components/contract-recalculation/ContractRecalculation';
import React from 'react';
import { useParams } from 'react-router-dom';

export const ContractRecalculationPage: React.FC = () => {
    const { contractId: encryptedContractId, vinCode } = useParams();
    return (
        <ContentSection pageWrapSize="medium">
            <ContractRecalculation encryptedContractId={encryptedContractId} vinCode={vinCode} />
        </ContentSection>
    );
};
