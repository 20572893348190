import { useTranslation } from 'react-i18next';
import { ContactChange, contactValidator, ContactValidatorErrors } from '@cp-es/common';
import * as Yup from 'yup';

export const useContactValidation = (): Yup.SchemaOf<ContactChange> => {
    const { t } = useTranslation('my-profile-contact-details');

    const messages: ContactValidatorErrors = {
        email: {
            email: t('editView.email.validations.invalidFormat'),
            required: t('editView.email.validations.required'),
        },
        invoiceEmail: { email: t('editView.invoiceEmail.validations.invalidFormat') },
        secondaryPhoneNumber: {
            matches: {
                pattern: t('editView.secondaryPhoneNumber.validations.invalidPattern'),
            },
            max: t('editView.secondaryPhoneNumber.validations.invalidFormat'),
            min: t('editView.secondaryPhoneNumber.validations.invalidFormat'),
        },
        primaryPhoneNumber: {
            matches: {
                pattern: t('editView.primaryPhoneNumber.validations.invalidPattern'),
            },
            max: t('editView.primaryPhoneNumber.validations.invalidFormat'),
            min: t('editView.primaryPhoneNumber.validations.invalidFormat'),
            required: t('editView.primaryPhoneNumber.validations.required'),
        },
    };
    return contactValidator(messages);
};
