import { CpDataApi } from '../../cp-xhr';
import { getConsultDecisionClientEndpoint, getMakeDecisionEndpoint } from '@cp-es/common';
import { MakeDecisionRequestBody } from '@cp-es/common/src/apis/services';

export const makeClientDecision = async (
    encryptedContractId: string,
    action: string,
    data?: MakeDecisionRequestBody,
): Promise<void> => {
    return CpDataApi.post(getMakeDecisionEndpoint(encryptedContractId, action), data);
};
export const getClientDecision = async (encryptedContractId: string): Promise<void> => {
    return CpDataApi.get(getConsultDecisionClientEndpoint(encryptedContractId));
};
