import { FetchAmortizationDetailsError, Installment } from '@cp-es/common';
import { createGetContractBasedDataFetchSlice } from '@cp-shared-6/frontend-integration';
import { CpDataApi } from 'cp-xhr';

const { reducer, fetchData } = createGetContractBasedDataFetchSlice<Installment[], FetchAmortizationDetailsError>({
    dataName: 'amortizationDetails',
    fetchCallback(link: string) {
        return CpDataApi.get(link).then(response => response.data);
    },
});

export default reducer;
export const fetchAmortizationDetails = fetchData;
