import { Contract } from '@cp-es/common';

import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper/withLoadingAndNoConnectionHandler';
import { NoConnectionNotification } from 'components/notifications/no-connection/NoConnectionNotification';
import React from 'react';
import { RequestCertificateHeader } from './request-certificate-header';
import { RequestCertificateInfoBox } from './request-certificate-infobox';
import { RequestCertificateForm } from './request-certificate-form';
import { useContract } from '../contracts/useContract';

export type RequestCertificateUiProps = {
    contract?: Contract;
};

export type RequestCertificateProps = {
    encryptedContractId: string;
};

export const RequestCertificateUi: React.FC<RequestCertificateUiProps> = ({ contract }) => {
    if (!contract) {
        return <NoConnectionNotification />;
    }

    return (
        <>
            <RequestCertificateInfoBox /> <br />
            <RequestCertificateHeader contract={contract} />
            <RequestCertificateForm links={contract?._links} beginDate={contract?.beginDate} />
        </>
    );
};

const RequestCertificateUiWithHandlers = withLoadingAndNoConnectionHandler(RequestCertificateUi);

export const RequestCertificate: React.FC<RequestCertificateProps> = ({ encryptedContractId }) => {
    const { data: contract, isLoading, loadingError } = useContract(encryptedContractId, { encryptedContractId: true });
    return <RequestCertificateUiWithHandlers isLoading={isLoading} hasError={!!loadingError} contract={contract} />;
};
