import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-xhr-backend';
import { initReactI18next } from 'react-i18next';

import { formatAsDistance, formatAsEmission } from '@cp-es/common';

i18n.use(LanguageDetector)
    .use(Backend)
    .use(initReactI18next)
    .init({
        fallbackLng: 'en',
        load: 'languageOnly',
        preload: ['es'],
        ns: [
            'amortization-table',
            'contracts',
            'dashboard',
            'early-settlement',
            'financial-details',
            'joint-early-settlement',
            'my-profile',
            'my-profile-address-details',
            'my-profile-contact-details',
            'my-profile-invoice-consent',
            'my-request',
            'navigation',
            'partial-early-settlement',
            'total-early-settlement',
            'request-certificate',
            'postbox',
            'registration',
            'translation',
            'unpaid-details',
            'request-subrogation',
            'request-vehicle-purchase',
            'vehicle-return',
        ],
        debug: process.env.NODE_ENV === 'development',
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
            format(value, format) {
                if (format === 'distance') {
                    return formatAsDistance(value);
                }
                if (format === 'emission') {
                    return formatAsEmission(value);
                }
                return value;
            },
        },
        backend: {
            loadPath: `/locales/{{lng}}/{{ns}}.json`,
        },
        whitelist: ['en', 'es'],
    });

export default i18n;
