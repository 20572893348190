import { DataOverview } from '@vwfs-bronson/bronson-react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Identification } from '@cp-es/common';
import { DefinitionListHorizontal, DefinitionListItem } from '@cp-shared-6/frontend-ui';
import { ValueOrDefaultError } from '../../value-or-default-error';

export const IdentificationSection: React.FC<{ identification?: Identification }> = ({ identification }) => {
    const { t } = useTranslation('my-profile');

    const identificationList: DefinitionListItem[] = [
        {
            label: t('identification.items.label.name'),
            value: <ValueOrDefaultError value={identification?.name} />,
        },
        {
            label: t('identification.items.label.id'),
            value: <ValueOrDefaultError value={identification?.id} />,
        },
    ];

    return (
        <DataOverview title={t('identification.title')}>
            <DefinitionListHorizontal list={identificationList} />
        </DataOverview>
    );
};
