import { CookiePolicyDto, getCookiePolicyEndpoint } from '@cp-es/common';
import { createCmsContentSlice } from 'cms-integration';

const { reducer, fetchContent } = createCmsContentSlice<CookiePolicyDto>({
    contentName: 'cookiePolicy',
    initialContent: {
        content: {},
    },
    contentEndpoint: getCookiePolicyEndpoint,
});

export default reducer;
export const fetchCookiePolicy = fetchContent;
