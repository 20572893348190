import { PersonalIdValidatorError, RegistrationDataWithInvitationError } from '../types';

export const defaultPersonalIdValidator: PersonalIdValidatorError = {
    format: 'Personal id must be valid CIF or NIF.',
    required: 'Personal id is required.',
};
export const defaultRegistrationDataWithInvitationError: RegistrationDataWithInvitationError = {
    invitationCode: { required: 'Invitation code is required.' },
    personalId: defaultPersonalIdValidator,
};
