import React from 'react';
import { useTranslation } from 'react-i18next';
import { Notification, NotificationStatus } from '@cp-shared-6/frontend-ui';
import { PersonalDetailsEditStatus } from '@cp-es/common';

export const NotificationForLastEditStatus: React.FC<{ lastEditStatus: PersonalDetailsEditStatus }> = ({
    lastEditStatus,
}) => {
    const { t } = useTranslation('my-profile-contact-details');

    switch (lastEditStatus) {
        case 'SUCCESS': {
            return (
                <Notification
                    status={NotificationStatus.success}
                    headline={t('consultView.editStatus.success.title')}
                    text={t('consultView.editStatus.success.text')}
                    testId={'lastEditStatus'}
                />
            );
        }
        case 'SUCCESS_PENDING': {
            return (
                <Notification
                    status={NotificationStatus.warning}
                    headline={t('consultView.editStatus.successPending.title')}
                    text={t('consultView.editStatus.successPending.text')}
                    testId={'lastEditStatus'}
                />
            );
        }
        case 'CHANGE_ERROR': {
            return (
                <Notification
                    status={NotificationStatus.error}
                    headline={t('consultView.editStatus.businessError.title')}
                    text={t('consultView.editStatus.businessError.text')}
                    testId={'lastEditStatus'}
                />
            );
        }
        case 'PARTIAL_CHANGE_ERROR': {
            return (
                <Notification
                    status={NotificationStatus.error}
                    headline={t('consultView.editStatus.partialChangeError.title')}
                    text={t('consultView.editStatus.partialChangeError.text')}
                    testId={'lastEditStatus'}
                />
            );
        }
        case 'UNEXPECTED_ERROR': {
            return (
                <Notification
                    status={NotificationStatus.error}
                    headline={t('consultView.editStatus.unexpectedError.title')}
                    text={t('consultView.editStatus.unexpectedError.text')}
                    testId={'lastEditStatus'}
                />
            );
        }
        default: {
            return null;
        }
    }
};
