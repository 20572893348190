import React, { useState } from 'react';
import { OfflinePaymentContent } from '../../offline-payment/offline-payment-content';
import { OfflinePaymentButtonBar } from '../../offline-payment/offline-payment-button-bar';
import {
    Contract,
    EarlySettlementEmailRequest,
    EarlySettlementHeader,
    EarlySettlementOptionType,
    RequestCertificateError,
    TotalEarlySettlementOfflinePayment,
} from '@cp-es/common';
import { Layout } from '@vwfs-bronson/bronson-react';
import { ContractTermsHeader } from '../../contract-terms-header';
import { bankName, iban } from '../../../../config';
import { CpDataApi } from '../../../../cp-xhr';
import { parseErrorResponse } from '@cp-shared-6/frontend-integration';
import { OfflinePaymentConfirmationModal } from '../../offline-payment/offline-payment-confirmation-modal';
import { Spinner, useAnalyticsActionTracker, useAnalyticsPageViewTracker } from '@cp-shared-6/frontend-ui';
import { CPDate, trimAllValues, WithDefaultCpIntegrationErrors } from '@cp-shared-6/common-utilities';

type OfflinePaymentProps = {
    back: () => void;
    offlinePaymentSendingEmailLink?: string;
    codeTransfer?: string;
    totalAmount?: number;
    paymentDate: CPDate;
    contract: Contract;
    earlySettlementHeader: EarlySettlementHeader;
    partialEarlySettlementOptionType?: EarlySettlementOptionType;
};

export const OfflinePayment: React.FC<OfflinePaymentProps> = ({
    offlinePaymentSendingEmailLink,
    codeTransfer,
    totalAmount,
    paymentDate,
    contract,
    earlySettlementHeader,
    back,
    partialEarlySettlementOptionType,
}) => {
    const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [lastSubmissionError, setLastSubmissionError] = useState<
        WithDefaultCpIntegrationErrors<RequestCertificateError>
    >();
    const [lastReceivedEmail, setLastReceivedEmail] = useState<string>('');

    useAnalyticsPageViewTracker(
        'earlySettlementSummary',
        !!(totalAmount && codeTransfer && offlinePaymentSendingEmailLink && partialEarlySettlementOptionType),
    );
    const { onAction: onEmailRequestSuccess } = useAnalyticsActionTracker('onEditEarlySettlementSummaryLayerSuccess');
    const { onAction: onEmailRequestFailed } = useAnalyticsActionTracker('onEarlySettlementSummarySubmitFailed');

    if (!totalAmount || !codeTransfer || !offlinePaymentSendingEmailLink || !partialEarlySettlementOptionType) {
        return null;
    }

    const offlinePaymentDetails: TotalEarlySettlementOfflinePayment = {
        bankName: bankName,
        iban: iban,
        codeTransfer: codeTransfer,
        totalAmount: totalAmount,
        paymentDate: paymentDate,
    };

    const openConfirmationModal = (
        isSuccess = true,
        trackingError?: {
            errorCode: string;
            errorMessage: string;
        },
    ): void => {
        setShowConfirmationModal(true);
        isSuccess ? onEmailRequestSuccess() : onEmailRequestFailed(trackingError);
        setIsSubmitting(false);
    };

    const closeConfirmationModal = (): void => {
        setShowConfirmationModal(false);
    };

    const sendEmail = (value: EarlySettlementOptionType): void => {
        setIsSubmitting(true);

        const requestBody: EarlySettlementEmailRequest = {
            optionType: value,
        };

        CpDataApi.post(offlinePaymentSendingEmailLink, trimAllValues(requestBody))
            .then(response => {
                setLastReceivedEmail(response.data?.email);
                setLastSubmissionError(undefined);
                openConfirmationModal();
            })
            .catch(error => {
                setLastReceivedEmail('');
                const { code, message } = parseErrorResponse<RequestCertificateError>(error);
                setLastSubmissionError(code);
                openConfirmationModal(false, { errorCode: code, errorMessage: message });
            });
    };

    return (
        <>
            <Layout.Item default="1/1">
                <ContractTermsHeader contractDetails={contract} earlySettlementHeader={earlySettlementHeader} />
            </Layout.Item>

            <Layout.Item default="1/1">
                <OfflinePaymentContent offlinePaymentDetails={offlinePaymentDetails} />
            </Layout.Item>

            <OfflinePaymentButtonBar
                back={back}
                sendEmail={(): void => sendEmail(partialEarlySettlementOptionType)}
                success={!!lastReceivedEmail}
            />

            {isSubmitting && <Spinner fullPage={true} />}

            <Layout.Item default="1/1">
                <OfflinePaymentConfirmationModal
                    shown={showConfirmationModal}
                    receivedEmail={lastReceivedEmail}
                    errorStatus={lastSubmissionError}
                    closeConfirmationModal={closeConfirmationModal}
                />
            </Layout.Item>
        </>
    );
};
