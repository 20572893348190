import React, { useState } from 'react';
import { CpDataApi } from '../../../cp-xhr';
import base64ToBlob from 'b64-to-blob';
import { saveAs as downloadFileAs } from 'file-saver';
import { useTranslation } from 'react-i18next';
import { Layout, Link, Modal } from '@vwfs-bronson/bronson-react';
import { Spinner, useAnalyticsActionTracker } from '@cp-shared-6/frontend-ui';
import moment from 'moment-timezone';

type AmortizationTableDownloadProps = {
    link?: string;
    contractNumber?: string;
    encryptedContractId?: string;
};
export const AmortizationTableDownload: React.FC<AmortizationTableDownloadProps> = ({ link, contractNumber }) => {
    const { t, i18n } = useTranslation('amortization-table');

    const currentLanguage = i18n.languages[0];

    const [hasError, setHasError] = useState<boolean>(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const { onAction: onDownload } = useAnalyticsActionTracker('onAmortizationTableDownload');

    const downloadPdf = (fileName: string): void => {
        if (!link) {
            return;
        }
        onDownload();
        setIsSubmitting(true);
        const requestBody = {
            locale: currentLanguage,
        };
        CpDataApi.get(link, { params: requestBody })
            .then(response => {
                const fileContent = response.data;
                const pdfContentType = 'application/pdf';
                const pdfBlob = base64ToBlob(fileContent, pdfContentType);
                downloadFileAs(pdfBlob, fileName);
                setIsSubmitting(false);
            })
            .catch(() => {
                setIsSubmitting(false);
                setHasError(true);
            });
    };

    const fileName = t('download.filename', {
        contractNumber: contractNumber,
        date: moment(moment.now()).format('YYYYMMDD'),
    });

    return (
        <>
            <Layout className={'u-text-right u-mb-small'} right={isSubmitting}>
                <Layout.Item default={isSubmitting ? '1/6' : '1/1'}>
                    {isSubmitting ? (
                        <Spinner small />
                    ) : (
                        <Link
                            onClick={(): void => downloadPdf(fileName)}
                            testId="pdfDownloadLink"
                            normalLink
                            icon="download"
                        >
                            {t('download.download-link')}
                        </Link>
                    )}
                </Layout.Item>
            </Layout>

            <Modal
                shown={hasError}
                buttonConfirmText={t('download.error-modal.button-text')}
                onConfirm={(): void => setHasError(false)}
                onClose={(): void => setHasError(false)}
                title={t('download.error-modal.title')}
                status="error"
                testId="downloadFailureModal"
            >
                {t('download.error-modal.text')}
            </Modal>
        </>
    );
};
