import { Registration, getRegistrationCmsEndpoint } from '@cp-es/common';
import { createCmsContentSlice } from 'cms-integration';

const { reducer, fetchContent } = createCmsContentSlice<Registration>({
    contentName: 'registration',
    contentEndpoint: getRegistrationCmsEndpoint,
});

export default reducer;
export const fetchRegistration = fetchContent;
