import { PostboxDocument } from '@cp-es/common';
import { CPDate, formatCpDate } from '@cp-shared-6/common-utilities';

import { getSortedPostboxDocuments } from './SortPostboxDocuments';

export type AllTimeframes = 'ALL';

const sortedTimeframes = [
    'LAST_3_MONTHS',
    'LAST_6_MONTHS',
    'LAST_12_MONTHS',
    'LAST_2_YEARS',
    'LAST_3_YEARS',
    'LAST_4_YEARS',
] as const;

export type Timeframe = typeof sortedTimeframes[number];

const timeframeTranslationMapping: Record<Timeframe | AllTimeframes, string> = {
    ALL: 'all',
    LAST_3_MONTHS: 'last-3-months',
    LAST_6_MONTHS: 'last-6-months',
    LAST_3_YEARS: 'last-3-years',
    LAST_12_MONTHS: 'last-12-months',
    LAST_2_YEARS: 'last-2-years',
    LAST_4_YEARS: 'last-4-years',
};

export const timeframeMomentMapping = (): Record<Timeframe, CPDate> => ({
    LAST_3_MONTHS: formatCpDate()
        .subtract(3, 'months')
        .toCpDate(),
    LAST_6_MONTHS: formatCpDate()
        .subtract(6, 'months')
        .toCpDate(),
    LAST_12_MONTHS: formatCpDate()
        .subtract(12, 'months')
        .toCpDate(),
    LAST_2_YEARS: formatCpDate()
        .subtract(2, 'years')
        .toCpDate(),
    LAST_3_YEARS: formatCpDate()
        .subtract(3, 'years')
        .toCpDate(),
    LAST_4_YEARS: formatCpDate()
        .subtract(4, 'years')
        .toCpDate(),
});

export type AvailableTimeframe = {
    key: Timeframe | AllTimeframes;
    momentFilter?: CPDate;
    translation: string;
};

const getOldestDocument = (postboxDocuments: PostboxDocument[]): PostboxDocument => {
    const sortedDocuments = getSortedPostboxDocuments(postboxDocuments);
    return sortedDocuments[0];
};

const getOldestDocumentDateTimeframeFilter = (postboxDocuments: PostboxDocument[]): Timeframe => {
    const oldestDocument = getOldestDocument(postboxDocuments);

    const sortedTimeframesInverse: Timeframe[] = Object.assign([], sortedTimeframes).reverse();

    let oldestTimeframeFilter: Timeframe = 'LAST_4_YEARS';
    sortedTimeframesInverse.forEach(timeframe => {
        if (oldestDocument.date >= timeframeMomentMapping()[timeframe]) {
            oldestTimeframeFilter = timeframe;
        }
    });

    return oldestTimeframeFilter;
};

export const getListOfAvailableTimeframes = (postboxDocuments: PostboxDocument[]): AvailableTimeframe[] => {
    const oldestDocumentDateTimeframe = getOldestDocumentDateTimeframeFilter(postboxDocuments);

    const listOfAvailableTimeframes: AvailableTimeframe[] = [
        { key: 'ALL', translation: timeframeTranslationMapping['ALL'] },
    ];

    for (let i = 0; sortedTimeframes[i - 1] !== oldestDocumentDateTimeframe && i < sortedTimeframes.length; i++) {
        const timeframe = sortedTimeframes[i];

        listOfAvailableTimeframes.push({
            key: timeframe,
            translation: timeframeTranslationMapping[timeframe],
            momentFilter: timeframeMomentMapping()[timeframe],
        });
    }

    return listOfAvailableTimeframes;
};
