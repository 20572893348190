import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Button, ButtonContainer, Card, Heading, Layout, Paragraph, RadioButton } from '@vwfs-bronson/bronson-react';
import { ContractHeader } from '../../contracts/ContractHeader';
import { dashboardPagePath, digitalRenewalPagePath } from 'components/navigation/paths';
import {
    Contract,
    DATE_FORMAT,
    DigitalRenewalOptions,
    EncryptedContractNumber,
    FinanceContract,
    getMyRequestEndpoint,
    OperatingLeaseContract,
    PersonalDetails,
    formatAsCurrency,
} from '@cp-es/common';
import { formatCpDate, trimAllValues } from '@cp-shared-6/common-utilities';
import { RefinanceSlider } from './refinance-slider/RefinanceSlider';
import { makeClientDecision } from '../makeClientDecision';
import { SuccessModal } from '../success-modal/SuccessModal';
import { useDispatch } from 'react-redux';
import { setSuccessNotification } from 'components/notifications/digital-renewal/DigitalRenewalNotificationSlice';
import { Spinner } from '@cp-shared-6/frontend-ui';
import { CpDataApi } from 'cp-xhr';
import { RefinancingFormValues } from './refinancingFormikValues';
import { useFinancialDetails } from 'components/contracts/financial-details/useFinancialDetails';
import { useBrandName } from '../options-overview/useBrandName';
import { MakeDecisionRequestBody } from '@cp-es/common/src/apis/services';

type TextComponentProps = {
    paragraph: string;
};

const TextComponent: React.FC<TextComponentProps> = ({ paragraph }) => {
    return <span dangerouslySetInnerHTML={{ __html: paragraph }} />;
};

export type DigitalRenewalRefinanceOrPaymentProps = {
    contract?: Contract & EncryptedContractNumber;
    personalDetails?: PersonalDetails;
};

export const DigitalRenewalRefinanceOrPayment: React.FC<DigitalRenewalRefinanceOrPaymentProps> = ({
    contract,
    personalDetails,
}) => {
    const { t } = useTranslation('digital-renewal');
    const history = useHistory();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isError, setIsError] = useState(true);
    const [isSuccess, setIsSuccess] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const brand = useBrandName(contract);

    const [refinanceFormValues, setRefinanceFormValues] = useState<RefinancingFormValues>({
        sliderAmount: 0,
        sliderAmountCurrency: 0,
        request: '',
    });

    const [radioButtonValue, setRadioButtonValue] = useState<
        DigitalRenewalOptions.REFINANCE | DigitalRenewalOptions.PAYMENT | null
    >(null);

    const dispatch = useDispatch();

    const { data: financialDetails } = useFinancialDetails(contract?.contractNumber || '');

    if (!contract || !personalDetails) {
        return null;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const nominalAmount = formatAsCurrency((financialDetails as any)?.nominalAmount);

    const email = personalDetails?.contact?.email;
    const { contractNumber } = contract;
    const contractEndDate = formatCpDate(contract.contractEndDate).format(DATE_FORMAT);
    const firstParagraph = t('finance.refinance-or-payment-page.paragraphs.firstParagraph', {
        brand: brand,
    });
    const secondParagraph = t('finance.refinance-or-payment-page.paragraphs.secondParagraph', { nominalAmount });
    const paymentParagraph = t('finance.refinance-or-payment-page.radio-button.payment-text', {
        contractEndDate: contractEndDate,
        nominalAmount: nominalAmount,
    });

    const backToDashboard = (): void => history.push(dashboardPagePath());
    const onModalClose = (): void => {
        setIsModalOpen(false);
        backToDashboard();
    };

    const handleRefinanceSubmit = async (): Promise<void> => {
        if (!refinanceFormValues) {
            return;
        }

        const requestBody = {
            email,
            category: 'FINANCE-NEXT-AUTOCREDIT/DC01',
            shortDescription: 'refinance',
            request: `
            Contrato: ${contract.contractNumber}
            Request: ${refinanceFormValues.request}
            Slider Amount: ${refinanceFormValues.sliderAmount}
            Slider Amount Currency: ${refinanceFormValues.sliderAmountCurrency}
        `,
            contractInfo: { contractType: contract.contractType },
        };

        await CpDataApi.post(getMyRequestEndpoint(), trimAllValues(requestBody));
    };

    const handleSetSuccessMessage = (
        contract: (FinanceContract | OperatingLeaseContract) & EncryptedContractNumber,
        option: DigitalRenewalOptions,
    ): void => {
        dispatch(setSuccessNotification({ contract, selectedOption: option }));
    };

    const handleMakeClientDecision = (): void => {
        if (!radioButtonValue) {
            return;
        }

        setIsLoading(true);
        const contractNumber = contract._encryptedContractNumber;

        const body: MakeDecisionRequestBody = {
            refinanceAndPaymentData: {
                months: String(refinanceFormValues.sliderAmount),
                downpayment: String(refinanceFormValues.sliderAmountCurrency),
                observations: refinanceFormValues.request,
            },
        };

        makeClientDecision(contractNumber, radioButtonValue, body)
            .then(() => (radioButtonValue === DigitalRenewalOptions.REFINANCE ? handleRefinanceSubmit() : undefined))
            .then(() => {
                setIsSuccess(true);
                handleSetSuccessMessage(contract, radioButtonValue);
            })
            .catch(() => {
                setIsError(true);
            })
            .finally(() => {
                setIsLoading(false);
                setIsModalOpen(true);
            });
    };

    const successModalContent = isSuccess
        ? {
              heading: t('finance.refinance-or-payment-page.modal-success.heading'),
              description: t('finance.refinance-or-payment-page.modal-success.description'),
              buttonContent: t('finance.refinance-or-payment-page.modal-success.buttonContent'),
          }
        : isError
        ? {
              heading: t('finance.modal-error.heading'),
              description: t('finance.modal-error.description'),
              buttonContent: t('finance.modal-error.buttonContent'),
          }
        : null;

    return (
        <>
            <Heading level={1}>{t('finance.refinance-or-payment-page.heading')}</Heading>
            <Layout>
                <Layout.Item default="1/1" s="1/1">
                    <Card element="article">
                        <ContractHeader contract={contract} />
                    </Card>
                </Layout.Item>
                <Layout.Item default="1/1" s="1/1">
                    <Paragraph>
                        <TextComponent paragraph={firstParagraph} />
                    </Paragraph>
                    <Paragraph>
                        <TextComponent paragraph={secondParagraph} />
                    </Paragraph>
                </Layout.Item>

                <Layout.Item className={'u-ml-large'}>
                    <Paragraph>
                        <RadioButton
                            name="radio-button-refinancing"
                            id="radio-button-refinancing"
                            onChange={(): void => setRadioButtonValue(DigitalRenewalOptions.REFINANCE)}
                            checked={radioButtonValue === DigitalRenewalOptions.REFINANCE}
                        >
                            {t('finance.refinance-or-payment-page.radio-button.refinancing')}
                        </RadioButton>
                    </Paragraph>
                    <RefinanceSlider
                        show={radioButtonValue === DigitalRenewalOptions.REFINANCE}
                        contractId={contractNumber}
                        formValues={refinanceFormValues}
                        setFormValues={(values): void => setRefinanceFormValues(prev => ({ ...prev, ...values }))}
                    />
                </Layout.Item>
                <Layout.Item className={'u-ml-large'}>
                    <Paragraph>
                        <RadioButton
                            name="radio-button-payment"
                            id="radio-button-payment"
                            onChange={(): void => setRadioButtonValue(DigitalRenewalOptions.PAYMENT)}
                            checked={radioButtonValue === DigitalRenewalOptions.PAYMENT}
                        >
                            {t('finance.refinance-or-payment-page.radio-button.payment')}
                        </RadioButton>
                    </Paragraph>
                    {radioButtonValue === DigitalRenewalOptions.PAYMENT && <Paragraph>{paymentParagraph}</Paragraph>}
                </Layout.Item>
                {isLoading && (
                    <Layout.Item>
                        <Spinner center />
                    </Layout.Item>
                )}
                <Layout.Item className="u-mt" default="1/1" s="1/1">
                    <ButtonContainer center>
                        <Button
                            secondary
                            onClick={(): void =>
                                history.push(digitalRenewalPagePath(contract._encryptedContractNumber))
                            }
                            testId="backButton"
                        >
                            {t('finance.button.back')}
                        </Button>
                        <Button
                            onClick={(): void => handleMakeClientDecision()}
                            testId="submitButton"
                            disabled={radioButtonValue === null}
                        >
                            {t('finance.button.submit')}
                        </Button>
                    </ButtonContainer>
                </Layout.Item>
            </Layout>
            <SuccessModal
                isOpen={isModalOpen}
                onClose={onModalClose}
                onSubmit={onModalClose}
                content={successModalContent}
            />
        </>
    );
};
