import React from 'react';
import { PartialEarlySettlement } from '@cp-es/common';

import { TileSelectGroup, TileSelect, Layout, SectionHeading } from '@vwfs-bronson/bronson-react';
import {
    TranslationFormat,
    useTranslationWithFormatting,
} from '../../../../../localization/useTranslationWithFormatting';

export type SettlementOptionsProps = {
    partialEarlySettlementDetails?: PartialEarlySettlement[];
    setSelectedOption: Function;
    setIsOptionSelected: Function;
};

export const SettlementOptions: React.FC<SettlementOptionsProps> = ({
    partialEarlySettlementDetails,
    setSelectedOption,
    setIsOptionSelected,
}) => {
    const { t, f } = useTranslationWithFormatting('partial-early-settlement');

    if (!partialEarlySettlementDetails || partialEarlySettlementDetails.length === 0) return null;

    const spiPesDetails: PartialEarlySettlement | undefined = partialEarlySettlementDetails.find(
        item => item.optionType === 'SPI',
    );

    const sppPesDetails: PartialEarlySettlement | undefined = partialEarlySettlementDetails.find(
        item => item.optionType === 'SPP',
    );

    return (
        <>
            <Layout.Item default="1/1" s="1/1">
                <SectionHeading center={true} level="5" subtitle={t('calculate.options.text')}>
                    {t('calculate.options.headline')}
                </SectionHeading>
            </Layout.Item>
            <Layout.Item className="u-text-center" default="1/1" s="1/1">
                <TileSelectGroup layoutItemClassName="u-1/2 l-1/2 m-1/1 s-1/1">
                    {spiPesDetails && (
                        <TileSelect
                            testId="optionSpi"
                            title={t('calculate.options.reduce-monthly-rate.headline')}
                            inputType="radio"
                            value="spiPesDetails"
                            radioGroupName="settlementOptions"
                            onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                                setIsOptionSelected(event.target.checked);
                                setSelectedOption(spiPesDetails);
                            }}
                        >
                            <p>
                                <span className="u-block">
                                    {t('calculate.options.reduce-contract-end-date.monthly-rate')}
                                </span>
                                <span className="u-block">
                                    <strong className="u-text-success">
                                        {f(spiPesDetails.newInstallmentAmount, TranslationFormat.CURRENCY)}
                                    </strong>
                                </span>
                            </p>
                            <p>
                                <span className="u-block">
                                    {t('calculate.options.reduce-monthly-rate.contract-end-date')}
                                </span>
                                <span className="u-block">
                                    <strong>{f(spiPesDetails.endDate, TranslationFormat.DATE)}</strong>
                                </span>
                            </p>
                            <p>
                                <span className="u-block">{t('calculate.options.remaining-installments')}</span>
                                <span className="u-block">
                                    <strong>{f(spiPesDetails.remainingInstallments, TranslationFormat.NUMBER)}</strong>
                                </span>
                            </p>
                            <p>
                                <span className="u-block">{t('calculate.options.cost-of-option')}</span>
                                <span className="u-block">
                                    <strong>{f(spiPesDetails.totalAmount, TranslationFormat.CURRENCY)}</strong>
                                </span>
                            </p>
                        </TileSelect>
                    )}
                    {sppPesDetails && (
                        <TileSelect
                            testId="optionSpp"
                            title={t('calculate.options.reduce-contract-end-date.headline')}
                            inputType="radio"
                            value="sppPesDetails"
                            radioGroupName="settlementOptions"
                            onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                                setIsOptionSelected(event.target.checked);
                                setSelectedOption(sppPesDetails);
                            }}
                        >
                            <p>
                                <span className="u-block">
                                    {t('calculate.options.reduce-contract-end-date.monthly-rate')}
                                </span>
                                <span className="u-block">
                                    <strong>{f(sppPesDetails.newInstallmentAmount, TranslationFormat.CURRENCY)}</strong>
                                </span>
                            </p>
                            <p>
                                <span className="u-block">
                                    {t('calculate.options.reduce-contract-end-date.new-contract-end-date')}
                                </span>
                                <span className="u-block">
                                    <strong className="u-text-success">
                                        {f(sppPesDetails.endDate, TranslationFormat.DATE)}
                                    </strong>
                                </span>
                            </p>
                            <p>
                                <span className="u-block">{t('calculate.options.remaining-installments')}</span>
                                <span className="u-block">
                                    <strong className="u-text-success">
                                        {f(sppPesDetails.remainingInstallments, TranslationFormat.NUMBER)}
                                    </strong>
                                </span>
                            </p>
                            <p>
                                <span className="u-block">{t('calculate.options.cost-of-option')}</span>
                                <span className="u-block">
                                    <strong>{f(sppPesDetails.totalAmount, TranslationFormat.CURRENCY)}</strong>
                                </span>
                            </p>
                        </TileSelect>
                    )}
                </TileSelectGroup>
            </Layout.Item>
        </>
    );
};
