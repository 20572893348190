import React from 'react';
import { useTranslation } from 'react-i18next';

import { ValueOrErrorMessage } from '@cp-shared-6/frontend-ui';

export type ValueOrDefaultErrorProps = {
    value?: string;
};

export const ValueOrDefaultError: React.FC<ValueOrDefaultErrorProps> = ({ value }) => {
    const { t } = useTranslation();
    return <ValueOrErrorMessage value={value} errorMessage={t('error.missingValue')} />;
};
