export type ContractRecalculationFormValues = {
    recalculationType?: string;
    newPeriod?: boolean;
    durationValue?: number;
    newMileage?: boolean;
    distanceValue?: number;
    comment?: string;
};

export enum recalculationType {
    extension = 'extension',
    reduction = 'reduction',
}
export const boundry = {
    duration: {
        maximum: 60,
        minimum: 0,
    },
    distance: {
        maximum: 200000,
        minimum: 0,
    },
};

export const getInitialValues = (currentMileage: number, currentDuration: number): ContractRecalculationFormValues => ({
    recalculationType: '',
    newPeriod: false,
    durationValue: currentDuration,
    newMileage: false,
    distanceValue: currentMileage,
    comment: '',
});
