export enum FetchContentError {
    DEFAULT_ERROR = 'DEFAULT_ERROR',
}

export type ContentEndpoint = (locale: string, brand: string) => string;

function getContentEndpoint(locale: string, brand: string, contentType: string): string {
    return `/${brand}/${locale}/${contentType}.json`;
}

export const getIconFooterEndpoint: ContentEndpoint = (locale, brand) => {
    return getContentEndpoint(locale, brand, 'icon-footer');
};

export const getLegalFooterEndpoint: ContentEndpoint = (locale, brand) => {
    return getContentEndpoint(locale, brand, 'legal-footer');
};

export const getLandingPageHeroTeaserEndpoint: ContentEndpoint = (locale, brand) => {
    return getContentEndpoint(locale, brand, 'landing-page-hero-teaser');
};

export const getLandingPageMarketingCardsEndpoint: ContentEndpoint = (locale, brand) => {
    return getContentEndpoint(locale, brand, 'landing-page-marketing-cards');
};

export const getDashboardMarketingCardEndpoint: ContentEndpoint = (locale, brand) => {
    return getContentEndpoint(locale, brand, 'dashboard-marketing-card');
};

export const getCookieWarningEndpoint: ContentEndpoint = (locale, brand) => {
    return getContentEndpoint(locale, brand, 'cookie-warning');
};

export const getCookiePolicyEndpoint: ContentEndpoint = (locale, brand) => {
    return getContentEndpoint(locale, brand, 'cookie-policy');
};

export const getLegalNoticeEndpoint: ContentEndpoint = (locale, brand) => {
    return getContentEndpoint(locale, brand, 'legal-notice');
};

export const getFaqEndpoint: ContentEndpoint = (locale, brand) => {
    return getContentEndpoint(locale, brand, 'faq');
};

export const getUnauthorizedErrorEndpoint: ContentEndpoint = (locale, brand) => {
    return getContentEndpoint(locale, brand, 'unauthorized-error');
};

export const getForbiddenErrorEndpoint: ContentEndpoint = (locale, brand) => {
    return getContentEndpoint(locale, brand, 'forbidden-error');
};

export const getNotFoundErrorEndpoint: ContentEndpoint = (locale, brand) => {
    return getContentEndpoint(locale, brand, 'notfound-error');
};

export const getPrivacyPolicyEndpoint: ContentEndpoint = (locale, brand) => {
    return getContentEndpoint(locale, brand, 'privacy-policy');
};

export const getThirdPartyLicensesEndpoint: ContentEndpoint = (locale, brand) => {
    return getContentEndpoint(locale, brand, 'third-party-licenses');
};

/**
 * I am deviating from the naming of the other endpoints,
 * as there is already another function with the name getRegistrationEndpoint
 */
export const getRegistrationCmsEndpoint: ContentEndpoint = (locale, brand) => {
    return getContentEndpoint(locale, brand, 'registration');
};

//CO-21500
export const getLogoutEndpoint: ContentEndpoint = (locale, brand) => {
    return getContentEndpoint(locale, brand, 'logout');
};
