export enum ContractPartyRole {
    HOLDER = '1',
    GUARANTOR = '2',
}

export interface ContractParty {
    /*
     * Full name of the user
     * */
    name?: string;
    /*
     * Id of the user as it is received from the market
     * */
    userId?: string;
    /*
     * Role of the user for a specific contract.
     * */
    userRole?: ContractPartyRole;
}
