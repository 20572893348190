import { MyRequestFormFields } from './MyRequestFormFields';
import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { MAX_REQUEST, MAX_SHORT_DESCRIPTION } from '@cp-es/common';

export const getMyRequestValidationSchema = (t: TFunction): Yup.SchemaOf<MyRequestFormFields> =>
    Yup.object().shape({
        email: Yup.string()
            .trim()
            .required(t('form.email.validations.empty'))
            .email(t('form.email.validations.format')),
        category: Yup.string().required(t('form.category.validations.empty')),
        subject: Yup.string()
            .trim()
            .required(t('form.subject.validations.empty'))
            .max(
                MAX_SHORT_DESCRIPTION,
                t('form.subject.validations.max-length', { maxSubjectCharacters: MAX_SHORT_DESCRIPTION }),
            ),
        request: Yup.string()
            .trim()
            .required(t('form.request.validations.empty'))
            .max(MAX_REQUEST, t('form.request.validations.max-length', { maxRequestCharacters: MAX_REQUEST })),
    });
