import { getUnauthorizedErrorEndpoint, HeroTeaserDto } from '@cp-es/common';
import { createCmsContentSlice } from 'cms-integration';

const { reducer, fetchContent } = createCmsContentSlice<HeroTeaserDto>({
    contentName: 'unauthorizedErrorContent',
    contentEndpoint: getUnauthorizedErrorEndpoint,
});

export default reducer;
export const fetchUnauthorizedError = fetchContent;
