import { createGetContractBasedDataFetchSlice } from '@cp-shared-6/frontend-integration';
import { PartialEarlySettlement, EarlySettlementError } from '@cp-es/common';
import { CpDataApi } from 'cp-xhr';

const { reducer, fetchData } = createGetContractBasedDataFetchSlice<PartialEarlySettlement[], EarlySettlementError>({
    dataName: 'partialEarlySettlement',
    fetchCallback(link: string) {
        return CpDataApi.get(link).then(response => response.data);
    },
});

export default reducer;
export const fetchPartialEarlySettlement = fetchData;
