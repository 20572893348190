import 'moment/locale/es';
import 'numeral/locales/es';
import moment from 'moment-timezone';
import numeral from 'numeral';
import { CPDate, formatCpDate } from '@cp-shared-6/common-utilities';

export const ES_TIMEZONE = 'Europe/Madrid';

moment.tz.setDefault(ES_TIMEZONE);

const locale = 'es';
export const numberFormat = '0,0';
export const numberFormatRoundedTwo = '0,0.00';
const numeralFormat = '0o';
const currencyFormat = '0,0.00';
const longDateFormat = 'DD/MMM/YYYY';
export const undefinedReturnValueHyphen = '-';

export const DATE_FORMAT = 'DD/MM/YYYY';
export const isoDateFormat = 'YYYY-MM-DD';

export function changeLocale(locale: 'en' | 'es'): void {
    moment.locale(locale);
    numeral.locale(locale);
}

changeLocale(locale);

export function formatAsCurrency(value?: number, undefinedReturnValue = ''): string {
    return typeof value === 'number' ? `${numeral(value).format(currencyFormat)} €` : undefinedReturnValue;
}

export function formatAsDate(date?: CPDate): string {
    return date ? formatCpDate(date).format(longDateFormat) : '';
}

export function formatAsNumber(value?: number, format: string = numberFormat): string {
    return typeof value === 'number' ? numeral(value).format(format) : '';
}

export function formatAsPercent(value?: number, format: string = numberFormat): string {
    return typeof value === 'number' ? `${numeral(value).format(format)} %` : '';
}

export function formatAsPercentWithoutRounding(value?: number, delimiter = '.'): string {
    return typeof value === 'number' ? `${value.toString().replace(/\./g, delimiter)} %` : '';
}

export function formatAsNumeral(value?: number): string {
    return typeof value === 'number' ? numeral(value).format(numeralFormat) : '';
}

export function formatAsDistance(value?: number): string {
    return typeof value === 'number' ? numeral(value).format(numberFormat) : '';
}

export function formatAsEmission(value?: number): string {
    return typeof value === 'number' ? `${numeral(value).format(numberFormat)} g/km` : '';
}

export function formatAsIban(iban?: string): string {
    return (
        iban
            ?.replace(/[^\dA-Z]/g, '')
            .replace(/(.{4})/g, '$1 ')
            .trim() ?? ''
    );
}
