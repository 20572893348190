export function getDigitalRenewalEndpoint(contractId: string): string {
    return `/contracts/${contractId}/digital-renewal`;
}

export function getMakeDecisionEndpoint(contractId: string, action: string): string {
    return `${getDigitalRenewalEndpoint(contractId)}/${action.toLowerCase()}`;
}
export function getConsultDecisionClientEndpoint(contractId: string): string {
    return `${getDigitalRenewalEndpoint(contractId)}/consultaDecisionCliente`;
}
