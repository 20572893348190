// The following URLs needs to be whitelisted within IDK (including domain and port)
export function loginPath(): string {
    return '/login';
}
export function notFoundPagePath(): string {
    return '/not-found';
}

export function registrationPagePath(): string {
    return '/register';
}

export function invitationPagePath(): string {
    return '/invitation';
}

export function notAuthorizedPagePath(): string {
    return '/not-authorized';
}

export function dashboardPagePath(): string {
    return '/dashboard';
}

export function digitalRenewalPagePath(contractId = ':contractId'): string {
    return `/digital-renewal/${contractId}`;
}
export function digitalRenewalRefinanceOrPaymentPagePath(contractId = ':contractId'): string {
    return `/refinance-or-payment/${contractId}`;
}

export function digitalRenewalReturnOfTheCarPath(contractId = ':contractId'): string {
    return `/return-of-the-car/${contractId}`;
}
export function digitalRenewalJointContractPagePath(contractId = ':contractId'): string {
    return `/joint-contract/${contractId}`;
}

export function faqPagePath(): string {
    return '/faq';
}

export function cookiePolicyPath(): string {
    return '/cookie-policy';
}

export function legalNoticePath(): string {
    return '/legal-notice';
}

export function privacyPolicyPath(): string {
    return '/privacy-policy';
}

export function landingPagePath(): string {
    return '/';
}

export function logoutPath(): string {
    return '/logout';
}

export function totalEarlySettlementPagePath(contractId = ':contractId'): string {
    return `/total-early-settlement/${contractId}`;
}

export function earlySettlementLeasingPagePath(contractId = ':contractId'): string {
    return `/early-settlement-leasing/${contractId}`;
}

export function totalEarlySettlementContractSelectorPagePath(): string {
    return '/total-early-settlement';
}

export function myProfilePagePath(): string {
    return '/my-profile';
}

export function changeContactDetailsPath(): string {
    return `${myProfilePagePath()}/change-contact-details`;
}

export function changeInvoiceConsentPath(): string {
    return `${myProfilePagePath()}/change-invoice-consent`;
}

export function changeAddressDetailsPath(): string {
    return `${myProfilePagePath()}/change-address-details`;
}

export function changeMonthlyDueDatePath(contractId = ':contractId'): string {
    return `${dashboardPagePath()}/${contractId}/change-monthly-due-date`;
}

export function changeIbanPath(contractId = ':contractId'): string {
    return `${dashboardPagePath()}/${contractId}/change-iban`;
}

export function requestCertificatePath(contractId = ':contractId'): string {
    return `/request-certificate/${contractId}`;
}

export function unpaidDetailsPath(contractId = ':contractId'): string {
    return `/unpaid-details/${contractId}`;
}

export function paymentSuccessPath(): string {
    return '/payment-succeeded';
}

export function paymentErrorPath(): string {
    return '/payment-failed';
}

export function postboxPagePath(): string {
    return '/postbox';
}

export function amortizationTablePath(contractId = ':contractId'): string {
    return `/amortization-table/${contractId}`;
}

export function totalEarlySettlementFutureQuotePath(contractId = ':contractId'): string {
    return `${totalEarlySettlementContractSelectorPagePath()}/${contractId}/future-quote`;
}

export function partialEarlySettlementPagePath(contractId = ':contractId'): string {
    return `/partial-early-settlement/${contractId}`;
}

export function earlySettlementPagePath(contractId = ':contractId'): string {
    return `/early-settlement/${contractId}`;
}

export function myRequestPagePath(): string {
    return '/my-request';
}

export function identityBrokerErrorPagePath(): string {
    return '/error';
}

export function featureAppLicensesPagePath(): string {
    return '/feature-app-licenses';
}

export function requestSubragationPagePath(contractId = ':contractId'): string {
    return `/request-subrogation/${contractId}`;
}

export function requestVehicleReturnPagePath(): string {
    return `/vehicle-return/`;
}

export function privatePersonSubragationPagePath(contractId = ':contractId'): string {
    return `${requestSubragationPagePath(contractId)}/private-person`;
}

export function legalPersonSubragationPagePath(contractId = ':contractId'): string {
    return `${requestSubragationPagePath(contractId)}/legal-person`;
}

export function corporateNameChangingSubragationPagePath(contractId = ':contractId'): string {
    return `${requestSubragationPagePath(contractId)}/corporate-name-changing`;
}

export function requestVehiclePurchasePagePath(contractId = ':contractId'): string {
    return `/request-vehicle-purchase/${contractId}`;
}

export function contractRecalculation(contractId = ':contractId', vinCode = ':vinCode'): string {
    return `/contract-recalculation/${contractId}/${vinCode}`;
}
