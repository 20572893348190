import { RoadType } from '@cp-es/common';

type RoadValueType =
    | 'Alameda'
    | 'Apartamento'
    | 'Avenida'
    | 'Bloque'
    | 'Barrio'
    | 'Calle'
    | 'Chalet'
    | 'Camino'
    | 'Colonia'
    | 'Carretera'
    | 'Caserio'
    | 'Cuesta'
    | 'Edificio'
    | 'Glorieta'
    | 'Grupo'
    | 'Lugar'
    | 'Mercado'
    | 'Municipio'
    | 'Manzana'
    | 'Poblado'
    | 'Polígono'
    | 'Pasaje'
    | 'Parque'
    | 'Prolongación'
    | 'Paseo'
    | 'Plaza'
    | 'Rambla'
    | 'Ronda'
    | 'Travesia'
    | 'Urbanización';

interface SelectRoadType {
    value: RoadType;
    label: RoadValueType;
}

export const roadTypeItems: SelectRoadType[] = [
    { value: 'CL', label: 'Calle' },
    { value: 'AL', label: 'Alameda' },
    { value: 'AP', label: 'Apartamento' },
    { value: 'AV', label: 'Avenida' },
    { value: 'BL', label: 'Bloque' },
    { value: 'BO', label: 'Barrio' },
    { value: 'CH', label: 'Chalet' },
    { value: 'CM', label: 'Camino' },
    { value: 'CO', label: 'Colonia' },
    { value: 'CR', label: 'Carretera' },
    { value: 'CS', label: 'Caserio' },
    { value: 'CT', label: 'Cuesta' },
    { value: 'ED', label: 'Edificio' },
    { value: 'GL', label: 'Glorieta' },
    { value: 'GR', label: 'Grupo' },
    { value: 'LG', label: 'Lugar' },
    { value: 'MC', label: 'Mercado' },
    { value: 'MN', label: 'Municipio' },
    { value: 'MZ', label: 'Manzana' },
    { value: 'PB', label: 'Poblado' },
    { value: 'PG', label: 'Polígono' },
    { value: 'PJ', label: 'Pasaje' },
    { value: 'PQ', label: 'Parque' },
    { value: 'PR', label: 'Prolongación' },
    { value: 'PS', label: 'Paseo' },
    { value: 'PZ', label: 'Plaza' },
    { value: 'RB', label: 'Rambla' },
    { value: 'RD', label: 'Ronda' },
    { value: 'TR', label: 'Travesia' },
    { value: 'UR', label: 'Urbanización' },
];

export const getRoadType = (roadTypeValue?: string): RoadType =>
    roadTypeItems.find(item => item.label === roadTypeValue)?.value ?? 'CL';
