import { Notification, NotificationStatus } from '@cp-shared-6/frontend-ui';
import { Button, ButtonContainer, Heading, ItemTeaser, Layout, Paragraph } from '@vwfs-bronson/bronson-react';
import { dashboardPagePath } from 'components/navigation/paths';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

export const RequestVehiclePurchaseUi = () => {
    const { t } = useTranslation('request-vehicle-purchase');
    const history = useHistory();

    return (
        <Layout>
            <Layout.Item default="1/1">
                <Heading level={'1'}>{t('heading')}</Heading>
            </Layout.Item>
            <Layout.Item default="1/1">
                <Notification status={NotificationStatus.info} text={t('notification')} className={'u-mb'} />
            </Layout.Item>
            <Layout.Item default="1/1">
                <ButtonContainer center>
                    <Button
                        onClick={() => window.open(t('buttons.call-to-action-link'), '_blank')}
                        testId="callToActionButton"
                        title="callToAction"
                        className="u-1/1 c-btn__text"
                    >
                        <span className="c-item-teaser__title">{t('buttons.call-to-action-button')}</span>
                    </Button>
                </ButtonContainer>
            </Layout.Item>
            <Layout.Item default="1/1">
                <Paragraph className={'u-mb u-text-center'}>
                    {t('paragraph.text')}:{' '}
                    <a href={t('paragraph.link')} target="_blank" rel="noreferrer">
                        {t('paragraph.link')}
                    </a>
                </Paragraph>
            </Layout.Item>
            <Layout.Item default="1/1">
                <ItemTeaser
                    linkProps={{
                        href: `mailto:${t('contact.email')}`,
                        normalLink: true,
                    }}
                    icon="semantic-mail"
                    title={t('contact.text')}
                    linkLabel={t('contact.email')}
                    small
                ></ItemTeaser>
            </Layout.Item>
            <Layout.Item default="1/1">
                <ButtonContainer center className={' u-mt-large'}>
                    <Button
                        secondary
                        onClick={() => history.push(dashboardPagePath())}
                        testId="backButton"
                        className="u-1/5"
                    >
                        {t('buttons.back-button')}
                    </Button>
                </ButtonContainer>
            </Layout.Item>
        </Layout>
    );
};
