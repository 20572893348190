import React from 'react';

import { getDisplayName } from '@cp-shared-6/frontend-ui';

import { withLoadingHandler, withLoadingHandlerProps } from './withLoadingHandler';
import { withNoConnectionHandler, withNoConnectionHandlerProps } from './withNoConnectionHandler';

type combinedProps<TProps> = TProps & withLoadingHandlerProps & withNoConnectionHandlerProps;

export const withLoadingAndNoConnectionHandler = <TProps extends object>(
    WrappedComponent: React.ComponentType<TProps>,
): React.FC<combinedProps<TProps>> => {
    const Wrapper = (props: combinedProps<TProps>) => {
        const ComponentWithHocs = withLoadingHandler(withNoConnectionHandler(WrappedComponent));
        return <ComponentWithHocs {...props} />;
    };

    Wrapper.displayName = `withLoadingAndNoConnectionHandler(${getDisplayName(WrappedComponent)})`;

    return Wrapper;
};
