import { createGetContractBasedDataFetchSlice } from '@cp-shared-6/frontend-integration';
import { CpDataApi } from 'cp-xhr';
import { TotalEarlySettlementOfflinePayment, TotalEarlySettlementOfflinePaymentError } from '@cp-es/common';
import { AxiosRequestConfig } from 'axios';

const { reducer, fetchData } = createGetContractBasedDataFetchSlice<
    TotalEarlySettlementOfflinePayment,
    TotalEarlySettlementOfflinePaymentError
>({
    dataName: 'offlinePayment',
    fetchCallback(link: string, requestConfig?: AxiosRequestConfig) {
        return CpDataApi.get(link, requestConfig).then(response => response.data);
    },
});

export default reducer;
export const fetchOfflinePayment = fetchData;
