import React from 'react';
import { ContentSection, Layout } from '@vwfs-bronson/bronson-react';
import { DigitalRenewalOptionsOverview } from '../components/digital-renewal/options-overview/DigitalRenewalOptionsOverview';
import { useParams } from 'react-router-dom';
import { useContract } from '../components/contracts/useContract';
import { withLoadingAndNoConnectionHandler } from '../components/integration-wrapper';
import { NoConnectionNotification } from '../components/notifications/no-connection/NoConnectionNotification';
import { ContractType } from '@cp-es/common';
import { useFinancialDetails } from '../components/contracts/financial-details/useFinancialDetails';

interface RouteParams {
    contractId: string;
}

const DigitalRenewalOptionsOverviewWithHandlers = withLoadingAndNoConnectionHandler(DigitalRenewalOptionsOverview);

export const DigitalRenewalPage: React.FC = () => {
    const { contractId } = useParams<RouteParams>();
    const { data: contract, isLoading, loadingError } = useContract(contractId, {
        encryptedContractId: true,
    });
    const {
        data: financialDetails,
        isLoading: isLoadingFinancialDetails,
        loadingError: loadingErrorFinancialDetails,
    } = useFinancialDetails(contract?.contractNumber || '');

    if (
        contract &&
        !(contract.contractType == ContractType.FINANCING || contract.contractType == ContractType.OPERATING_LEASE)
    ) {
        return <NoConnectionNotification />;
    }

    return (
        <ContentSection pageWrapSize="medium">
            <Layout>
                <Layout.Item default={'3/3'} m={'1/1'}>
                    <DigitalRenewalOptionsOverviewWithHandlers
                        contract={contract}
                        financialDetails={financialDetails}
                        isLoading={isLoading || isLoadingFinancialDetails}
                        hasError={!!loadingError || !!loadingErrorFinancialDetails}
                    />
                </Layout.Item>
            </Layout>
        </ContentSection>
    );
};
