import React, { useMemo, useState } from 'react';
import { isEligibleFinancingContract, isEligibleLeasing } from 'utils/digitalRenewalEligibleContracts';
import { useContracts } from 'components/contracts/useContracts';
import { Contract } from '@cp-es/common';
import { EntryNotification } from '../notifications/digital-renewal/entry-notification';

export const DigitalRenewalEntryNotification: React.FC = () => {
    const { data: contracts } = useContracts() as {
        data: Contract[] | null;
        isLoading: boolean;
        loadingError: unknown;
    };
    const [dismissedContracts, setDismissedContracts] = useState(new Set());

    const eligibleContracts = useMemo(() => {
        if (contracts && contracts.length > 0) {
            return contracts.filter(
                (contract: Contract) =>
                    (isEligibleFinancingContract(contract) || isEligibleLeasing(contract)) &&
                    !dismissedContracts.has(contract._encryptedContractNumber),
            );
        }
        return [];
    }, [contracts, dismissedContracts]);

    const handleButtonClick = (contractId: string): void => {
        setDismissedContracts(prevState => {
            const newDismissedContracts = new Set(prevState);
            newDismissedContracts.add(contractId);
            return newDismissedContracts;
        });
    };

    return (
        <>
            {eligibleContracts.map((contract: Contract, index: number) => (
                <EntryNotification
                    key={index}
                    contract={contract}
                    handleButtonClick={(): void => handleButtonClick(contract._encryptedContractNumber)}
                />
            ))}
        </>
    );
};
