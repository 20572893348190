import React from 'react';

import { useCmsContent } from '@cp-shared-6/frontend-integration';
import { LegalFooter as LegalFooterShared, LegalFooterLoadingPlaceholder } from '@cp-shared-6/frontend-ui';
import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import { RootState } from 'store/rootReducer';
import { formatCpDate } from '@cp-shared-6/common-utilities';

import { fetchLegalFooter } from './LegalFooterSlice';
import { featureAppLicensesPagePath } from '../navigation/paths';
import { useLocation } from 'react-router-dom';

const pagesWithoutLinks = [featureAppLicensesPagePath()];

const LegalFooterWithHandlers = withLoadingAndNoConnectionHandler(LegalFooterShared);

export const LegalFooter: React.FC = () => {
    const { cmsContent: legalFooter, isLoading, loadingError } = useCmsContent(fetchLegalFooter, (state: RootState) => {
        return state.content.legalFooter;
    });
    const currentPathName = useLocation().pathname;

    const currentYear = formatCpDate()
        .toMoment()
        .year();

    const hidden = pagesWithoutLinks.includes(currentPathName);

    return (
        <LegalFooterWithHandlers
            isLoading={isLoading}
            hasError={!!loadingError}
            footnotes={legalFooter?.footnotes}
            copyrightText={legalFooter?.copyrightText && `${legalFooter?.copyrightText} ${currentYear}`}
            legalText={legalFooter?.legalText || ''}
            links={hidden ? [] : legalFooter?.links || []}
            loadingPlaceholder={<LegalFooterLoadingPlaceholder />}
        />
    );
};
