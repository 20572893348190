import React from 'react';
import { useTranslation } from 'react-i18next';
import { Notification, NotificationStatus } from '@cp-shared-6/frontend-ui';
import { EarlySettlementError } from '@cp-es/common';
import { WithDefaultCpIntegrationErrors } from '@cp-shared-6/common-utilities';

export const TotalEarlySettlementErrorNotification: React.FC<{
    fetchContractError: WithDefaultCpIntegrationErrors<EarlySettlementError>;
}> = ({ fetchContractError }) => {
    const { t } = useTranslation('total-early-settlement');
    let errorType: string;

    switch (fetchContractError) {
        case 'CONTRACTUAL_SITUATION_ERROR': {
            errorType = 'contractual-situation-error';
            break;
        }
        default: {
            errorType = 'no-connection-error';
            break;
        }
    }

    return (
        <Notification
            status={NotificationStatus.error}
            headline={t(`error.${errorType}.headline`)}
            text={t(`error.${errorType}.text`)}
            testId={'error'}
        />
    );
};
