import { DataOverview } from '@vwfs-bronson/bronson-react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { InvoiceConsent, PersonalDetailsEditStatus } from '@cp-es/common';
import { AlertOrSuccessText, DefinitionListHorizontal, DefinitionListItem } from '@cp-shared-6/frontend-ui';
import { ValueOrDefaultError } from '../../../value-or-default-error';
import { NotificationForLastEditStatus } from './notification-for-last-edit-status/NotificationForLastEditStatus';
import { WithDefaultCpIntegrationErrors } from '@cp-shared-6/common-utilities';

export type ConsultViewProps = {
    invoiceConsent: InvoiceConsent;
    lastEditStatus: WithDefaultCpIntegrationErrors<PersonalDetailsEditStatus>;
    startEditing: () => void;
    isSignUp: boolean;
};

export const ConsultView: React.FC<ConsultViewProps> = ({ invoiceConsent, startEditing, lastEditStatus, isSignUp }) => {
    const { t } = useTranslation('my-profile-invoice-consent');

    const signUp = invoiceConsent.signUp;
    const isChangeLinkPresent = !!invoiceConsent;

    const value =
        typeof signUp === 'boolean' ? (
            <AlertOrSuccessText
                text={signUp ? t('consult-view.active') : t('consult-view.inactive')}
                isSuccess={signUp}
            />
        ) : (
            <ValueOrDefaultError />
        );

    const consentList: DefinitionListItem[] = [
        {
            label: t('consult-view.invoice-consent-setting'),
            value: value,
        },
    ];

    return (
        <DataOverview
            title={t('consult-view.title')}
            buttonLabel={isChangeLinkPresent ? t('translation:editableSectionNav.start') : undefined}
            buttonProps={isChangeLinkPresent ? { onClick: startEditing, testId: 'consentEditButton' } : undefined}
        >
            <p>{t('consult-view.info-title')}</p>
            <DefinitionListHorizontal list={consentList} />
            <NotificationForLastEditStatus lastEditStatus={lastEditStatus} isSignUp={isSignUp} />
        </DataOverview>
    );
};
