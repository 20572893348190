export function getFinancialDetailsEndpoint(contractId: string): string {
    return `/contracts/${contractId}/financial-details`;
}

export function changeMonthlyDueDateEndpoint(contractId: string): string {
    return `${getFinancialDetailsEndpoint(contractId)}/monthly-due-date`;
}

export function ibanValidationEndpoint(contractId: string): string {
    return `${getFinancialDetailsEndpoint(contractId)}/validate-iban`;
}

export function ibanChangeEndpoint(contractId: string): string {
    return `${getFinancialDetailsEndpoint(contractId)}/change-iban`;
}

export function sepaMandateDownloadEndpoint(contractId: string): string {
    return `${getFinancialDetailsEndpoint(contractId)}/sepa-mandate-download`;
}
