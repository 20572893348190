import { ContentSection } from '@vwfs-bronson/bronson-react';
import React from 'react';
import { PageHeader } from 'components/page-header/PageHeader';
import { PaymentFeedbackView } from 'components/unpaid-details';
import { useTranslation } from 'react-i18next';

type PaymentFeedbackPageProps = {
    asErrorFeedback?: boolean;
};

export const PaymentFeedbackPage: React.FC<PaymentFeedbackPageProps> = ({ asErrorFeedback }) => {
    const { t } = useTranslation('unpaid-details');
    return (
        <ContentSection pageWrapSize="medium">
            <PageHeader title={t('payment-feedback.headline')} />
            <PaymentFeedbackView asErrorFeedback={asErrorFeedback} />
        </ContentSection>
    );
};
