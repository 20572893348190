import React from 'react';
import { ContentSection } from '@vwfs-bronson/bronson-react';
import { PageHeader } from '../../components/page-header/PageHeader';
import { useTranslation } from 'react-i18next';
import { MyRequest } from '../../components/my-request';

export const MyRequestPage: React.FC = () => {
    const { t } = useTranslation('my-request');

    return (
        <ContentSection pageWrapSize="medium">
            <PageHeader title={t('headline.label')} />
            <MyRequest />
        </ContentSection>
    );
};
