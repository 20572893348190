import React from 'react';
import { Layout, Card } from '@vwfs-bronson/bronson-react';
import { LicensePlate } from '../../license-plate';
import { TranslationFormat, useTranslationWithFormatting } from '../../../localization/useTranslationWithFormatting';
import { Contract } from '@cp-es/common';
import { getProductCategoryTranslationLabel } from 'components/contracts/ProductCategoryTranslationLabel';
import { DefinitionListHorizontal, DefinitionListItem } from '@cp-shared-6/frontend-ui';

export type ContractTermsHeaderProps = {
    contractDetails: Contract;
    contractTermsHeaderData: {
        mileageLastRecalculation?: number;
        earlyCanAmount?: string;
    };
};
export const ContractTermsHeaderLeasing: React.FC<ContractTermsHeaderProps> = ({
    contractDetails,
    contractTermsHeaderData,
}) => {
    const { t, f } = useTranslationWithFormatting('early-settlement-leasing');

    const contractInformationList: DefinitionListItem[] = [
        {
            label: t('contract-information.next-due-amount'),
            value: f(contractDetails.nextInstalmentAmount, TranslationFormat.CURRENCY),
        },
        {
            label: t('contract-information.end-date'),
            value: f(contractDetails.contractEndDate, TranslationFormat.DATE),
        },
        {
            label: t('contract-information.estimated'),
            value: `${contractTermsHeaderData.earlyCanAmount} €`,
        },
        {
            label: t('contract-information.current-mileage'),
            value: f(contractTermsHeaderData.mileageLastRecalculation, TranslationFormat.DISTANCE),
        },
    ].filter(item => !!item.value);

    return (
        <Card element="article">
            <Layout equalHeight divider>
                <Layout.Item default="1/2" s="1/1">
                    <div className="u-text-left@m-up  u-mr-none cp-contract-identifier-slot">
                        <LicensePlate
                            size={'small'}
                            licensePlateNumber={contractDetails.licensePlateNumber}
                            className="u-mb"
                        />
                        <p className="u-zeta">
                            <strong>{contractDetails.carInformation}</strong>
                        </p>
                        <p>{`${t(getProductCategoryTranslationLabel(contractDetails.contractType))} ${
                            contractDetails.contractNumber
                        }`}</p>
                    </div>
                </Layout.Item>
                <Layout.Item default="1/2" s="1/1">
                    <div>
                        <h6>{t('contract-information.headline')}</h6>
                        <DefinitionListHorizontal list={contractInformationList} />
                    </div>
                </Layout.Item>
            </Layout>
        </Card>
    );
};
