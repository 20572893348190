import {
    ContractLinks,
    DATE_FORMAT,
    RequestCertificateData,
    RequestCertificateError,
    RequestCertificateType,
} from '@cp-es/common';
import {
    CPDate,
    formatCpDate,
    NotReachableCpIntegrationError,
    WithDefaultCpIntegrationErrors,
} from '@cp-shared-6/common-utilities';
import { parseErrorResponse } from '@cp-shared-6/frontend-integration';
import {
    preventSubmit,
    Spinner,
    useAnalyticsActionTracker,
    useAnalyticsPageViewTracker,
    ValidatedDate,
    ValidatedSelect,
} from '@cp-shared-6/frontend-ui';
import { Button, ButtonContainer, ContentSection, Fieldset, Form, Layout } from '@vwfs-bronson/bronson-react';
import { Formik } from 'formik';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { CpDataApi } from '../../../cp-xhr';
import { dashboardPagePath } from '../../navigation/paths';
import { RequestCertificateFormConfirmationModal } from './confirmation-modal';
import { useValidationSchema } from './useValidationSchema';
import { FormValues, getInitialValues } from './initialValues';

export type RequestCertificateFormProps = {
    links?: ContractLinks;
    beginDate?: CPDate;
};

type SelectItemType = {
    label: string;
    value: RequestCertificateType;
};

export const RequestCertificateForm: React.FC<RequestCertificateFormProps> = ({ links, beginDate }) => {
    const { t, i18n } = useTranslation('request-certificate');

    useAnalyticsPageViewTracker('requestCertificate');
    const { onAction: onSuccess } = useAnalyticsActionTracker('onRequestCertificateSuccess');
    const { onAction: onError } = useAnalyticsActionTracker('onRequestCertificateSubmitFailed');
    const { onAction: onApiError } = useAnalyticsActionTracker('onRequestCertificateSubmitAPIError');

    const [showConfirmationModal, setShowConfirmationModal] = useState(false);

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [lastSubmissionError, setLastSubmissionError] = useState<
        WithDefaultCpIntegrationErrors<RequestCertificateError>
    >();
    const [lastReceivedEmail, setLastReceivedEmail] = useState('');

    const history = useHistory();
    const language = i18n.languages[0];

    const validationSchema = useValidationSchema();

    const cancel = (): void => {
        history.push(dashboardPagePath());
    };

    const openConfirmationModal = (): void => {
        setShowConfirmationModal(true);
    };

    const closeConfirmationModal = (): void => {
        setShowConfirmationModal(false);
        if (!lastSubmissionError) {
            history.push(dashboardPagePath());
        }
    };

    const selectItems: SelectItemType[] = [
        { value: 'CONTRACT_STATUS', label: t('form.dropdown.stc') },
        { value: 'OUTSTANDING_DEBT_CONTRACT', label: t('form.dropdown.dpc') },
    ];

    const onSubmit = (values: FormValues): void => {
        if (!links?.requestCertificate) {
            return;
        }
        setIsSubmitting(true);
        const requestBody: RequestCertificateData = {
            certificateType: values.certificateType as RequestCertificateType,
            requestDate: formatCpDate(values.requestDate, DATE_FORMAT).toCpDate(),
        };
        const documentName =
            values.certificateType === 'CONTRACT_STATUS' ? 'Contract Status' : 'Outstanding Debt Contract';
        CpDataApi.put(links.requestCertificate, requestBody)
            .then(response => {
                onSuccess(documentName);
                setLastReceivedEmail(response.data?.email);
                setLastSubmissionError(undefined);
                setIsSubmitting(false);
                openConfirmationModal();
            })
            .catch(error => {
                const { code, message } = parseErrorResponse<RequestCertificateError>(error);
                const trackingError = { errorCode: code, errorMessage: message };
                code === NotReachableCpIntegrationError.code
                    ? onApiError(documentName, trackingError)
                    : onError(documentName, trackingError);
                setLastReceivedEmail('');
                setLastSubmissionError(code);
                setIsSubmitting(false);
                openConfirmationModal();
            });
    };

    return (
        <>
            <Formik initialValues={getInitialValues()} onSubmit={onSubmit} validationSchema={validationSchema}>
                {(formik): JSX.Element => (
                    <Form onSubmit={preventSubmit()} data-testid="requestCertificateForm">
                        <ContentSection className={'request-certificate-form'}>
                            <ValidatedSelect
                                label={t('form.dropdown.label')}
                                name={'certificateType'}
                                id={'request-certificate-type'}
                                testId={'request-certificate-type'}
                                selectItems={selectItems}
                                placeholder={t('form.dropdown.placeholder')}
                                disablePlaceholder={true}
                            />
                            {(formik.values.certificateType as RequestCertificateType) ===
                                'OUTSTANDING_DEBT_CONTRACT' && (
                                <Fieldset>
                                    <Fieldset.Row>
                                        <Layout>
                                            <Layout.Item default="1/2" s="1/1">
                                                <ValidatedDate
                                                    label={t('form.date.label')}
                                                    language={language}
                                                    outputDateFormat="d/m/Y"
                                                    minDate={
                                                        beginDate
                                                            ? formatCpDate(beginDate).format(DATE_FORMAT)
                                                            : undefined
                                                    }
                                                    maxDate={formatCpDate()
                                                        .subtract(1, 'days')
                                                        .format(DATE_FORMAT)}
                                                    excludeWeekend={true}
                                                    name={'requestDate'}
                                                    testId={'requestCertificateDate'}
                                                    type="text"
                                                />
                                            </Layout.Item>
                                        </Layout>
                                    </Fieldset.Row>
                                </Fieldset>
                            )}
                        </ContentSection>
                        <Fieldset>
                            <Fieldset.Row>
                                <ButtonContainer nav>
                                    <Button
                                        secondary
                                        onClick={cancel}
                                        testId="cancelButton"
                                        type="btn"
                                        disabled={isSubmitting}
                                    >
                                        {t('form.button.cancel')}
                                    </Button>
                                    <Button
                                        onClick={formik.submitForm}
                                        testId="submitButton"
                                        type="btn"
                                        disabled={isSubmitting}
                                    >
                                        {t('form.button.submit')}
                                    </Button>
                                </ButtonContainer>
                            </Fieldset.Row>
                        </Fieldset>
                    </Form>
                )}
            </Formik>
            {isSubmitting && <Spinner fullPage={true} />}
            <RequestCertificateFormConfirmationModal
                shown={showConfirmationModal}
                receivedEmail={lastReceivedEmail}
                errorStatus={lastSubmissionError}
                closeConfirmationModal={closeConfirmationModal}
            />
        </>
    );
};
