import React from 'react';
import { Layout, ButtonContainer, Button } from '@vwfs-bronson/bronson-react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { dashboardPagePath } from 'components/navigation/paths';

type FutureQuoteSimulationButtonBarProps = {
    back: () => void;
};

export const FutureQuoteSimulationButtonBar: React.FC<FutureQuoteSimulationButtonBarProps> = ({ back }) => {
    const { t } = useTranslation('total-early-settlement');
    const history = useHistory();
    return (
        <Layout className="u-mt">
            <Layout.Item default="1/4" />
            <Layout.Item className={'u-text-center'} default="1/2">
                <ButtonContainer center>
                    <Button secondary onClick={back} testId="futureQuoteSimulationBackButton" type="btn">
                        {t('translation:editableSectionNav.back')}
                    </Button>
                    <Button onClick={() => history.push(dashboardPagePath())} testId="dashboardButton" type="btn">
                        {t('offline-payment.button.dashboard')}
                    </Button>
                </ButtonContainer>
            </Layout.Item>
        </Layout>
    );
};
