import { ContactPhoneValidatorError, ContactValidatorErrors } from '../types';

const contactPhoneValidatorError = (invalid: string): ContactPhoneValidatorError => ({
    matches: {
        pattern: invalid,
    },
    max: invalid,
    min: invalid,
});

export const defaultContactValidatorErrors: ContactValidatorErrors = {
    email: { email: 'Email address is not valid.', required: 'Email address is required.' },
    invoiceEmail: { email: 'Email address is not valid.' },
    primaryPhoneNumber: {
        ...contactPhoneValidatorError(
            'The phone number must contain 7-9 digits. Only Spanish phone numbers are allowed.',
        ),
        required: 'The phone number is required.',
    },
    secondaryPhoneNumber: contactPhoneValidatorError(
        'The secondary phone number must contain 7-9 digits. Only Spanish phone numbers are allowed.',
    ),
};
